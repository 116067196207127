import {useEffect, useState} from "react";

export function HighScoreNavDropDown({ options, onOptionChange, showOption}) {
    const [isOpen, setIsOpen] = useState(false);
    const [arrowSymbol, setArrowSymbol] = useState(0x25BC)

    // Find the option object that matches the selected value
    const selectedOption = options.find(option => option.value === showOption);

    const handleOptionClick = (value) => {
        onOptionChange(value);
        setIsOpen(false);



    }

    useEffect(() => {
        if(isOpen === true){
            setArrowSymbol(0x25B2)
        }else{
            setArrowSymbol(0x25BC)
        }
    }, [isOpen])

    return (
        <div className="relative md:hidden z-[49]">
            <button onClick={() => {setIsOpen(!isOpen);}} className="py-2 px-3 bg-mainDarkBrown text-white min-w-[170px] rounded-none">
                {selectedOption.text} {String.fromCharCode(arrowSymbol)}
            </button>
            {isOpen && (
                <ul className="absolute left-0   text-white shadow-xl min-w-[170px]">
                    {options.map((option) => (
                        <li key={option.value}>
                            <button
                                onClick={() => handleOptionClick(option.value)}
                                className="block px-4 py-2 text-sm capitalize bg-mainDarkBrown hover:bg-blue-500 w-full rounded-none"
                            >
                                {option.text}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
