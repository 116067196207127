import { SlotSquare } from "./SlotSquare";
import {useContext, useEffect, useState} from "react";
import {SlotsVictoryModal} from "../../modals/SlotsVictoryModal";
import {CasinoContext} from "../../../index";
export function SlotMachine({startSpinning1, setStartSpinning1, startSpinning2, setStartSpinning2, startSpinning3, setStartSpinning3, setLootCollected}) {


    const rowIcons = ["🍇", "🍒", "🍒", "🥑", "🥑", "🥑", "🍉", "🍉", "🍉", "🍉"]
    const [icon1, setIcon1] = useState();
    const [icon2, setIcon2] = useState();
    const [icon3, setIcon3] = useState();
    const [winningIcon, setWinningIcon] = useState();

    const [showWinModal, setShowWinModal] = useState(false)

    useEffect(() => {

        if(icon1 && icon2 && icon3){

            if(icon1 === icon2 && icon2 === icon3){
                setWinningIcon(icon1)
                setShowWinModal(true)


            }
            setIcon1(undefined)
            setIcon2(undefined)
            setIcon3(undefined)
        }

    }, [icon3, icon1, icon2])



    return (
        <div className="bg-red-400 flex">
            <SlotSquare
                direction='down'
                icons={rowIcons}
                startSpinning={startSpinning1}
                setStartSpinning={setStartSpinning1}
                stopDelay={0} // No delay for the first slot
                onSpinEnd={icon => {
                    setIcon1(icon)
                }}
            />
            <SlotSquare
                direction="up"
                icons={rowIcons}
                startSpinning={startSpinning2}
                setStartSpinning={setStartSpinning2}
                stopDelay={1000} // 1-second delay for the second slot
                onSpinEnd={icon => {
                    setIcon2(icon)
                }}
            />
            <SlotSquare
                direction="down"
                icons={rowIcons}
                startSpinning={startSpinning3}
                setStartSpinning={setStartSpinning3}
                stopDelay={2000} // 2-second delay for the third slot
                onSpinEnd={icon => {
                    setIcon3(icon)
                }}
            />


            <SlotsVictoryModal isOpen={showWinModal} onClose={() => setShowWinModal(false)} winningIcon={winningIcon} setLootCollected={setLootCollected}/>
        </div>
    );
}