import {TroopCard} from "../cards/TroopCard";
import {FounderTimerList} from "../lists/FounderTimerList";
import {useFounderTimerState} from "../contexts/timerContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";

export function MapchamberProduction({level, troopReq}){

    const timers = useFounderTimerState();
    const [isTimersVisible, setIsTimersVisible] = useState(true)

    const toggleBarracksVisibility = () => {
        setIsTimersVisible(!isTimersVisible);
    };

    return   <div className="">
        <div className="bg-mainLightBrown1 pl-2 rounded grid grid-cols-1  md:gap-4 lg:grid-cols-1 xl:grid-cols-2">


        <div className="">
            <div className="mt-4 ">
                {(level >= 5) ? (
                    <div className="bg-mainLightBrown1 pt-2 pl-2 pb-2">
                        <div className="max-h-[36vh] overflow-y-scroll scrollbar pr-2 scrollbar-thumb-blue-500 scrollbar-thick  ">
                        <TroopCard
                            troop={troopReq[3]}
                            troopNumber={4}
                            troopId={troopReq[3].id}
                            level={level}
                            ad="2"
                            dp="1"

                        />
                    </div>
                    </div>
                ): <div>You need level 5 or higher to train founders!</div>}
            </div>
        </div>

            {timers.length > 0 && <div className="flex justify-center mx-auto place-items-center bg-mainDarkBrown rounded text-white items-center mt-2 w-[50%] block xl:hidden" onClick={toggleBarracksVisibility}>
                <FontAwesomeIcon icon={isTimersVisible? faArrowUp : faArrowDown} className=""  />
                <p className="pl-2 font-semibold">{isTimersVisible? 'Hide timers' : 'Show timers'}</p>
            </div>}

            <div className={`xl:block ${isTimersVisible ? 'block' : 'hidden'}`}>

            <FounderTimerList/>
        </div>

    </div>
    </div>
}