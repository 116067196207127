import {React, useContext, useEffect, useState} from "react";
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";



import {SignedOutsNavBar} from "../Components/bars/SignedOutsNavBar";
import {SignedOutFooter} from "../Components/bars/SignedOutFooter";
import {SmallWheatIcon} from "../images/resources/SmallWheatIcon";
import {SmallWoodIcon} from "../images/resources/SmallWoodIcon";
import {SmallStoneIcon} from "../images/resources/SmallStoneIcon";
import {SmallIronIcon} from "../images/resources/SmallIronIcon";
import {BUILDINGS_AND_PRICES} from "../constants";
import {AuthContext} from "../index";
import {HelperFunctions} from "../HelperFunctions";


export function GuidePage(){
    const { authToken } = useContext(AuthContext);
    const buildingOptions = ["Warehouse", "Barracks", "Mill", "Lumberyard", "Blacksmith", "Quarry", "Casino", "Brothel", "Embassy", "Mapchamber", "Marketplace"]
    const troopOptions = ["Clubman", "Spearman", "Axeman", "Founder"]
    const objectiveOptions = ["Fighter", "Farmer", "Gambler"]
    const [category, setCategory] = useState("Buildings")
    const [infoCategory, setInfoCategory] = useState("Warehouse")
    const [infoOptions, setInfoOptions] = useState(buildingOptions)
    const [infoText, setInfoText] = useState("Warehouse is good building")
    const [infoImg, setInfoImg] = useState(BUILDINGS_AND_PRICES["Warehouse"].image)

    const [selectedLevel, setSelectedLevel]  = useState(1);
    const [wheatReq, setWheatReq]  = useState(BUILDINGS_AND_PRICES.Warehouse.levels[0].wheat);
    const [woodReq, setWoodReq]  = useState(BUILDINGS_AND_PRICES.Warehouse.levels[0].wood);
    const [stoneReq, setStoneReq]  = useState(BUILDINGS_AND_PRICES.Warehouse.levels[0].stone);
    const [ironReq, setIronReq]  = useState(BUILDINGS_AND_PRICES.Warehouse.levels[0].iron);
    const [levelList, setLevelList] = useState([])
    const [secondsToBuild, setSecondsToBuild] = useState(0);

    function getImageForCategory(){

        setInfoImg(BUILDINGS_AND_PRICES[infoCategory].image)
    }

    function generateLevelList(level){
        const building = BUILDINGS_AND_PRICES[infoCategory].levels
        setLevelList(building)
        selectLevelPrice(level)
    }

    function selectLevelPrice(level){
        const req = BUILDINGS_AND_PRICES[infoCategory].levels[level - 1]
        setWheatReq(req.wheat);
        setWoodReq(req.wood);
        setStoneReq(req.stone);
        setIronReq(req.iron);
        setInfoText(BUILDINGS_AND_PRICES[infoCategory].description)
        if(category !== "Strategies"){
            setSecondsToBuild(req.seconds_to_build)
        }

    }

    // Sets initial state of each category
    function changeCategory(e){
        setSelectedLevel(1)
        setCategory(e.target.value)

        if(e.target.value === "Strategies"){
            setInfoOptions(objectiveOptions)
            setInfoCategory("Fighter")
            setInfoText(BUILDINGS_AND_PRICES["Fighter"].description)
            return
        }
        const item = BUILDINGS_AND_PRICES[infoCategory].levels
        setWheatReq(item[0].wheat)
        setWoodReq(item[0].wood)
        setStoneReq(item[0].stone)
        setIronReq(item[0].iron)
        setSecondsToBuild(item[0].seconds_to_build)
        setInfoText(BUILDINGS_AND_PRICES[infoCategory].description)
        if(e.target.value === "Buildings"){
            setInfoOptions(buildingOptions)
            setInfoCategory("Warehouse")
            setInfoImg(BUILDINGS_AND_PRICES["Warehouse"].image)

        }else if(e.target.value === "Troops"){
            setInfoOptions(troopOptions)
            setInfoCategory("Clubman")
            setInfoImg(BUILDINGS_AND_PRICES["Clubman"].image)
        }

    }

    function changeInfoCategory(e){

            setSelectedLevel(1)
            setInfoCategory(e.target.value)
            setInfoText(BUILDINGS_AND_PRICES[e.target.value].description)
            if(category !== "Strategies"){
                setSecondsToBuild(BUILDINGS_AND_PRICES[e.target.value].levels[0].seconds_to_build)
            }




    }



    function nextPage(){
        infoOptions.map((item, index) => {
            if(item === infoCategory){
                if(index + 1 >= infoOptions.length){
                    setInfoCategory(infoOptions[0])
                }else{
                    setInfoCategory(infoOptions[index + 1])
                }

            }
        })
    }
    function prevPage(){
        infoOptions.map((item, index) => {
            if(item === infoCategory){
                if(index > 0){
                    setInfoCategory(infoOptions[index - 1])
                }else{
                    setInfoCategory(infoOptions[infoOptions.length - 1])
                }

            }
        })
    }




        useEffect(() => {
            if(category && category !== "Strategies"){
                generateLevelList(1)

            }
            if(infoCategory){
                getImageForCategory()
            }

        }, [category, infoCategory])

    useEffect(() => {
        selectLevelPrice(selectedLevel)
    }, [selectedLevel])


    return <div className="w-screen h-screen bg-mainLightGreen flex flex-col">
        {!authToken && <SignedOutsNavBar/>}

        <div className=" grid justify-center  mb-auto overflow-auto my-auto">

            <div className="mt-4  md:w-screen md:px-32 ">
                <div className="mx-4 md:mx-0 ">
                    <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4">
                        <div className="grid grid-cols-1 gap-8 w-full scrollbar scrollbar-thumb-mainLightGreen2 scrollbar-thick bg-mainLightYellow rounded p-4  max-h-[90vh] min-h-0 overflow-auto">
                            <div className="bg-mainAttack rounded">
                                <h1 className="text-center font-semibold">Guide</h1>


                                <div className="px-4 mb-2">
                                    <ul className="flex justify-center space-x-2 sm:space-x-4 md:space-x-6 text-">
                                        <li className="form-radio">
                                            <input type="radio" id="buildings" value="Buildings" name="infoCategory" onChange={changeCategory} checked={category === "Buildings"} className="hidden peer" />
                                            <label htmlFor="buildings" className="flex items-center justify-center px-2 md:px-4 py-2 border-2 border-transparent rounded-lg cursor-pointer text-xs md:text-sm font-medium leading-5  transition duration-150 ease-in-out bg-mainDarkRed focus:outline-none focus:shadow-outline-blue  active:text-gray-800 peer-checked:border-mainDarkBrown peer-checked:ring-2 peer-checked:ring-mainDarkBrown peer-checked:font-semibold">
                                                Buildings
                                            </label>
                                        </li>
                                        <li>
                                            <input type="radio" id="troops" value="Troops" name="infoCategory" onChange={changeCategory} className="hidden peer" />
                                            <label htmlFor="troops" className="flex items-center justify-center px-2 md:px-4 py-2 border-2 border-transparent rounded-lg cursor-pointer text-xs md:text-sm font-medium leading-5  transition duration-150 ease-in-out bg-mainDarkRed focus:outline-none focus:shadow-outline-blue  active:text-gray-800 peer-checked:border-mainDarkBrown peer-checked:ring-2 peer-checked:ring-mainDarkBrown peer-checked:font-semibold">
                                                Troops
                                            </label>
                                        </li>
                                        <li>
                                            <input type="radio" id="strategies" value="Strategies" name="infoCategory" onChange={changeCategory} className="hidden peer" />
                                            <label htmlFor="strategies" className="flex items-center justify-center px-2 md:px-4 py-2 border-2 border-transparent rounded-lg cursor-pointer text-xs md:text-sm font-medium leading-5 transition duration-150 ease-in-out bg-mainDarkRed focus:outline-none focus:shadow-outline-blue  peer-checked:border-mainDarkBrown peer-checked:ring-2 peer-checked:ring-mainDarkBrown peer-checked:font-semibold">
                                                Strategies
                                            </label>
                                        </li>
                                    </ul>
                                </div>


                                <div className="text-center  rounded">

                                    <div className="grid place-items-center p-4">
                                        <select
                                            className="form-select appearance-none block w-48  bg-gray-800 text-white border border-gray-700 rounded-full shadow-xl cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:border-blue-600 text-center"
                                            value={infoCategory}
                                            onChange={(e) => changeInfoCategory(e)}
                                            style={{ padding: '0.5rem 1rem', backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="%23FFFFFF"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right 0.5rem center', backgroundSize: '1.5em' }}
                                        >
                                            {infoOptions.map((item) => (
                                                <option key={item} value={item} className="bg-gray-800 text-white hover:bg-gray-600">
                                                    {item}
                                                </option>
                                            ))}
                                        </select>
                                    </div>




                                    <div className="grid grid-cols-1 md:grid-cols-2 place-items-center">
                                        <div className=" h-full ml-4">
                                            <h2 className="text-xl pt-2 font-semibold">{category}</h2>
                                            <p className="text-left">
                                                {infoText}
                                            </p>
                                        </div>
                                        <img src={infoImg} alt="Info image" className="w-64"/>
                                    </div>
                                    {category !== "Strategies" &&
                                    <div>


                                        <h4 className="font-semibold text-xl mb-1">Price:</h4>
                                        <div className="flex justify-center">

                                            <div className="grid grid-cols-4 gap-6 xxs:grid-cols-4  text-xxs xxs:text-xs  font-semibold">
                                                <div className="flex">
                                                    <SmallWheatIcon/>
                                                    <h4>{wheatReq}</h4>
                                                </div>
                                                <div className="flex">
                                                    <SmallWoodIcon/>
                                                    <h4>{woodReq}</h4>
                                                </div>
                                                <div className="flex">
                                                    <SmallStoneIcon/>
                                                    <h4>{stoneReq}</h4>
                                                </div>
                                                <div className="flex">
                                                    <SmallIronIcon/>
                                                    <h4>{ironReq}</h4>
                                                </div>


                                            </div>

                                        </div>
                                        <div>
                                            {secondsToBuild && <h4>{HelperFunctions.secondsToTimestamp(secondsToBuild)}</h4>}
                                        </div>

                                        <h4 className="font-semibold text-xl mb-1">Level:</h4>
                                        <div className="grid justify-center">
                                            <select
                                                name="level"
                                                id="level"
                                                onChange={(e) => setSelectedLevel(e.target.value)}
                                                className="appearance-none block w-16 bg-gray-800 text-white border border-gray-700 rounded-full shadow-xl cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:border-blue-600 mb-2"
                                                value={selectedLevel}
                                                style={{
                                                    padding: '0.5rem 1rem',
                                                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="%23FFFFFF"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>')`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'right 0.5rem center',
                                                    backgroundSize: '1.5em',
                                                    maxHeight: '10rem', // Set a maximum height for the dropdown
                                                    overflowY: 'auto', // Enable vertical scrolling
                                                }}
                                            >
                                                {levelList.map((item) => (
                                                    <option key={item.level} value={item.level}>
                                                        {item.level}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>}
                                    <div className="flex justify-between">
                                        <FontAwesomeIcon icon={faArrowLeft}
                                                         size="2xl"
                                                         className="ml-2 mb-2 hover:scale-[120%] "
                                                         onClick={prevPage}
                                        />
                                        <FontAwesomeIcon icon={faArrowRight}
                                                         size="2xl"
                                                         className="mr-2 mb-2 hover:scale-[120%]"
                                                         onClick={nextPage}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>

        </div>
        <SignedOutFooter/>
    </div>
}