import {useState} from "react";

export function CreateAllianceCard(){

    const [allianceName, setAllianceName] = useState("");
    const [allianceTag, setAllianceTag] = useState("");
    const [status, setStatus] = useState();

    async function createAlliance(){
        // Check if both fields have been filled in
        setStatus(undefined)
        if (!allianceName || !allianceTag) {
            setStatus('Please fill in all fields');
            return;
        }
        if(allianceName.length > 15){
            setStatus("Alliance name is too long! Max 15 characters.")
            return
        }

        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/new`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({allianceName, allianceTag}),
            credentials: "include"

        });

        // Handle response
        if (res.ok) {
            // Reset fields if successful
            setAllianceName("");
            setAllianceTag("");
        } else {
            // Handle error (e.g., if tags are not unique)
            const errorMessage = await res.text();
            setStatus(errorMessage);
        }
    }

    return <div className="flex justify-center mb-4">
        <div>
            <div>
                <h4 className="text-center text-2xl font-semibold">Create Alliance</h4>
            </div>
            <div>
                <div className="text-center">Name</div>
                <input className="bg-mainLightBrown3" type="text" maxLength={15} onChange={(e) => setAllianceName(e.target.value)}/>
            </div>
            <div className="mb-2">
                <div className="text-center">Tag</div>
                <input className="bg-mainLightBrown3" type="text" maxLength={4} onChange={(e) => setAllianceTag(e.target.value)}/>
            </div >
            <div className="flex justify-center">
                <button className="bg-mainDarkBrown text-white" onClick={createAlliance}>Create alliance</button>
            </div>

            {status && <div className="text-red-500">{status}</div>}
        </div>

    </div>
}
