export function HearthSuit(){


        return (
            <svg
                width="643"
                height="663"
                viewBox="0 0 643 663"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                    <path
                        d="M321.5 550C-150 150 150 -50 321.5 150C493 -50 793 150 321.5 550Z"
                        fill="#B70B0B"
                        stroke="black"
                        strokeWidth="5"
                    />
            </svg>
        );
}