import coinStack from "./images/CoinStack.png"

import clubmanIcon from "./images/troops/ClubmanIcon.png"
import axeManIcon from "./images/troops/AxemanIcon.png"
import wheatIcon from "./images/resources/WheatIcon.png"
import woodIcon from "./images/resources/WoodIcon.png"
import stoneIcon from "./images/resources/StoneIcon.png"
import ironIcon from "./images/resources/IronIcon.png"
import resGroup from "./images/resources/AllResGroups.png"


export const HelperFunctions = {


    secondsToTimestamp(seconds){
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const paddedHours = String(hours).padStart(2, '0');
        const paddedMinutes = String(minutes).padStart(2, '0');
        const paddedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    },

    getCardValue(carValue){
        if(carValue === "K" || carValue === "Q" || carValue === "J" ){
            return {wasAce: false, value: 10}
        }else if(carValue === "A"){


            return {wasAce: true, value: 11}
        }else if(carValue === "Unknown"){
            return {wasAce: false, value: 0}

        }else{
            return {wasAce: false, value: parseInt(carValue)}

        }
    },
    getShuffledItemPoolArray(){
        const resPoolOrdered = [
            {name: '3xCoins', rarity: 'common', description: 'You got 3 x coins', image:coinStack, text: "3x" },
            {name: '500xEachRes', rarity: 'common',  description: 'You got 500 x each resource', image: resGroup, text: "500x"},
            {name: '5xCoins', rarity: 'common', description: 'You got 5 x coins', image: coinStack, text: "5x"},
            {name: '2500xEachRes', rarity: 'common',  description: 'You got 2500 x each resource', image: resGroup, text: "2500x"},
            {name: '10xAxemen', rarity: 'uncommon',  description: 'You got 10 x axemen', image: axeManIcon, text: "10x"},
            {name: '5000xWheat', rarity: 'uncommon',  description: 'You got 5000 x wheat', image: wheatIcon, text: "5000x"},
            {name: '5000xWood', rarity: 'uncommon',  description: 'You got 5000 x wood', image: woodIcon, text: "5000x"},
            {name: '5000xStone', rarity: 'uncommon',  description: 'You got 5000 x stone', image: stoneIcon, text: "5000x"},
            {name: '5000xIron', rarity: 'uncommon',  description: 'You got 5000 x iron', image: ironIcon, text: "5000x"},
            {name: '20xCoins', rarity: 'rare',  description: 'You got 20 x coins', image: coinStack, text: "20x"},
            {name: '50xAxemen', rarity: 'rare',  description: 'You got 50 x axemen', image: axeManIcon, text: "50x"},
            {name: '10000xEachRes', rarity: 'rare',  description: 'You got 10,000 x each resource', image: resGroup, text: "10,000x"},
            {name: '50xCoins', rarity: 'very rare',  description: 'You got 50 x coins', image: coinStack, text: "50x"},
            {name: '50000xWheat', rarity: 'very rare',  description: 'You got 50,000 x wheat', image: wheatIcon, text: "50,000x"},
            {name: '50000xWood', rarity: 'very rare',  description: 'You got 50,000 x wood', image: woodIcon, text: "50,000x"},
            {name: '50000xStone', rarity: 'very rare',  description: 'You got 50,000 x stone', image: stoneIcon, text: "50,000x"},
            {name: '50000xIron', rarity: 'very rare',  description: 'You got 50,000 x iron', image: ironIcon, text: "50,000x"},
            {name: '250xAxemen', rarity: 'epic',  description: 'You got 250 x axemen', image: axeManIcon, text: "250x"},
            {name: '500xClubmen', rarity: 'epic',  description: 'You got 500 x clubmen', image: clubmanIcon, text: "500x"},
            {name: '1000xCoins', rarity: 'legendary',  description: 'You got 1000 x coins', image: coinStack, text: "1000x"}
        ]

        for(let i = resPoolOrdered.length - 1; i > 0; i--){
            let j  = Math.floor(Math.random() * (i + 1));
            let temp = resPoolOrdered[i];
            resPoolOrdered[i] = resPoolOrdered[j];
            resPoolOrdered[j] = temp
        }

        return resPoolOrdered
    },
    setLootboxImageByType(item){
        switch (item.name){
            case "3xCoins":
                item.image = coinStack;
                break;
            case "500xEachRes":
                item.image = resGroup;
                break;
            case "5xCoins":
                item.image = coinStack;
                break;
            case "2500xEachRes":
                item.image = resGroup;
                break;
            case "10xAxemen":
                item.image = axeManIcon;
                break;
            case "5000xWheat":
                item.image = wheatIcon;
                break;
            case "5000xWood":
                item.image = woodIcon;
                break;
            case "5000xStone":
                item.image = stoneIcon;
                break;
            case "5000xIron":
                item.image = ironIcon;
                break;
            case "20xCoins":
                item.image = coinStack;
                break;
            case "50xAxemen":
                item.image = axeManIcon;
                break;
            case "10000xEachRes":
                item.image = resGroup;
                break;
            case "50xCoins":
                item.image = coinStack;
                break;
            case "50000xWheat":
                item.image = wheatIcon;
                break;
            case "50000xWood":
                item.image = woodIcon;
                break;
            case "50000xStone":
                item.image = stoneIcon;
                break;
            case "50000xIron":
                item.image = ironIcon;
                break;
            case "250xAxemen":
                item.image = axeManIcon;
                break;
            case "500xClubmen":
                item.image = clubmanIcon;
                break;
            case "1000xCoins":
                item.image = coinStack;
                break;
            default:
                item.image = "FAILED"


        }
        return item

    },
    getRandomItemFromArray(){
        const pool = this.getShuffledItemPoolArray();
        const newarray = []
        newarray.push(pool[0])
        newarray.push(pool[1])
        return newarray
    },


}

export function getRarityColor(rarity) {
    switch (rarity) {
        case 'common':
            return 'bg-gray-400';
        case 'uncommon':
            return 'bg-green-500';
        case 'rare':
            return 'bg-blue-500';
        case 'very rare':
            return 'bg-purple-500';
        case 'epic':
            return 'bg-pink-500';
        case 'legendary':
            return 'bg-yellow-500';
        default:
            return 'bg-gray-400'; // default color
    }
}