import {BlackAce} from "./values/BlackAce";
import {BlackTwo} from "./values/BlackTwo";
import {BlackThree} from "./values/BlackThree";
import {RedAce} from "./values/RedAce";
import {RedTwo} from "./values/RedTwo";
import {RedThree} from "./values/RedThree";
import {BlackFour} from "./values/BlackFour";
import {BlackFive} from "./values/BlackFive";
import {BlackSix} from "./values/BlackSix";
import {BlackSeven} from "./values/BlackSeven";
import {BlackEight} from "./values/BlackEight";
import {BlackNine} from "./values/BlackNine";
import {BlackTen} from "./values/BlackTen";
import {BlackJack} from "./values/BlackJack";
import {BlackQueen} from "./values/BlackQueen";
import {BlackKing} from "./values/BlackKing";
import {RedFour} from "./values/RedFour";
import {RedFive} from "./values/RedFive";
import {RedSix} from "./values/RedSix";
import {RedSeven} from "./values/RedSeven";
import {RedEight} from "./values/RedEight";
import {RedNine} from "./values/RedNine";
import {RedTen} from "./values/RedTen";
import {RedJack} from "./values/RedJack";
import {RedQueen} from "./values/RedQueen";
import {RedKing} from "./values/RedKing";

const suitsToBlack = {
    "A": <BlackAce/>,
    "2": <BlackTwo/>,
    "3": <BlackThree/>,
    "4": <BlackFour/>,
    "5": <BlackFive/>,
    "6": <BlackSix/>,
    "7": <BlackSeven/>,
    "8": <BlackEight/>,
    "9": <BlackNine/>,
    "10": <BlackTen/>,
    "J": <BlackJack/>,
    "Q": <BlackQueen/>,
    "K": <BlackKing/>,

};

const suitsToRed = {
    "A": <RedAce/>,
    "2": <RedTwo/>,
    "3": <RedThree/>,
    "4": <RedFour/>,
    "5": <RedFive/>,
    "6": <RedSix/>,
    "7": <RedSeven/>,
    "8": <RedEight/>,
    "9": <RedNine/>,
    "10": <RedTen/>,
    "J": <RedJack/>,
    "Q": <RedQueen/>,
    "K": <RedKing/>,

};

export function CardValue({suit, value}) {
    if(suit === "Clubs" || suit === "Spades") {
        return suitsToBlack[value] || null;
    } else if(suit === "Hearts" || suit === "Diamonds") {
        return suitsToRed[value] || null;
    }

    return null;
}
