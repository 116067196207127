import { useEffect, useState } from "react";
import { MapCityCard } from "../cards/MapCityCard";
import {EmptyTileCard} from "../cards/EmptyTileCard";

export function MapModal(props) {
  const [tileData, setTileData] = useState();

  function findElement() {
    for (let i = 0; i < props.map.length; i++) {
      for (let j = 0; j < props.map[i].length; j++) {
        if (
            props.map[i][j].x === props.tileInfo.x &&
            props.map[i][j].y === props.tileInfo.y
        ) {
          setTileData(props.map[i][j]);
        }
      }
    }
  }
  useEffect(() => {
    findElement();
  }, [props.map]);


  if (tileData) {
    return (
        <div className="fixed bottom-0 left-0 w-full h-full grid place-items-center overflow-auto">
          <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={props.closeModal}
          > </div>
          <div className="relative bg-mainLightBrown4 p-4 rounded-lg">
           <div className="bg-mainLightGreen2 p-2">
             <button
                 className="absolute top-0 right-0 mt-2 mr-2 text-white font-bold text-xxs sm:text-xs focus:outline-none"
                 onClick={props.closeModal}
             >
               &times;
             </button>
             <div className="w-[75vw] md:w-[43vw]">
               {tileData.content.includes("City") || tileData.content === 'bot' ? (
                   <MapCityCard position={{ x: props.tileInfo.x, y: props.tileInfo.y }} tileData={tileData} />
               ): <EmptyTileCard position={{ x: props.tileInfo.x, y: props.tileInfo.y }}/>
               }
             </div>
           </div>

          </div>
        </div>
    );
  }

  return <div>There was an error</div>;
}
