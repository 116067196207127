import {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {UserContext} from "../../index";


export function MessageDetailsCard() {
    const { userInfo } = useContext(UserContext);
    const { messageId } = useParams();
    const [messageData, setMessageData] = useState();
    const [isFrom, setIsFrom] = useState();
    const [senderName, setSenderName] = useState();
    const [recieverName, setRecieverName] = useState();
    const [status, setStatus] = useState("");
    const navigate = useNavigate();

    function reply(){
        navigate("/messages/all", {state: {reply: true, recipientsUsername: senderName,
                subject: `RE: ${messageData.subject}`
            }})
    }

    async function getMessageDetails() {
        setStatus("");
        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/messages/details/${messageId}`, {
            credentials: "include"
        });

        if (res.status === 200) {
            const data = await res.json();

            if(userInfo.username === data.from_username){
                setIsFrom(true)
            }

            if(userInfo.username === data.to_username){
                setIsFrom(false)
            }


            setRecieverName(data.to_username)
            setSenderName(data.from_username)
            setMessageData(data);
        }else if(res.status === 401){
            const data = await res.text();
            setStatus(data)
        }
    }

    useEffect(() => {
        getMessageDetails();



    }, []);

    if (!messageData && !status ) {
        return <div>Loading...</div>;
    }

    return (
        <div className="w-screen h-screen bg-mainLightGreen overflow-hidden">
            <div className="relative top-[15vh]">
                <div className="w-2/3 mx-auto bg-mainDarkBrown p-4 text-black">
                    <div className="max-h-[66vh] overflow-y-auto bg-mainLightYellow p-4 ">
                        {messageData && <div>

                            {/*TO AND FROM*/}
                            <div className="flex justify-center text-xs sm:text-sm md:text-base">
                                {
                                    isFrom ?  <div>
                                        <div className="bg-mainLightBrown1 rounded-full">
                                            <h4 className="pr-4 pl-4 pt-2 pb-2">
                                                From: {senderName}
                                            </h4>
                                        </div>
                                        <div className="ml-2 bg-mainLightBrown1 rounded-full">
                                            <h4 className="pr-4 pl-4 pt-2 pb-2">
                                                To: <Link to={`/profile/${recieverName}`} >{recieverName}</Link>
                                            </h4></div>
                                    </div> :<div className="flex">
                                        <div className="bg-mainLightBrown1 rounded-full">
                                            <h4 className="pr-4 pl-4 pt-2 pb-2">
                                                From: <Link to={`/profile/${senderName}`} >{senderName}</Link>
                                            </h4>

                                        </div>
                                        <div className="ml-2 bg-mainLightBrown1 rounded-full">
                                            <h4 className="pr-4 pl-4 pt-2 pb-2">
                                                To: {recieverName}
                                            </h4>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/*SUBJECT*/}
                            <div className="flex justify-center text-xs sm:text-sm md:text-base">
                                <div className="bg-mainLightBrown1 rounded-full mt-2 inline-block mb-2">
                                    <h3 className="pr-4 pl-4 pt-2 pb-2">
                                        Subject: {messageData.subject}
                                    </h3>
                                </div>
                            </div>

                            {/*MESSAGE*/}
                            <div className="bg-mainLightBrown3 text-white text-sm sm:text-sm md:text-base lg:text-lg">
                                <p className="p-4">{messageData.message}</p>
                            </div>
                            {/*SENDT AT*/}
                            <div className="mt-4">
                                <p className="text-sm text-gray-600">
                                    Sent at: {new Date(messageData.sendt).toLocaleString()}
                                </p>
                            </div>
                            {/*REPLY*/}
                            <div className="grid justify-center">
                                <button onClick={reply} className="bg-mainDarkBrown text-white mt-4">Reply</button>
                            </div>


                        </div>}

                        {status && <div>
                            <h4 className="text-red-500">{status}</h4>
                        </div>}

                    </div>
                </div>
            </div>
        </div>
    );
}
