import { useNavigate } from "react-router-dom";
import {useContext, useState} from "react";
import {CityConfigContext} from "../../index";
import StoneField from '../../images/resourceFields/StoneField.png'

export function StoneSpot(props) {
    const {setSelectedResField } = useContext(CityConfigContext);
    const navigate = useNavigate();
    const [isHovered, setHovered] = useState(false);
    const [isClicked, setClicked] = useState(false);

    function upgradeResource(resourceField) {
        setSelectedResField(resourceField)
        localStorage.setItem("selectedResField", JSON.stringify(resourceField))
        navigate("/resourcefield/upgrade");
        setClicked(true);
        setTimeout(() => setClicked(false), 200);
    }

    if(!props.newField.location) {
        return <div className={props.pos}>Under construction</div>
    }

    return (
        <div
            className={`${props.pos} bg-gradient-to-br from-mainLightBrown1 to-gradientStone2 rounded-lg shadow-lg p-2 transition-colors duration-500 ease-in-out hover:scale-[103%] cursor-pointer aspect-[1/1] w-full`}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => upgradeResource(props.newField)}
        >
            <div className="aspect-content">
                <img
                    src={StoneField}
                    alt="Stone image"
                    className={`w-full h-full object-cover rounded-lg transition-transform duration-500 ease-in-out ${isClicked ? 'animate-pulse' : ''}`}
                />
                <div className="mt-4 bg-stoneUpgradeButton rounded-full flex justify-center items-center">
                    {props.newField.status
                        ? <div className="text-white">{props.newField.status}</div>
                        : <span className="text-white">{props.newField.level}</span>
                    }
                </div>
            </div>
        </div>
    )
}
