import {useContext, useEffect, useState} from "react";
import {AllianceContext} from "../../index";
import {TabItem} from "./Marketplace";
import {BuildingDescAndImage} from "../cards/BuildingDescAndImage";
import {EmbassyAlliance} from "./EmbassyAlliance";

export function Embassy({level, hideUpgradeMenu}){

    const {personalAllianceInvites, allianceRole, allianceData} = useContext(AllianceContext);


    const [canMake, setCanMake] = useState();

    const [activeTab, setActiveTab] = useState("overview");

    const handleTabChange = (tab) => {
        if(tab === "overview"){
            hideUpgradeMenu(true)
        }else{
            hideUpgradeMenu(false)
        }
        setActiveTab(tab);
    };



    useEffect(() => {

        if(level >= 5){
            setCanMake(true)
        }
    }, [])


    return (
        <div >

            <div className="mb-4">
                <ul className="flex border-b">
                    <TabItem
                        label="Overview"
                        active={activeTab === "overview"}
                        onClick={() => handleTabChange("overview")}
                    />
                    <TabItem
                        label="Alliance"
                        active={activeTab === "alliance"}
                        onClick={() => handleTabChange("alliance")}
                    />

                </ul>
            </div>

            {activeTab === "overview" && <BuildingDescAndImage type="Embassy" isDetails={true}/> }
            {activeTab === "alliance" && <EmbassyAlliance allianceData={allianceData} canMake={canMake} personalAllianceInvites={personalAllianceInvites}/> }

        </div>
    );
}