export function HighScoreTopPlayersList(props) {
  return (
    <div className="w-full overflow-x-auto text-sm lg:text-lg">


        <div>


      <h2 className="text-xl font-bold mb-2 text-black text-center">{props.title}</h2>

            {/*FOR MEDIUM AND BIG SCREENS*/}
      <ul className="hidden xs:block text-xs md:text-xxs lg:text-xs xl:text-sm">
        <li className="grid grid-cols-3 gap-4 bg-mainLightBrown1 text-center pt-2 pb-2 font-semibold text-base ">
          <li>Rank</li>
          <li>Name</li>
          <li>Amount</li>
        </li>
        {props.items.map((item, index) => (
          <li
            key={index}
            className={`grid grid-cols-3   ${item.username === props.playerName ? 'bg-red-500' : index % 2 === 0 ? 'bg-mainLightBrown3': 'bg-mainLightBrown1'} text-center pt-2 pb-2 `}
          >

            <div>{item.rank}</div>
            <div>{item.username}</div>
            <div>{item.amount}</div>
          </li>
        ))}
          <li className={`grid grid-cols-3 bg-red-500 border-2 border-gray-200 text-center pt-2 pb-2`}>
                <div>
                    {props.playerRank}
                </div>
              <div>
                  {props.playerName}
              </div>
              <div>
                  {props.playerScore}
              </div>
          </li>
      </ul>

            {/*FOR SMALL SCREENS*/}
            <div className="block xs:hidden">

                <div className="grid grid-cols-1 gap-2 text-sm sm:text-base">
                    {props.items.map((item, index) => (
                        <div className={`${index % 2 === 0 ? 'bg-mainLightBrown3': 'bg-mainLightBrown1'} text-center pt-2 pb-2 ${item.username === props.playerName && 'bg-red-500'} ` }>
                            <h4 className="font-semibold">Rank: {item.rank}</h4>
                            <h4>{item.username}</h4>
                            <h4>Score: {item.amount}</h4>
                        </div>
                    ))}
                    <div className="bg-red-500 border-2 border-gray-200 text-center pt-2 pb-2">
                        <div className="font-semibold ">
                            Rank: {props.playerRank}
                        </div>
                        <div>
                            {props.playerName}
                        </div>
                        <div>
                            {props.playerScore}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
  );
}
