import {SmallWheatIcon} from "../../images/resources/SmallWheatIcon";
import {SmallWoodIcon} from "../../images/resources/SmallWoodIcon";
import {SmallStoneIcon} from "../../images/resources/SmallStoneIcon";
import {SmallIronIcon} from "../../images/resources/SmallIronIcon";
import {useTimerState} from "../contexts/timerContext";
import {useEffect, useState} from "react";
import {HelperFunctions} from "../../HelperFunctions";

export function NextLevelRequirementsCard({level, wheatReq, woodReq, stoneReq, ironReq, actualBuildTime, bonuses,  hasEnoughResources,build, resReqId}){

    const allTimers = useTimerState();

    const [buildingTimers, setBuildingTimers] = useState([...allTimers.resourceTimers, ...allTimers.buildingTimers])

    useEffect(() => {
        setBuildingTimers([...allTimers.resourceTimers, ...allTimers.buildingTimers])
    }, [allTimers])

    return <div className="grid grid-cols-1 place-items-center mt-4">
        <h4 className="text-xl font-semibold mb-2">
            Upgrade to {level}:
        </h4>

            <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4" >
            <div className="flex"><SmallWheatIcon/> <h4>{wheatReq}</h4></div>
            <div><div className="flex"><SmallWoodIcon/> <h4>{woodReq}</h4></div> </div>
            <div><div className="flex"><SmallStoneIcon/> <h4>{stoneReq}</h4> </div> </div>
            <div><div className="flex"><SmallIronIcon/> <h4>{ironReq}</h4></div> </div>
            </div>
        <h2 className="text-lg mb-4">
            {HelperFunctions.secondsToTimestamp(actualBuildTime)} {bonuses && bonuses.building_speed_bonus > 0 && <div>({bonuses.building_speed_bonus}% bonus)</div>}
        </h2>
        <div className="">
            {hasEnoughResources && buildingTimers.length < 2 ? (
                <button
                    onClick={() => build(resReqId)}
                    className="bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded transition duration-200"
                >
                    Build
                </button>
            ) : buildingTimers.length < 2 ? (
                <h4 className="text-red-500 font-bold">
                    Not enough resources
                </h4>
            ) : (
                <h4 className="font-bold">Building queue full!</h4>
            )}
        </div>

    </div>
}