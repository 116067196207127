import { ResourceField } from "../Components/resource/ResourceField.jsx";


import "../assets/main.css";

export function ResourcePage() {

    return (
        <div className="w-screen h-screen bg-mainLightGreen overflow-hidden">
            <div className="relative  top-[10vh]">
                <ResourceField />
            </div>
        </div>
    );
}
