export function ClubsSuit() {
    return (
        <svg
            width="643"
            height="663"
            viewBox="0 0 643 663"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="321.5" cy="220" r="100" fill="#000000" stroke="black" strokeWidth="5" />
            <circle cx="421.5" cy="350" r="100" fill="#000000" stroke="black" strokeWidth="5" />
            <circle cx="221.5" cy="350" r="100" fill="#000000" stroke="black" strokeWidth="5" />
            <rect x="271.5" y="350" width="100" height="200" fill="#000000" stroke="black" strokeWidth="5" />
            <circle cx="321.5" cy="450" r="50" fill="#000000" stroke="black" strokeWidth="5" />
        </svg>
    );
}
