import {CasinoCoinResponsive} from "../../../images/CasinoCoinResponsive";

export function SlotRewards() {
    return (
        <div className="bg-blue-600 p-2 rounded-lg shadow-xl w-auto inline-flex flex-col items-center m-4 border-4 border-yellow-400">
            <h1 className="text-2xl text-yellow-300 font-bold mb-2 underline">Rewards:</h1>
            <ul className="space-y-2 text-center text-base  md:text-lg lg:text-sm xl:text-xl">
                <li className=" text-white flex items-center justify-between space-x-1">
                    <span >🍉🍉🍉</span>
                    <div className="flex">
                        <span className="text-yellow-300">10 </span>
                        <CasinoCoinResponsive/>
                    </div>
                </li>
                <li className=" text-white flex items-center justify-between space-x-1">
                    <span>🥑🥑🥑</span>
                    <div className="flex">
                        <span className="text-yellow-300">25 </span>
                        <CasinoCoinResponsive/>
                    </div>
                </li>
                <li className=" text-white flex items-center justify-between space-x-1">
                    <span >🍒🍒🍒</span>
                    <div className="flex">
                        <span className="text-yellow-300">100 </span>
                        <CasinoCoinResponsive/>
                    </div>
                </li>
                <li className=" text-white flex items-center justify-between space-x-1">
                    <span>🍇🍇🍇</span>
                    <div className="flex">
                        <span className="text-yellow-300">1000 </span>
                        <CasinoCoinResponsive/>
                    </div>
                </li>
            </ul>
        </div>
    )
}
