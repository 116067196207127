import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../index";


export function KickPlayerCard(props) {
    const { userInfo } = useContext(UserContext);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [errorText, setErrorText] = useState();



    useEffect(() => {
        if (props.members && userInfo) {
            const membersWithoutLoggedInUser = props.members.filter(
                (member) => member.username !== userInfo.username
            );
            setFilteredMembers(membersWithoutLoggedInUser);
        }
    }, [props.members, userInfo]);

    const handleKick = async () => {
        if (!selectedMember) return;
        // Kick the selected member
        setErrorText(undefined)

        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/kick`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({userId: selectedMember.user_id, allianceId: props.allianceId, kickedUsername: selectedMember.username  }),
            credentials: "include"
        })

        if(res.status === 400){
            const error = await res.text()
            setErrorText(error)
        }

        if(res.status === 200){
           /* navigate("resources")*/
        }
    };

    return (
        <div className="flex justify-center">
            <div>
                <h3>Who would you like to kick?</h3>
                {filteredMembers.length === 0 && <h4 className="text-red-500">You are alone in the alliance</h4>}
                <ul>
                    {filteredMembers.map((member) => (
                        <li key={member.user_id}>
                            <label>
                                <input
                                    type="radio"
                                    name="member"
                                    value={member.user_id}
                                    onChange={() => setSelectedMember(member)}
                                />
                                {member.username} ({member.role})
                            </label>
                        </li>
                    ))}
                </ul>
                {selectedMember && (
                    <div className="flex justify-center mt-2">
                        <button onClick={handleKick} className="px-4 py-2 bg-red-600 text-white rounded-lg">
                            Kick {selectedMember.username}
                        </button>
                    </div>

                )}

                {errorText && <div className="text-red-500">
                    {errorText}
                </div>}
            </div>
        </div>
    );
}
