import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {MainGameInfoPage} from "./MainGameInfoPage";


export function Homepage(){
    const [cookies, setCookies, removeCookies] = useCookies(["authToken"]);
    const navigate = useNavigate();
    const [jwt, setJwt] = useState(cookies.authToken);
    useEffect(() => {
        setJwt(cookies.authToken);
        if(jwt){
            navigate("/resources")
        }
    }, [cookies]);



    if(!jwt){
        return <MainGameInfoPage/>
    }


}