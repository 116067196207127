import React, {useState} from 'react';
// Import icons from Heroicons library
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/solid'
import {useNavigate} from "react-router-dom";



export function AllianceInvitationList(props) {

    const [statusMessage, setStatusMessage] = useState();
    const navigate = useNavigate();

    async function answerInvite(answer, allianceId, inviteId){
        setStatusMessage(undefined)

        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/answer/invite`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({answer, allianceId, inviteId  }),
            credentials: "include"
        })

        if(res.status === 204){
            setStatusMessage("Invitation declined")

        }

        if(res.status === 200){
            navigate("/allianceDetails")
        }

        if(res.status === 400){
            setStatusMessage(await res.text())
        }
    }
    return (
        <div className="flex justify-center">


        <ul className=" bg-mainLightBrown3 p-4 rounded-lg shadow-md w-[50%] min-w-[180px] ">

            <li>
                <h4 className="text-xl text-center font-bold text-white">Invitations</h4>
            </li>

            {props.list.map((invite) => (
                <li key={invite.id} className="bg-mainLightBrown1 border border-gray-300 rounded-lg mb-4 flex flex-col justify-between">

                    <h4 className="font-semibold text-gray-800 text-center text-base md:text-lg xl:text-2xl mt-2">{invite.name}</h4>

                        <h4 className="font-medium text-center">#{invite.tag}</h4>

                    <div className="flex justify-center mb-2">
                        <button className="bg-green-500 p-2 rounded-full hover:bg-green-600 transition duration-200 mr-2" onClick={() => answerInvite("Accepted", invite.alliance_id, invite.id)}>
                            <CheckIcon className="h-4 w-4 text-white" />
                        </button>
                        <button className="bg-red-500 p-2 rounded-full hover:bg-red-600 transition duration-200" onClick={() => answerInvite("Declined", invite.alliance_id, invite.id)}>
                            <XMarkIcon className="h-4 w-4 text-white" />
                        </button>
                    </div>
                </li>
            ))}

            {statusMessage && <li>
                <div className="text-red-500">{statusMessage}</div>
            </li>}
        </ul>
        </div>
    );
}
