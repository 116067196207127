import {createContext, useContext, useEffect, useReducer} from 'react';
import {timerActions, timerReducer} from "../reducers/timerReducer";

const TimerStateContext = createContext({
    buildingTimers: [],
    resourceTimers: [],
    trainTroopsTimers: [],
    trainFoundersTimers: [],
    sendResourcesTimers: [],
    retrieveTradersTimers: [],
    incomingResourceTimers: [],
    outgoingAttackTimer: [],
    incomingAttackTimer: [],
    returningAttackTimer: [],
    foundNewCityTimer: [],
});


const TimerDispatchContext = createContext(null);

export const TimerProvider = ({children}) => {
    const [timers, dispatch] = useReducer(timerReducer, {
        buildingTimers: [],
        resourceTimers: [],
        trainTroopsTimers: [],
        trainFoundersTimers: [],
        sendResourcesTimers: [],
        retrieveTradersTimers: [],
        incomingResourceTimers: [],
        outgoingAttackTimer: [],
        incomingAttackTimer: [],
        returningAttackTimer: [],
        foundNewCityTimer: [],
    });

    useEffect(() => {

        const tick = setInterval(() => {
            dispatch({type: timerActions.TICK});
            dispatch({type: timerActions.REMOVE_EXPIRED});
        }, 1000);

        return () => clearInterval(tick);
    }, [])



    return (<TimerStateContext.Provider value={timers}>
        <TimerDispatchContext.Provider value={dispatch}>
            {children}
        </TimerDispatchContext.Provider>
    </TimerStateContext.Provider>)

};

export const useTimerState = () => {
    const context = useContext(TimerStateContext);
    if(context === undefined){
        throw new Error("Use timer state must be used within a TimerProvider");
    }
    return context
}
export const useTroopTimerState = () => {
    const state = useTimerState();
    return state.trainTroopsTimers
}

export const useTimerDispatch = () => {
    const context = useContext(TimerDispatchContext);
    if(context === undefined){
        throw new Error("useTimerDispatch must be used within a timer Provider");
    }
    return context;
}

export const useResourceUpgradeTimerState = ()  => {
    const state = useTimerState();
    return state.resourceTimers;
}

export const useBuildingUpgradeTimerState = () => {
    const state = useTimerState();
    return state.buildingTimers;
}

export const useFounderTimerState = () => {
    const state = useTimerState();
    return state.trainFoundersTimers
}
export const useSendResourceTimerState = () => {
    const state = useTimerState();
    return state.sendResourcesTimers
}
export const useRetrieveTraderTimerState = () => {
    const state = useTimerState();
    return state.retrieveTradersTimers;
}
export const useIncomingResourcesTimerState = () => {
    const state = useTimerState();
    return state.incomingResourceTimers
}

export const useOutgoingAttackTimerState = () => {
    const state = useTimerState();
    return state.outgoingAttackTimer
}

export const useIncomingAttackTimerState = () => {
    const state = useTimerState();
    return state.incomingAttackTimer
}

export const useReturningAttackTimer = () => {
    const state = useTimerState();
    return state.returningAttackTimer
}

export const useFoundNewCityTimer = () => {
    const state = useTimerState();
    return state.foundNewCityTimer
}