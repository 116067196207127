import {CasinoCoinResponsive} from "../../images/CasinoCoinResponsive";

export function GameOverModal({ winner, betAmount, closeModal, changeTab }) {
    const playerWon = winner === "player";
    const dealerWon = winner === "dealer";
    const draw = winner === "draw";

    let dealerMessage = '';
    let playerMessage = '';
    let dealerBackgroundColor = '';
    let playerBackgroundColor = '';

    if (draw) {
        dealerMessage = 'Draw';
        playerMessage = 'Draw';
        dealerBackgroundColor = 'bg-blue-500';
        playerBackgroundColor = 'bg-blue-500';
    } else {
        dealerMessage = dealerWon ? 'Dealer Won' : 'Dealer Lost';
        playerMessage = playerWon ? 'Player Won' : 'Player Lost';
        dealerBackgroundColor = dealerWon ? 'bg-green-500' : 'bg-red-500';
        playerBackgroundColor = playerWon ? 'bg-green-500' : 'bg-red-500';
    }

    const rewardText = playerWon ? `+${betAmount * 2}` : (draw ? `${betAmount}` : `-${betAmount}`)

    async function endGame(){
        await fetch(`${import.meta.env.VITE_GAMEHOST}/api/casino/blackjack/end`, {credentials: "include"})
        closeModal()
        changeTab()
    }

    async function collect(){
        await fetch(`${import.meta.env.VITE_GAMEHOST}/api/casino/blackjack/collect`, {credentials: "include"})
        closeModal()
        changeTab()
    }

    return (
        <div className="absolute inset-0 flex flex-col items-center justify-center ">
            <div className={`flex items-center justify-center w-full h-1/3 opacity-75 ${dealerBackgroundColor}`}>
                <p className="text-xl font-bold text-white">{dealerMessage}</p>
            </div>
            <div className={` grid place-items-center w-full h-1/3 opacity-75 ${playerBackgroundColor}`}>
                <p className="text-xl font-bold text-white">{playerMessage}</p>

                {playerWon || draw ?
                    <button className="mt-4 bg-white text-black py-1 px-4 rounded-md" onClick={collect}>
                        Collect
                    </button>: <button className="mt-4 bg-white text-black py-1 px-4 rounded-md" onClick={endGame}
                    >Play again</button>
                }
                <div className="bg-gray-300 flex">
                    <CasinoCoinResponsive/>

                    <p className={`text-xl font-bold ${playerWon ? "text-green-500": (draw ? "text-blue-500" : "text-red-500")}`}>{rewardText}</p>
                </div>
            </div>
        </div>
    );
}
