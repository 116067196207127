import { useContext, useEffect, useState } from "react";
import {CityConfigContext, UserContext, WarehouseContext} from "../../index.jsx";
import { useNavigate } from "react-router-dom";
import { BuildingInfo } from "./buildings/BuildingInfo";
import {UpgradeBuildingReqCard} from "../cards/UpgradeBuildingReqCard";
import {timerActions} from "../reducers/timerReducer";
import {useBuildingUpgradeTimerState, useTimerDispatch} from "../contexts/timerContext";
import DemolishIcon from "../../images/DemolishBuildingIcon.png";

export function UpgradeBuilding() {
  const dispatch = useTimerDispatch();



  const [building, setBuilding] = useState();
  const [upgradedBuilding, setUpgradedBuilding] = useState();
  const [hasEnoughResources, setHasEnoughResources] = useState(false);

  const { setShowSpinner } = useContext(UserContext);

  const { warehouse } = useContext(WarehouseContext);
  const { inCityId, cityBonus,  selectedBuilding } =
    useContext(CityConfigContext);

  const navigate = useNavigate();
  const [isMaxLevel, setIsMaxLevel] = useState(false);
  const [actualBuildTime, setActualBuildTime] = useState(99999999);
  const buildingTimers = useBuildingUpgradeTimerState();
  const [showModal, setShowModal] = useState(false);
  const [demolishStatus, setDemolishStatus] = useState();

  const [shouldShowUpgradeMenu, setShouldShowUpgradeMenu] = useState(true)
  const [status, setStatus] = useState();
  async function upgradeBuilding(id, location) {
    setStatus(undefined)
    setShowSpinner(true)
    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/building/upgrade/${id}/${location}/${inCityId}`, {
      credentials: "include"
    });

    if(res.status === 200){

        const data = await res.json();
        // clear all timers and
        dispatch(
            {
              type: timerActions.ADD_TIMER,
              category: "buildingTimers",
              payload: {
                duration: data.building_total_time,
                location: data.location,
                timeLeft: data.timeLeft,
                endTime: data.end_time,
                cityId: data.cityId,
                extra: {
                  building_name: data.building_name,
                  next_level: data.building_level
                }
              }
            })

      setTimeout(() => {
        setShowSpinner(false)
      }, 1700)
      navigate("/town");

    }else if(res.status === 401){
      const data = await res.text();
      setStatus(data)
      setShowSpinner(false)
    }
  }

  useEffect(() => {


      const selected = localStorage.getItem("selectedTownBuilding")
      const data = JSON.parse(selected);
      if(buildingTimers.length > 0){
        if(data.nextLevel == null){
          buildingTimers.forEach((item) => {
            if(item.location === data.location){
              data.nextLevel = item.extra.next_level;
              data.status = `${data.building_level} -> ${item.extra.next_level}`
            }
          })
        }
      }
      setBuilding(data)

  }, [selectedBuilding]);

  async function getReqs(name, level) {
    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/building/building/upgrade/req?name=${name}&level=${level}`, {
          credentials: "include"
        }
    );

    if (res.status === 200) {
      const data = await res.json();
      if (cityBonus) {
        const bonus = cityBonus.building_speed_bonus / 100;
        const timeToBuild = Math.round(data.seconds_to_build / (1 + bonus));
        setActualBuildTime(timeToBuild);
      } else {
        setActualBuildTime(data.seconds_to_build);
      }

      setUpgradedBuilding(data);
    }

    if (res.status === 204) {
      setIsMaxLevel(true);
    }
  }

  async function demolishBuilding(){
    setDemolishStatus(undefined);
    console.log(`demolish building in location ${building.location} for city id ${inCityId}`)

    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/building/demolish`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ location: building.location, cityId: inCityId }),
      credentials: "include"

    });
    if(res.status === 200){
      setShowModal(false)
      navigate("/town")
    }else{
      const text = await res.text();
      setDemolishStatus(text)

    }




  }

  useEffect(() => {
    if (building && !isMaxLevel) {
      if (building.nextLevel) {
        getReqs(building.building_name, building.nextLevel);
      } else {
        getReqs(building.building_name, building.building_level);
      }
    }
  }, [building]);

  useEffect(() => {
    if (upgradedBuilding) {
      if (
        warehouse.wheat >= upgradedBuilding.wheat_req &&
        warehouse.wood >= upgradedBuilding.wood_req &&
        warehouse.stone >= upgradedBuilding.stone_req &&
        warehouse.iron >= upgradedBuilding.iron_req
      ) {
        setHasEnoughResources(true);
      } else {
        setHasEnoughResources(false);
      }
    }
  }, [warehouse, upgradedBuilding]);

  if (!building) {
    return <div>Loading..</div>;
  }

  // Should find the requirements for the next level

  return (
    <div className="w-screen h-screen bg-mainLightGreen overflow-hidden ">
      <div className="relative top-[10vh] mx-4">
        <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4 text-black">
          <div className="max-h-[66vh] overflow-y-auto bg-mainLightYellow p-2 md:p-4 scrollbar scrollbar-thumb-mainLightBrown1 scrollbar-thin min-h-[50vh]">

            <div className="flex justify-end">
              <img
                  src={DemolishIcon}
                  alt="Demolish building icon"
                  onClick={() => setShowModal(true)}
                  className="cursor-pointer p-2 text-white rounded focus:outline-none focus:ring-2 focus:ring-opacity-50"
              />
            </div>

            <BuildingInfo
            building={building}
            upgradedBuilding={upgradedBuilding}
            isMax={isMaxLevel}
            hideUpgradeMenu={setShouldShowUpgradeMenu}
          />

            {shouldShowUpgradeMenu &&
            <UpgradeBuildingReqCard building={building} upgradedBuilding={upgradedBuilding} upgradeBuilding={upgradeBuilding} actualBuildTime={actualBuildTime}
                                    hasEnoughResources={hasEnoughResources} isMaxLevel={isMaxLevel} bonuses={cityBonus}/>
            }
            {status && <h4 className="text-red-500">{status}</h4>}
        </div>
      </div>
      </div>

      {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={() => setShowModal(false)}>
            <div className="bg-gray-600 p-8 rounded shadow-md" onClick={(e) => e.stopPropagation()}>
              <h2 className="text-xl mb-4 font-semibold">Are you sure you want to demolish the {building.building_name}?</h2>
              <p>All bonuses gained from and resources used on this will be lost. Continue?</p>
              <div className="flex justify-between">
                <button className="mr-4 px-4 py-2 " onClick={() => setShowModal(false)}>
                  Cancel
                </button>
                <button className="px-4 py-2 bg-red-500 text-white" onClick={demolishBuilding}>
                  Confirm
                </button>
              </div>
              {demolishStatus &&
              <h4 className="text-red-500">{demolishStatus}</h4>}
            </div>
          </div>
      )}

    </div>
  );
}
