import { HelperFunctions } from "../../HelperFunctions";
import {useFounderTimerState} from "../contexts/timerContext";
import {FounderIconResponsive} from "../../images/troops/FounderIconResponsive";

export function FounderTimerList() {

  const timersV2 = useFounderTimerState()


  if (timersV2.length === 0) {
    return null;
  }



  return (
      <div className="bg-mainLightBrown1 pl-2 rounded">
        <div className="max-h-[36vh] overflow-y-scroll pr-2 scrollbar scrollbar-thumb-blue-500 scrollbar-thick">
        <ul>
          {timersV2.length > 0 &&
            timersV2.map((buildItem, index) => (
                <li className="mt-2 bg-cityCard1 p-2 rounded-lg border-2 border-black flex justify-center">
                {buildItem.troopsId === 4 && (
                  <FounderIconResponsive/>
                )}
                <span className="font-bold ml-2">{buildItem.amount} </span>
                {index !== 0 && (
                  <span className="text-gray-400"> waiting...</span>
                )}
                { index === 0 ? (
                    <h4 className="inline ml-4">
                        Time left: {HelperFunctions.secondsToTimestamp(buildItem.timeLeft)}
                    </h4>
                ) : (
                    <h4 className="inline ml-4">
                        {/*NOT BUILDING*/}{HelperFunctions.secondsToTimestamp(buildItem.duration)}

                    </h4>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
