import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {CityConfigContext, UserContext} from "../../index.jsx";
import { MapAttackCard } from "./MapAttackCard";
import {SendResFromMapCard} from "./SendResFromMapCard";

export function MapCityCard(props) {
  const { userInfo } = useContext(UserContext);
  const {cityRestrictions} = useContext(CityConfigContext);

  const [cityData, setCityData] = useState();
  const [isMyCity, setIsMyCity] = useState(false);
  const [showAttackMenu, setShowAttackMenu] = useState(false);
  const [showSendResMenu, setShowSendResMenu] = useState(false);
  const { inCityId } = useContext(CityConfigContext);

  function hideAttackMenu() {
    setShowAttackMenu(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/map/city/${props.tileData.cityId}`, {
        credentials: "include"
      });

      const data = await res.json();

      setCityData(data);
      if (userInfo.username === data.username) {
        setIsMyCity(true);
      } else {
        setIsMyCity(false);
      }
    };
    fetchData();
  }, []);



  if (!cityData) {
    return <div>Loading...</div>;
  }
  if (!showAttackMenu) {
    return (
        <div className="text-white">
          <h3 className="text-xs xs:text-sm sm:text-base md:text-xl font-semibold mb-4 text-center">{props.tileData.city.cityName}</h3>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 place-items-center text-sm md:text-base">


            <div>
              <p>
                User:{" "}

                <Link
                    className="text-cyan-500 font-semibold hover:underline"
                    to={cityData.username !== 'botlin' ? `/profile/${cityData.username}` : "/town"}
                >
                  {cityData.username}
                </Link>
              </p>
              <p>Population: {cityData.city_population}</p>
            </div>


            <div className="text-center">
              <p>
                (X: {props.position.x} Y: {props.position.y})
              </p>
              {isMyCity ? (
                  <div className="">My City</div>
              ) : (
                  <div>
                    {cityRestrictions.hasBarracks ? <button
                        className="bg-white text-blue-500 px-4 py-2 rounded shadow-md hover:text-purple-600 focus:outline-none mt-2 text-xs sm:text-sm md:text-base"
                        onClick={() => setShowAttackMenu(true)}
                    >
                      Attack
                    </button> :
                      <div className="text-red-400">You need a barracks in your city to attack!</div>
                   }


                  </div>

              )}
              {
                cityData.username !== "botlin" && cityData.city_id !== inCityId  && <div>
                  {cityRestrictions.hasMarketplace ? <button
                      className="bg-white text-blue-500 px-4 py-2 rounded shadow-md hover:text-purple-600 focus:outline-none mt-2 text-xs sm:text-sm md:text-base"
                      onClick={() => setShowSendResMenu(true)}
                  >
                    Send resources
                  </button>:<div className="text-red-200">You need a marketplace to send resources to other cities!</div>}
                </div>
              }
            </div>



          </div>

          {showSendResMenu && <SendResFromMapCard toCityId={cityData.city_id}/>}
        </div>
    );
  }
  return (
      <MapAttackCard hideMenu={hideAttackMenu} cityId={props.tileData.cityId} targetCity={props.tileData.city.cityName} targetUser={cityData.username}/>
  );
}
