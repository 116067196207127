import dataFileOutput from "../images/Map/dataFiles/dataFileOutput3.json";
import { useContext, useEffect, useState } from "react";
import bgImg from "../images/Map/dataFiles/TextureFileOutput3.png";
import { CityMapContext } from "../index";
import upArrowImage from "../images/upArrow.png";
import { MapModal } from "./modals/MapModal";

export function MapGridV2() {
    const { middleCords, setMiddleCords } = useContext(CityMapContext);
    const [mapData, setMapData] = useState([]);
    const [statusText, setStatusText] = useState();
    const [mouseCityName, setMouseCityName] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mouseCoords, setMouseCoords] = useState({
        x: 0,
        y: 0,
    });
    const [chosenX, setChosenX] = useState(middleCords? middleCords.x : 0);
    const [chosenY, setChosenY] = useState(middleCords? middleCords.y : 0);

    async function getNewMap(direction) {
        setStatusText("");
        let newX = middleCords.x;
        let newY = middleCords.y;
        if (direction === "up") {
            newY--;
        } else if (direction === "down") {
            newY++;
        } else if (direction === "left") {
            newX--;
        } else if (direction === "right") {
            newX++;
        }

        if (
            (newX <= -50 && direction === "left") ||
            (newX >= 50 && direction === "right") ||
            (newY <= -50 && direction === "up") ||
            (newY >= 50 && direction === "down")
        ) {
            setStatusText("You have reached the end of the map");
        } else {
            await fetchMapData(newX, newY);
        }
    }

    useEffect(() => {
        if (middleCords) {
            fetchMapData(middleCords.x, middleCords.y);
        }else{
            fetchMapData(0, 0)
        }
    }, []);

    useEffect(() => {
        if(middleCords){
            setChosenX(middleCords.x);
            setChosenY(middleCords.y);
        }
    }, [middleCords]);

    const getTileStyle = (type) => {
        const frame = findFrameByType(type);
        if (frame) {
            const { frame: { x, y, w, h } } = frame;
            return {
                backgroundImage: `url(${bgImg})`,
                backgroundPosition: `-${x}px -${y}px`,
                width: `${w}px`,
                height: `${h}px`,
            };
        }
        return {};
    };

    async function fetchMapData(x, y) {
        setMapData(undefined);

        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/map/new/${x}/${y}`, {
            credentials: "include",
        });
        if (res.status === 200) {
            const data = await res.json();
            setMapData(data);
            setMiddleCords({
                x,
                y,
            });
        }

        if (res.status === 404) {
            setStatusText(res.statusText);
        }
    }

    const findFrameByType = (type) => {
        return dataFileOutput.frames.find((frame) => frame.filename.includes(type));
    };

    function setMouseCords(newMouseX, newMouseY) {
        setMouseCoords({ x: newMouseX, y: newMouseY });
    }

    function clickOnTile(x, y, cityName) {
        setMouseCords(x, y);
        if (cityName) {
            setMouseCityName(cityName);
        }
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    };

    function setMouseName(newName) {
        setMouseCityName(newName);
    }

    async function goToCoords() {
        let x = chosenX || middleCords.x;
        let y = chosenY || middleCords.y;

        if (x <= -50 || x >= 50 || y <= -50 || y >= 50) {
            setStatusText("You cannot go that far!");
        } else {
            if (x && y) {
                setMapData(undefined);
                const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/map/new/${x}/${y}`, {
                    credentials: "include",
                });
                const data = await res.json();

                setMapData(data);
                setMiddleCords({
                    x: chosenX,
                    y: chosenY,
                });
            } else {
                setStatusText("Please fill in both the coordinates");
            }
        }
    }



    return (
        <div className="mx-4 md:mx-0">
            <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4">
                <div className="bg-mainLightYellow w-full grid place-items-center overflow-hidden p-2">
                    <div className="lg:flex lg:flex-row-reverse mx-auto ">
                        <div className="grid grid-cols-9 w-fit gap-0.5 min-w-[460px]">
                            {mapData &&
                            mapData.length > 0 &&
                            mapData.flat().map((tile, index) => (
                                <div
                                    key={index}

                                    style={getTileStyle(tile.content)}
                                    className="tile"
                                    onClick={() => clickOnTile(tile.x, tile.y, tile.city ? tile.city.cityName : undefined)}
                                    onMouseEnter={() => setMouseName(tile.city.cityName)}
                                    onMouseLeave={() => setMouseName("")}
                                    onMouseMove={() => setMouseCords(tile.x, tile.y)}
                                />
                            ))}
                        </div>
                        {/*MAP SIDE MENU*/}
                        <div className="bg-mainLightBrown1 grid grid-cols-2 lg:grid-cols-1 content-center py-4 w-fit xs:w-full xxs:mx-auto rounded">

                            <div>
                                <div className="">
                                    <div className="text-center mt-2 ">
                                        X: {mouseCoords.x} Y: {mouseCoords.y}
                                    </div>
                                    <div className="text-center mb-2">{mouseCityName}</div>
                                </div>



                                <div className="flex flex-col ">
                                    <div className="flex justify-center mt-2 md:mt-0">
                                        <div>
                                            <label>
                                                X
                                                <input
                                                    type="number"
                                                    max={49}
                                                    min={-49}
                                                    onChange={(e) => setChosenX(e.target.value)}
                                                    value={chosenX}
                                                    className="w-[50px] pr-2 mb-2"
                                                />
                                            </label>
                                        </div>

                                        <div className="ml-2">
                                            <label>
                                                Y
                                                <input
                                                    type="number"
                                                    max={49}
                                                    min={-49}
                                                    onChange={(e) => setChosenY(e.target.value)}
                                                    value={chosenY}
                                                    className="w-[50px] mb-2"
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex justify-center mb-0 md:mb-2 ml-2 md:ml-0 text-xs md:text-sm md:mt-2">
                                        <button onClick={() => goToCoords()}>Go</button>
                                    </div>
                                </div>

                            </div>
                            <div className="mx-auto">
                                <div className="flex flex-col items-center space-y-2 h-16 w-16 lg:h-36 lg:w-36 mb-2">
                                    <img
                                        src={upArrowImage}
                                        alt="Up"
                                        className="w-8 md:h-8 rotate-180 hover:scale-[108%]"
                                        onClick={() => getNewMap("up")}
                                    />
                                    <div className="flex space-x-2">
                                        <img
                                            src={upArrowImage}
                                            alt="Left"
                                            className=" w-8 md:h-8 rotate-90 hover:scale-[108%]"
                                            onClick={() => getNewMap("left")}
                                        />
                                        <img
                                            src={upArrowImage}
                                            alt="Right"
                                            className=" w-8 md:h-8 rotate-[270deg] hover:scale-[108%]"
                                            onClick={() => getNewMap("right")}
                                        />
                                    </div>
                                    <img
                                        src={upArrowImage}
                                        alt="Down"
                                        className="w-8 md:h-8 hover:scale-[108%]"
                                        onClick={() => getNewMap("down")}
                                    />
                                </div>
                            </div>


                        </div>
                    </div>
                    {statusText && <h2 className="text-red-500">{statusText}</h2>}
                    {isModalOpen && (
                        <MapModal
                            closeModal={closeModal}
                            tileInfo={{ x: mouseCoords.x, y: mouseCoords.y }}
                            map={mapData}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
