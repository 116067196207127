
import Clubman from './ClubmanIcon.png'


export function ClubmanResponsive(){

    return <img
        src={Clubman}
        alt="Clubman icon"
        className="w-6 h-6 xs:w-8 xs:h-8 mr-2"
    />
}