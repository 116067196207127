import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "../../index";
import { CityBuildingSpot } from "./CityBuildingSpot";
import {useTimerState} from "../contexts/timerContext";

export function TownCenter() {
  const { cityTownCenter, inCityId } = useContext(CityConfigContext);


  const [sortedBuildings, setSortedBuildings] = useState();
  const globalTimers = useTimerState();
  const [buildingTimers, setBuildingTimers] = useState(globalTimers.buildingTimers)

  function updateBuildingStatus() {
    if (buildingTimers.length > 0 && cityTownCenter) {
      const currentTime  = new Date().getTime();
      buildingTimers.forEach((timerItem) => {
        let highestLvl = 0;
        cityTownCenter.forEach((buildingItem) => {
          if(parseInt(timerItem.cityId) === parseInt(inCityId)){
            if (timerItem.location === buildingItem.location) {
              if (timerItem.extra.next_level > highestLvl) {
                highestLvl = timerItem.extra.next_level;
              }
              if (timerItem.endTime >= currentTime && highestLvl !== buildingItem.building_level) {
                buildingItem.status = `${buildingItem.building_level} -> ${highestLvl}`;
                buildingItem.nextLevel = highestLvl;
              }

            }
          }
        });

      });
    }
  }



  useEffect(() => {
    if(globalTimers.buildingTimers.length > 0){
      setBuildingTimers( globalTimers.buildingTimers)
    }
  }, [globalTimers])



  useEffect(() => {
    if (cityTownCenter) {
      updateBuildingStatus()
      cityTownCenter.sort((a, b) => a.location - b.location);
      setSortedBuildings(cityTownCenter);

    }


  }, [cityTownCenter, buildingTimers]);

  if (!sortedBuildings) {
    return <div>Loading..</div>;
  }


  return (
    <div className="mx-4 md:mx-0">
      <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4 ">

        <div className="grid grid-cols-2 xs:grid-cols-3  sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 max-w-full scrollbar scrollbar-thumb-mainLightGreen2 scrollbar-thick bg-mainLightYellow p-4  max-h-[74vh] min-h-0 overflow-auto">

            <CityBuildingSpot
                building={sortedBuildings[0]}
                location={1}
            />


          <CityBuildingSpot
              building={sortedBuildings[1]}
              location={2}
          />


          <CityBuildingSpot
              building={sortedBuildings[2]}
              location={3}
          />


          <CityBuildingSpot
              building={sortedBuildings[3]}
              location={4}
          />

          <CityBuildingSpot
              building={sortedBuildings[4]}
              location={5}
          />


          <CityBuildingSpot
              building={sortedBuildings[5]}
              location={6}
          />


          <CityBuildingSpot
              building={sortedBuildings[6]}
              location={7}
          />


          <CityBuildingSpot
              building={sortedBuildings[7]}
              location={8}
          />


          <CityBuildingSpot
              building={sortedBuildings[8]}
              location={9}
          />

          <CityBuildingSpot
              building={sortedBuildings[9]}
              location={10}
          />

        </div>
      </div>
    </div>
  );
}
