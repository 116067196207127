import { useTroopTimerState} from "../contexts/timerContext";
import {ClubmanResponsive} from "../../images/troops/ClubmanResponsive";
import {SpearmanResponsive} from "../../images/troops/SpearmanResponsive";
import {AxemanResponsive} from "../../images/troops/AxemanResponsive";


export function NextTroopInQueueModal() {
const troopTimers = useTroopTimerState();

  if(troopTimers === undefined ||troopTimers.length <= 0){
    return
  }
  return (
    <div className="bg-gray-800 bg-opacity-50 rounded-lg shadow-md p-4 text-white hover:shadow-lg transition-all duration-200 ease-in-out flex text-center justify-center">
      <h3 className="text-xs lg:text-sm font-fantasy font-semibold mb-2">
          Next:
      </h3>
      <div className="font-pixel flex">
        {troopTimers[0].troopsId === 1 ? (
          <ClubmanResponsive/>
        ) : troopTimers[0].troopsId === 2 ? (
          <SpearmanResponsive/>
        ) : troopTimers[0].troopsId === 3 ? (
          <AxemanResponsive/>
        ) : (
          <></>
        )}{" "}



        <h4>
            {troopTimers[0].timeLeftNextUnit}
        </h4>
      </div>
    </div>
  );
}
