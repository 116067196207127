export function SpadesSuits(){

    return (
        <svg
            width="643"
            height="663"
            viewBox="0 0 643 663"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M321.5 10C150 160 0 230 0 370C0 470 100 530 200 530C250 530 300 500 321.5 460C343 500 393 530 443 530C543 530 643 470 643 370C643 230 493 160 321.5 10Z"
                fill="#000000"
                stroke="black"
                strokeWidth="5"
            />
            <path
                d="M271.5 530H371.5V630H271.5V530Z"
                fill="#000000"
                stroke="black"
                strokeWidth="5"
            />
            <path
                d="M321.5 460L271.5 530H371.5L321.5 460Z"
                fill="#000000"
                stroke="black"
                strokeWidth="5"
            />
        </svg>
    );

}