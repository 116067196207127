
import {MapAttackCard} from "../cards/MapAttackCard";
import {useContext, useState} from "react";
import {CityConfigContext} from "../../index";
import {Link} from "react-router-dom";

export function CityProfileModal(props){
    const {cityRestrictions} = useContext(CityConfigContext);
    const [showAttackMenu, setShowAttackMenu] = useState(false)

    function hideAttackMenu() {
        setShowAttackMenu(false);
    }

    if (!showAttackMenu) {
        return (
            <div className=" text-white">

                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    onClick={props.closeModal}
                > </div>

                <div className="relative bg-mainLightBrown4 p-4 rounded-lg">
                    <div className="bg-mainLightGreen2 p-2">

                    <button
                        className="absolute top-0 right-0 mt-2 mr-2 text-white font-bold text-xs focus:outline-none"
                        onClick={props.closeModal}
                    >
                        &times;
                    </button>
                        <h3 className="text-xs xs:text-sm sm:text-base md:text-xl font-semibold mb-4 text-center">{props.cityName}</h3>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 place-items-center text-sm md:text-base">
                    <div>
                        <p>
                            User:{" "}
                            <Link
                                className="text-cyan-500 font-semibold hover:underline"
                                to={`/profile/${props.username}`}
                            >{props.username}
                            </Link>
                        </p>
                        <p>Population: {props.cityPop}</p>
                    </div>


                    <div className="text-center">
                        <p>
                            (X: {props.xCoord} Y: {props.yCoord})
                        </p>
                        {props.isMyCity ? (
                            <div>My City</div>
                        ) : (
                            <div>
                                {cityRestrictions.hasBarracks ? <button
                                        className="bg-white text-blue-500 px-4 py-2 rounded shadow-md hover:text-purple-600 focus:outline-none mt-2 text-xs sm:text-sm md:text-base"
                                        onClick={() => setShowAttackMenu(true)}
                                    >
                                        Attack
                                    </button> :
                                    <div className="text-red-400">You need a barracks in your city to attack!</div>
                                }
                            </div>

                        )}
                    </div>

            </div>

                </div>
                </div>
            </div>
        );
    }
    return (
        <div className="fixed bottom-0 left-0 w-full h-full grid place-items-center overflow-auto">
            <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={props.closeModal}
            > </div>
            <div className="relative bg-mainLightBrown4 p-4 rounded-lg">
                <div className="bg-mainLightGreen2 p-2">


                <button
                    className="absolute top-0 right-0 mt-2 mr-2 text-white font-bold text-xxs sm:text-xs focus:outline-none"
                    onClick={props.closeModal}
                >
                    &times;
                </button>
        <div className="w-[40vw] grid place-items-center">
            <MapAttackCard hideMenu={hideAttackMenu} cityId={props.cityId} targetCity={props.cityName} targetUser={props.username}/>
        </div>
            </div>
            </div>
        </div>
    );
}