import {useState} from "react";

export const EditCityNameModal = ({
                               cityName,
                               setCityName,
                               closeModal,
                               updateCityName,
                           }) => {

    const [status, setStatus] = useState("")

    const handleCityNameChange = (event) => {
        setCityName(event.target.value);
    };

    function saveChange() {
        setStatus("")

        if(cityName.length > 15){
            setStatus("City name is too long!")
            return
        }

        updateCityName()
    }

    return (
        <div className="fixed z-10 inset-0 flex items-center justify-center">
            <div className="flex justify-center pt-4 px-4 pb-20 text-center">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    onClick={closeModal}
                > </div>

                <div className="inline-block align-bottom bg-mainLightYellow rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">
                                    Edit City Name
                                </h3>
                                <div className="mt-2">
                                    <input
                                        maxLength={15}
                                        type="text"
                                        value={cityName}
                                        onChange={handleCityNameChange}
                                        className="w-full px-3 py-2 text-gray-50 bg-mainLightBrown3 border-2 rounded-lg focus:outline-none focus:border-blue-500"
                                    />
                                </div>

                                {status && <h4 className="text-red-400">{status}</h4>}
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-3 sm:px-6 flex justify-center">
                        <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center text-white rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-gray-700 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-mainDarkBrown text-base font-medium text-white hover:bg-mainLightBrown2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={saveChange}
                        >
                            Save
                        </button>

                    </div>
                </div>
            </div>
        </div>
    );
};