import {useState} from "react";
import {TabItem} from "./Marketplace";
import {CasinoOverviewCard} from "../cards/CasinoOverviewCard";
import {CasinoBlackjack} from "../cards/CasinoBlackjack";
import {CasinoSlots} from "../cards/CasinoSlots";
import {ChestInventory} from "../cards/casinoChests/ChestInventory";
import {HighScoreNavDropDown} from "../lists/HighScoreNavDropDown";
import {CasinoBuyIn} from "./CasinoBuyIn";

export function CasinoCard({hideUpgradeMenu}){
    const [activeTab, setActiveTab] = useState("overview");

    const handleTabChange = (tab) => {
        if(tab === "overview"){
            hideUpgradeMenu(true)
        }else {
            hideUpgradeMenu(false)
        }
        setActiveTab(tab);
    };

    const options = [
        { value: "overview", text: "Overview" },
        { value: "buyIn", text: "Buy In" },
        { value: "blackjack", text: "Blackjack" },
        { value: "slots", text: "Slots" },
        { value: "chests", text: "Chests" },
    ];


    return <div className="flex flex-col ">
        <div className="mb-0">
            <ul className="flex md:flex hidden text-lg sm:text:lg md:text-sm lg:text-base xl:text-lg text-white pb-4">
                <TabItem
                    label="Overview"
                    active={activeTab === "overview"}
                    onClick={() => handleTabChange("overview")}
                />
                <TabItem
                    label="Buy In"
                    active={activeTab === "buyIn"}
                    onClick={() => handleTabChange("buyIn")}
                />
                <TabItem
                    label="Blackjack"
                    active={activeTab === "blackjack"}
                    onClick={() => handleTabChange("blackjack")}
                />
                <TabItem
                    label="Slots"
                    active={activeTab === "slots"}
                    onClick={() => handleTabChange("slots")}
                />
                <TabItem
                    label="Chests"
                    active={activeTab === "chests"}
                    onClick={() => handleTabChange("chests")}
                />

            </ul>
            <div className="flex justify-center mb-2 z-[1000]">
                <HighScoreNavDropDown options={options} onOptionChange={(value) => handleTabChange(value)} showOption={activeTab}/>
            </div>
        </div>

    {activeTab === "overview" && <CasinoOverviewCard />}
    {activeTab === "buyIn" && <CasinoBuyIn />}
    {activeTab === "blackjack" && <CasinoBlackjack />}
    {activeTab === "slots" && <CasinoSlots />}
    {activeTab === "chests" && <ChestInventory/>}


    </div>
}

