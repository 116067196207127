export const timerActions = {
    ADD_TIMER: "ADD_TIMER",
    TICK: "TICK",
    REMOVE_EXPIRED: "REMOVE_EXPIRED",
    CLEAR_ALL: "CLEAR_ALL",
    CLEAR_BARRACKS: "CLEAR_BARRACKS",
    CLEAR_MAPCHAMBER: "CLEAR_MAPCHAMBER",
};

// Timer categories:

const initialState = {
    buildingTimers: [],
    resourceTimers: [],
    trainTroopsTimers: [],
    trainFoundersTimers: [],
    sendResourcesTimers: [],
    retrieveTradersTimers: [],
    incomingResourceTimers: [],
    outgoingAttackTimer: [],
    incomingAttackTimer: [],
    returningAttackTimer: [],
    foundNewCityTimer: [],
}


export const timerReducer = (state = initialState, action) => {
    switch (action.type) {
        case timerActions.ADD_TIMER:
            const category = action.category;
            return {
                ...state,
                [category]: [...state[category], action.payload].sort((a, b) => a.endTime - b.endTime)
            };

        case timerActions.TICK:
            const now2 = new Date().getTime();

            return {
                buildingTimers: state.buildingTimers.map(timer => ({
                    ...timer,
                    timeLeft: Math.round((timer.endTime - now2) / 1000)
                })),
                resourceTimers: state.resourceTimers.map(timer => ({
                    ...timer,
                    timeLeft: Math.round((timer.endTime - now2) / 1000)
                })),
                trainTroopsTimers: state.trainTroopsTimers.map(timer => ({
                    ...timer,
                    timeLeft: Math.round((timer.endTime - now2) / 1000),
                    timeLeftNextUnit: timer.timeLeftNextUnit - 1
                })),
                trainFoundersTimers: state.trainFoundersTimers.map(timer => ({
                    ...timer,
                    timeLeft: Math.round((timer.endTime - now2) / 1000),
                    timeLeftNextUnit: timer.timeLeftNextUnit - 1
                })),
                sendResourcesTimers: state.sendResourcesTimers.map(timer => ({
                    ...timer,
                    timeLeft: Math.round((timer.endTime - now2) / 1000)
                })),
                retrieveTradersTimers: state.retrieveTradersTimers.map(timer => ({
                    ...timer,
                    timeLeft: Math.round((timer.endTime - now2) / 1000)
                })),
                incomingResourceTimers: state.incomingResourceTimers.map(timer => ({
                    ...timer,
                    timeLeft: Math.round((timer.endTime - now2) / 1000)
                })),
                outgoingAttackTimer: state.outgoingAttackTimer.map(timer => ({
                    ...timer,
                    timeLeft: Math.round((timer.endTime - now2) / 1000)
                })),
                incomingAttackTimer: state.incomingAttackTimer.map(timer => ({
                    ...timer,
                    timeLeft: Math.round((timer.endTime - now2) / 1000)
                })),
                returningAttackTimer: state.returningAttackTimer.map(timer => ({
                    ...timer,
                    timeLeft: Math.round((timer.endTime - now2) / 1000)
                })),
                foundNewCityTimer: state.foundNewCityTimer.map(timer => ({
                    ...timer,
                    timeLeft: Math.round((timer.endTime - now2) / 1000)
                }))
            };


        case timerActions.REMOVE_EXPIRED:
            const now = new Date().getTime();

            return {
                buildingTimers: state.buildingTimers.filter(timer => timer.endTime > now),
                resourceTimers: state.resourceTimers.filter(timer => timer.endTime > now),
                trainTroopsTimers: state.trainTroopsTimers.filter(timer => timer.endTime > now),
                trainFoundersTimers: state.trainFoundersTimers.filter(timer => timer.endTime > now),
                sendResourcesTimers: state.sendResourcesTimers.filter(timer => timer.endTime > now),
                retrieveTradersTimers: state.retrieveTradersTimers.filter(timer => timer.endTime > now),
                incomingResourceTimers: state.incomingResourceTimers.filter(timer => timer.endTime > now),
                outgoingAttackTimer: state.outgoingAttackTimer.filter(timer => timer.endTime > now),
                incomingAttackTimer: state.incomingAttackTimer.filter(timer => timer.endTime > now),
                returningAttackTimer: state.returningAttackTimer.filter(timer => timer.endTime > now),
                foundNewCityTimer: state.foundNewCityTimer.filter(timer => timer.endTime > now),
            };

        case timerActions.CLEAR_ALL:
            return  {
                buildingTimers: [],
                resourceTimers: [],
                trainTroopsTimers: [],
                trainFoundersTimers: [],
                sendResourcesTimers: [],
                retrieveTradersTimers: [],
                incomingResourceTimers: [],
                outgoingAttackTimer: [],
                incomingAttackTimer: [],
                returningAttackTimer: [],
                foundNewCityTimer: [],
            }
        case timerActions.CLEAR_BARRACKS:

            return  {
                buildingTimers: state.buildingTimers,
                resourceTimers: state.resourceTimers,
                trainTroopsTimers: [],
                trainFoundersTimers: state.trainFoundersTimers,
                sendResourcesTimers: state.sendResourcesTimers,
                retrieveTradersTimers: state.retrieveTradersTimers,
                incomingResourceTimers: state.incomingResourceTimers,
                outgoingAttackTimer: state.outgoingAttackTimer,
                incomingAttackTimer: state.incomingAttackTimer,
                returningAttackTimer: state.returningAttackTimer,
                foundNewCityTimer: state.foundNewCityTimer,
            }
        case timerActions.CLEAR_MAPCHAMBER:
            return  {
                buildingTimers: state.buildingTimers,
                resourceTimers: state.resourceTimers,
                trainTroopsTimers: state.trainTroopsTimers,
                trainFoundersTimers: [],
                sendResourcesTimers: state.sendResourcesTimers,
                retrieveTradersTimers: state.retrieveTradersTimers,
                incomingResourceTimers: state.incomingResourceTimers,
                outgoingAttackTimer: state.outgoingAttackTimer,
                incomingAttackTimer: state.incomingAttackTimer,
                returningAttackTimer: state.returningAttackTimer,
                foundNewCityTimer: state.foundNewCityTimer,
            }

        default:
            return state;
    }
}