import {useEffect, useState} from "react";
import Wheat from "./WheatDetails.png"
import Wood from "./WoodDetails.png"
import Stone from "./StoneDetails.png"
import Iron from "./IronDetails.png"

export function ResFieldInfoImage({type}){
    const [imageLink, setImageLink] = useState()

    useEffect(() => {

        if(type === "Wheat"){
            setImageLink(Wheat)
        }else if(type === "Wood"){
            setImageLink(Wood)
        }else if(type === "Stone"){
            setImageLink(Stone)
        }else if(type === "Iron"){
            setImageLink(Iron)
        }
    }, [])

    if(!imageLink){
        return <div>Loading...</div>
    }

    return <img src={imageLink} alt="Resource image" className="w-[30vw] md:w-[18vw] min-w-[180px]"/>
}