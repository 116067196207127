import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "../../index.jsx";

import { WheatSpot } from "./WheatSpot";
import { WoodSpot } from "./WoodSpot";
import { StoneSpot } from "./StoneSpot";
import { IronSpot } from "./IronSpot";
import {useTimerState} from "../contexts/timerContext";


export function ResourceField() {
  const { cityResourceFields } = useContext(CityConfigContext);
  const [newResField, setNewResField] = useState([]);

  const globalTimers = useTimerState();

  const [buildingTimers, setBuildingTimers] = useState(
      globalTimers.resourceTimers
  )

  useEffect(() => {
    setBuildingTimers( globalTimers.resourceTimers)
  }, [globalTimers])

  function updateBuildingStatus(){
    if(buildingTimers.length > 0 && cityResourceFields){
      const currentTime = new Date().getTime()
      cityResourceFields.forEach((buildingItem) => {

        let highestLevel = 0;
        buildingTimers.forEach((timerItem) => {
          if(timerItem.location === buildingItem.location){
            if(timerItem.extra.next_level > highestLevel){
              highestLevel = timerItem.extra.next_level
            }

            if(timerItem.endTime > currentTime && highestLevel !== buildingItem.level){
              buildingItem.status = `${buildingItem.level} -> ${highestLevel}`;
              buildingItem.nextLevel = highestLevel;
            }
          }

        })
      })
    }
  }


  useEffect(() => {
    if(cityResourceFields){

      updateBuildingStatus()

    }
  }, [cityResourceFields, buildingTimers])

/*  useEffect(() => {

    if (cityResourceFields && buildingTimers.length > 0) {
      const currentTime = new Date().getTime();


      cityResourceFields.forEach((resItem) => {

        let highestLvl = 0;
        buildingTimers.forEach((timerItem) => {

          if (timerItem.location === resItem.location) {
            if (timerItem.extra.next_level > highestLvl) {
              highestLvl = timerItem.extra.next_level;
            }

            if (timerItem.endTime > currentTime) {
              resItem.status = `${resItem.level} -> ${highestLvl}`;
              resItem.nextLevel = highestLvl;
            }
          }
        });
      });
    }
  }, [buildingTimers]);*/


  useEffect(() => {

    setNewResField(cityResourceFields);
  }, [cityResourceFields]);

  if (!newResField) {
    return <div>Loading..</div>;
  }
  return (
      <div className="mx-4 md:mx-0">
        <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4">
          <div className="grid grid-cols-2 xs:grid-cols-3  sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 max-w-full scrollbar scrollbar-thumb-mainLightGreen2 scrollbar-thick bg-mainLightYellow p-4  max-h-[74vh] min-h-0 overflow-auto">
            {newResField.map((field, index) => {

              let Component;
              if (index <= 5) Component = WheatSpot;
              else if (index <= 9) Component = WoodSpot;
              else if (index <= 13) Component = StoneSpot;
              else Component = IronSpot;

              return field ? (
                  <Component newField={field} className="sm:max-w-xs md:max-w-sm lg:max-w-md" key={index} />
              ) : (
                  <div key={index}>No field exists</div>
              );
            })}
          </div>
        </div>
      </div>
  );

}