import {useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import {CityConfigContext} from "../../index";
import WheatField from '../../images/resourceFields/WheatField2.png'

export function WheatSpot(props){
    const {setSelectedResField } = useContext(CityConfigContext);
    const navigate = useNavigate();
    const [isClicked, setClicked] = useState(false);

    function upgradeResource(resourceField) {
        setSelectedResField(resourceField)
        localStorage.setItem("selectedResField", JSON.stringify(resourceField))
        navigate("/resourcefield/upgrade");
        setClicked(true);
        setTimeout(() => setClicked(false), 200); // Reset isClicked state after 200ms
    }

    return (
        <div
            className="bg-gradient-to-br from-cityCard1 to-gradientWheat1 rounded-lg shadow-lg p-2 transition-colors duration-500 ease-in-out hover:scale-[103%] cursor-pointer aspect-[1/1] w-full"
            onClick={() => upgradeResource(props.newField)}
        >
            <div className="aspect-content">
                <img
                    src={WheatField}
                    alt="Wheat image"
                    className={`w-full h-full object-cover rounded-lg transition-transform duration-500 ${isClicked ? 'animate-pulse' : ''}`}
                />
                <div className="mt-4 bg-wheatUpgradeButton rounded-full flex justify-center items-center">
                    {props.newField.status
                        ? <div className="text-white">{props.newField.status}</div>
                        : <span className="text-white">{props.newField.level}</span>
                    }
                </div>
            </div>
        </div>
    )
}
