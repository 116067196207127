import React, { useContext, useEffect, useState } from "react";
import {CityConfigContext, UserContext, WarehouseContext} from "../../index";
import { RULE_CONFIG } from "../../RULES.js";

export function SellResourcesCard(props) {
  const { warehouse } = useContext(WarehouseContext);
  const { inCityId, tradersCount } = useContext(CityConfigContext);
  const resources = ["wheat", "wood", "stone", "iron"];
  const [resourceToSell, setResourceToSell] = useState(resources[0]);
  const [resourceToReceive, setResourceToReceive] = useState(resources[1]);
  const [amountToSell, setAmountToSell] = useState(0);
  const [amountToReceive, setAmountToReceive] = useState(0);
  const [status, setStatus] = useState("");
  const [canCarry, setCanCarry] = useState(0);
  const { setShowSpinner } = useContext(UserContext);

  function calculateCanCarry() {
    if (tradersCount.canCarryTotal) {
      const sum = tradersCount.canCarryTotal - amountToSell;
      setCanCarry(sum);
    }
  }

  useEffect(() => {
    calculateCanCarry();
  }, [tradersCount, amountToSell]);

  async function handleSell() {
    setStatus("");


    if (amountToSell > 0 && amountToReceive > 0) {
      const maxCarryCapacity =
        tradersCount.tradersInCity * RULE_CONFIG.merchantCarryAmount;
      const maxTotalCapacity =
        RULE_CONFIG.maxMerchantAmount * RULE_CONFIG.merchantCarryAmount;
      if (amountToSell > warehouse[resourceToSell]) {
        setStatus(`You don't have enough ${resourceToSell} to sell!`);
        return;
      }

      if (amountToSell > maxCarryCapacity) {
        setStatus(`You're trying to sell more than your traders can carry!`);
        return;
      }
      if (amountToReceive > maxTotalCapacity) {
        setStatus(`You're trying to buy more than any traders can carry!`);
        return;
      }
      setShowSpinner(true)
      const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/marketplace/sell/res`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          resourceToSell,
          amountToSell,
          resourceToReceive,
          amountToReceive,
          inCityId,
        }),
        credentials: "include"

      });
      if (res.status === 200) {
        props.onSellComplete();
      }
    } else {
      setStatus("You can not do that!");

    }
    setTimeout(() => {
      setShowSpinner(false)
    }, 1700)
  }

  return (
    <div className="p-4 p-2 rounded bg-mainLightBrown1">
      <div className="bg-mainLightBrown1">


      <h2 className="text-xl mb-4 text-center">Sell Resources</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block mb-2">Resource to sell</label>
          <select
            value={resourceToSell}
            onChange={(e) => setResourceToSell(e.target.value)}
            className="p-2 bg-mainLightBrown3 rounded w-full"
          >
            {resources.map((resource) => (
              <option key={resource} value={resource}>
                {resource}
              </option>
            ))}
          </select>
          <label className="block mt-4 mb-2">Amount</label>
          <input
            type="number"
            max={canCarry}
            value={amountToSell}
            onChange={(e) => setAmountToSell(e.target.value)}
            className="p-2 bg-mainLightBrown3 rounded w-full"
          />
        </div>
        <div>
          <label className="block mb-2">Resource to receive</label>
          <select
            value={resourceToReceive}
            onChange={(e) => setResourceToReceive(e.target.value)}
            className="p-2 bg-mainLightBrown3 rounded w-full"
          >
            {resources.map((resource) => (
              <option key={resource} value={resource}>
                {resource}
              </option>
            ))}
          </select>
          <label className="block mt-4 mb-2">Amount</label>
          <input
            type="number"
            max={
              RULE_CONFIG.maxMerchantAmount * RULE_CONFIG.merchantCarryAmount
            }
            value={amountToReceive}
            onChange={(e) => setAmountToReceive(e.target.value)}
            className="p-2 bg-mainLightBrown3 rounded w-full"
          />
        </div>
      </div>
        <div className="flex justify-center">
          <button
              onClick={handleSell}
              className="mt-4 px-4 py-2 bg-mainDarkBrown text-white rounded "
          >
            Sell
          </button>
        </div>

      <div className="text-center">
        <div>
          Traders available: {tradersCount.tradersInCity}/
          {tradersCount.maxTraders}
        </div>
        <div>Can carry: {canCarry} resources</div>
      </div>
      {status && <div className="text-red-500">{status}</div>}
    </div>
    </div>
  );
}
