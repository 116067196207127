import {React, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {PaginationControls} from "../PaginationControls";

export function HighScoreAllAlliancesList({pageNumber, setPageNumber, totalPageCount, setTotalPageCount}){

/*    const [pageNumber, setPageNumber] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);*/
    const [alliancePage, setAlliancePage] = useState([]);
    const navigate = useNavigate();


    const getDataCount = async () => {

        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/highscores/count/all/alliances`, {
            credentials: "include"
        });
        const data = await res.json();

        setTotalPageCount(data);
    };

    async function getPage() {
        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/highscores/all/alliances/${pageNumber}`, {
            credentials: "include"
        });
        const data = await res.json();
        setAlliancePage(data);
    }

    useEffect(() => {
        const getData = async () => {
            await getPage();
        };
        if (alliancePage.length === 0) {
            getDataCount();
            getData();
        }
    }, []);

    useEffect(() => {
        const getData = async () => {
            await getPage();
        };
        getData();
    }, [pageNumber]);

    if (alliancePage.length <= 0) {
        return <div>No alliances yet</div>;
    }


    return <div className="w-full overflow-x-auto text-lg sm:text-lg md:text-xl lg:text-xl xl:text-xl">

        {/*MEDIUM AND BIG SCREEN*/}
        <div className="min-w-[60vw] hidden md:block">
        <div className="grid grid-cols-5 gap-4 bg-mainLightBrown1 text-center pt-2 pb-2 font-semibold">
            <div>Rank</div>
            <div>Name</div>
            <div>Total pop</div>
            <div>Tag</div>
            <div>Players</div>
        </div>


            {alliancePage.map((alliance, index) => (
                <div  className={`grid grid-cols-5 ${index % 2 === 0 ? 'bg-mainLightBrown3': 'bg-mainLightBrown1'} text-center pt-2 pb-2 text-sm md:text-sm lg:text-lg`} key={index}>
                    <div>{alliance.rank}</div>
                    <Link to={`/alliance/${alliance.tag}`}>{alliance.name}</Link>
                    <div>{alliance.total_population}</div>
                    <Link to={`/alliance/${alliance.tag}`}>#{alliance.tag}</Link>
                    <div>{alliance.players}</div>
                </div>
            ))}
        </div>

        {/*SMALL SCREEN*/}
        <div className="block md:hidden">

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                {alliancePage.map((alliance, index) => (<div className={` ${index % 2 === 0 ? 'bg-mainLightBrown3': 'bg-mainLightBrown1'} text-center pt-2 pb-2 text-xs xs:text-base`} key={index}
                                                             onClick={() => navigate(`/alliance/${alliance.tag}`)}>
                    <h4 className="font-semibold">Rank: {alliance.rank}</h4>
                    <h4>{alliance.name} #{alliance.tag}</h4>
                    <div className="flex justify-center">
                        <h4>Pop: {alliance.total_population}</h4>
                        <h4 className="ml-2">Players: {alliance.players}</h4>
                    </div>
                </div>))}
            </div>

        </div>

        <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPageCount={totalPageCount}/>


    </div>
}