import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../index";
import {ConfirmModal} from "../modals/ConfirmModal";

export function ChangeAllianceRoleCard(props) {
    const { userInfo } = useContext(UserContext);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [roleName, setRoleName] = useState("");
    const [permissions, setPermissions] = useState({
        canInvite: "",
        canKick: "",
        canEditDescription: "",
        canEditPermissions: ""
    });
    const [errorText, setErrorText] = useState();
    const [isFormReady, setIsFormReady] = useState(false);
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {

        const getData = async () => {
            const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/owner/${props.role.alliance_id}`, {
                credentials: "include"
            })
            const ownerName = await res.text();
            if (props.members && userInfo) {
                const membersWithoutLoggedInUser = props.members.filter(
                    (member) => member.username !== userInfo.username && member.username !== ownerName
                );
                setFilteredMembers(membersWithoutLoggedInUser);
            }
        }

        getData()


    }, [props.members, userInfo]);

    useEffect(() => {
        setIsFormReady(
            roleName !== "" || Object.values(permissions).some((value) => value !== "")
        );
    }, [permissions, roleName]);

    const handleRoleChange = async () => {
        setErrorText(undefined);
        if (!isFormReady) {
            setErrorText("Please choose something.");
            return;
        }

        if (!selectedMember) {
            setErrorText("Please select a member.");
            return;
        }

        if(roleName.length > 15){
            setErrorText("Role name too long, max 15 characters!")
            return
        }

        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/edit/role`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                userId: selectedMember.user_id,
                username: selectedMember.username,
                allianceId: props.role.alliance_id,
                newRole: {
                    roleName: roleName,
                    permissions: permissions,
                },
            }),
            credentials: "include"
        });

        if (res.status === 400) {
            const error = await res.text();
            setErrorText(error);
        }else{
            setErrorText("User updated!")
        }
    };
    const handleAllianceTransfer = async () => {


        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/transfer`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                userId: selectedMember.user_id,
                username: selectedMember.username,
                allianceId: props.role.alliance_id,
            }),
            credentials: "include"
        });

        if (res.status === 400) {
            const error = await res.text();
            setErrorText(error);
        }else{
            setErrorText("Ownership transfered!")
        }

        setShowModal(false);

    };

    const handlePermissionChange = (e) => {
        setPermissions({
            ...permissions,
            [e.target.name]: e.target.value,
        });
    };

    return (
       <div className="flex justify-center ">
           <div >
               <div >
                   <h3 className="text-xl">Change role for</h3>
                   <ul className="mb-2">
                       {filteredMembers.map((member) => (
                           <li key={member.user_id}>
                               <label>
                                   <input

                                       type="radio"
                                       name="member"
                                       value={member.user_id}
                                       onChange={() => setSelectedMember(member)}
                                       maxLength={15}
                                   />
                                   {member.username} ({member.role})
                               </label>
                           </li>
                       ))}
                   </ul>
               </div>

               <div className="mb-4">
                   <label className="font-semibold">
                       <h4 className="mr-2">Role name (optional):</h4>
                       <input
                           className="bg-mainLightBrown3 text-white"
                           type="text"
                           maxLength={15}
                           value={roleName}
                           onChange={(e) => setRoleName(e.target.value)}
                       />
                   </label>
               </div>

               <div className="mb-4">
                   <h4 className="font-semibold">Permissions:</h4>
                   {Object.entries(permissions).map(([key, value]) => (
                       <div key={key}>
                           <label className="font-semibold ">
                               <h4 className="mb-1">{key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}:</h4>
                               <select className="bg-mainLightBrown3 text-white mb-2" name={key} value={value} onChange={handlePermissionChange}>
                                   <option value="">Choose...</option>
                                   <option value="true">Yes</option>
                                   <option value="false">No</option>
                               </select>
                           </label>
                       </div>
                   ))}
               </div>
               {selectedMember && (
                   <button onClick={handleRoleChange} className="bg-mainDarkBrown text-white mb-2">
                       Change {selectedMember.username}'s role
                   </button>
               )}

               {props.role.candoeverything && selectedMember && <div>
                   <button onClick={() => setShowModal(true)} className="px-4 py-2 bg-red-600 text-white rounded-lg">Transfer ownership to <div>{selectedMember.username}</div></button>

               </div>}
               {showModal && (
                   <ConfirmModal
                       onConfirm={handleAllianceTransfer}
                       onCancel={() => setShowModal(false)}
                   />
               )}

               {errorText && <div className="text-red-500">{errorText}</div>}
           </div>
       </div>
    );
}
