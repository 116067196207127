import { useState} from "react";
import {HelperFunctions} from "../../../HelperFunctions";
import {LootboxModal} from "../../modals/LootboxModal";
import LootChestClosed from "../../../images/LootChestClosed.png"

export function InventorySpotCard({index, item, setNewItems, setUnclaimedPrize}){
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [itemRewardPool, setItemRewardPool] = useState([]);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const handleClick = () => setIsClicked(!isClicked);

    const shouldDisplay = isHovered || isClicked;


    const openChest = async () => {
        // Send request to server for opening a chest
        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/casino/inventory/open`, {credentials: "include"})
        // Should start an animation to cycle through some items until ending on the chosen item



        if(res.status === 200){

            const itemPool = HelperFunctions.getShuffledItemPoolArray()

            const data = await res.json();
            // updates the amount of chests
            setNewItems(data.newInventory)

            // Put the reward as the second last item in array
            const reward = HelperFunctions.setLootboxImageByType(data.item)
            itemPool.push(reward)
            // give two random item in the end so it looks like a full carousel
            const randomitems = HelperFunctions.getRandomItemFromArray()

            randomitems.forEach((itemThing) => {
                itemPool.push(itemThing)
            })

            setItemRewardPool(itemPool);
            setShowModal(true);
            // Start displaying items

        }



    }

    function closeModal(){
        setShowModal(false)
    }



    return  <div
        key={index}
        className="relative bg-mainLightBrown3 border-1 w-full overflow-hidden border border-black border-2"
        style={{paddingBottom: '100%'}}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
    >
        <div className="absolute inset-0 flex items-center justify-center">
            {item === "Chest" && (
                <img
                    className={`${shouldDisplay ? 'filter blur-md' : ''}`}
                    src={LootChestClosed}
                    alt="lootChest"
                />
            )}
            {item === "empty" && <div> </div>}

            {item === "Chest" && shouldDisplay && (
                <button className="absolute opacity-90 bg-green-500 text-white px-2 py-1 rounded" onClick={openChest}>Open</button>
            )}
        </div>
        {showModal &&<LootboxModal   pool={itemRewardPool} setUnclaimed={setUnclaimedPrize} closeModal={closeModal} />}
    </div>
}

