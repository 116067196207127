import { useNavigate } from "react-router-dom";
import {useContext, useState} from "react";
import {CityConfigContext} from "../../index";
import WoodField from '../../images/resourceFields/WoodField.png'

export function WoodSpot(props) {
    const {setSelectedResField } = useContext(CityConfigContext);
    const navigate = useNavigate();
    const [isHovered, setHovered] = useState(false);
    const [isClicked, setClicked] = useState(false);

    function upgradeResource(resourceField) {
        setSelectedResField(resourceField)
        localStorage.setItem("selectedResField", JSON.stringify(resourceField))
        navigate("/resourcefield/upgrade");
        setClicked(true);
        setTimeout(() => setClicked(false), 200);
    }

    if(!props.newField.location) {
        return <div className={props.pos}>Under construction</div>
    }

    return (
        <div
            className={`${props.pos} bg-gradient-to-br from-gradientWood2 to-gradientWood1 rounded-lg shadow-lg p-2 transition-colors duration-500 ease-in-out hover:scale-[103%] cursor-pointer aspect-[1/1] w-full`}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => upgradeResource(props.newField)}
        >
            <div className="aspect-content">
                <img
                    src={WoodField}
                    alt="Wood image"
                    className={`w-full h-full object-cover rounded-lg transition-transform duration-500 ease-in-out  ${isClicked ? 'animate-pulse' : ''}`}
                />
                <div className="mt-4 bg-woodUpgradeButton rounded-full flex justify-center items-center">
                    {props.newField.status
                        ? <div className="text-white">{props.newField.status}</div>
                        : <span className="text-white">{props.newField.level}</span>
                    }
                </div>
            </div>
        </div>
    )
}
