import { useState, useEffect } from 'react';
import { useSprings, animated } from '@react-spring/web';
import { getRarityColor } from "../../HelperFunctions";
import LootBoxOpen from "../../images/LootChestOpen.png"
export function LootboxModal({ pool, setUnclaimed, closeModal }) {
    const [index, setIndex] = useState(0);
    const [rarityColor, setRarityColor] = useState(getRarityColor(pool[0].rarity));
    const [randomOffset] = useState(Math.random() * 50 - 25); // Generates a random number between -25 and 25
    const [springs, set] = useSprings(pool.length, i => ({ x: i * 100 }));



    useEffect(() => {
        const timer = setInterval(() => {
            if (index < pool.length - 3) {
                setIndex(index + 1);
                setRarityColor(getRarityColor(pool[index + 1].rarity));
                set(i => ({ x: (i - index - 1) * 100 + (index === pool.length - 4 ? randomOffset : 0)}));
            }
        }, 120);

        return () => clearInterval(timer);
    }, [index, pool, set, randomOffset]);

    useEffect(() => {
        if (index === pool.length - 3) {
            setTimeout(() => {
                setUnclaimed(pool[index]);
                closeModal();
            }, 1500);
        }
    }, [index]);

    if (!pool || pool.length === 0) {
        return null;
    }

    return (
        <div className="fixed inset-0 flex md:items-center md:justify-center top-[30vh] md:top-[0vh] z-10">
            <div className="inline-block align-bottom bg-green-400 h-[50vh] rounded-lg overflow-hidden shadow-xl mx-8 w-full md:w-1/2 flex items-center justify-center flex-col">
                <div className={`flex overflow-hidden justify-center relative ${rarityColor} w-96 h-[100px]`}>
                    {springs.map(({ x }, i) => (
                        <animated.div
                            key={i}
                            style={{
                                transform: x.to(x => `translateX(${x}%)`),
                                position: 'absolute'
                            }}
                            className={`w-[100px] h-[100px] border-black border-2 flex items-center justify-center ${getRarityColor(pool[i].rarity)}`}
                        >
                            {/*<div>{pool[i].name}</div>*/}
                            <div className="flex justify-center flex-col">
                                <p className="text-xl text-center mb-2 font-semibold">{pool[i].text}</p>

                                <img src={pool[i].image} alt="Loot chest loot" className="w-[50px] h-[50px]"/>
                            </div>



                        </animated.div>
                    ))}
                    {/*Selector*/}
                    <div style={{position: 'absolute', top: '0', width: '2px', height: '10px', backgroundColor: '#000', left: '50%', transform: 'translateX(-50%)'}}/>
                </div>
                <div className="flex-shrink-0">
                    <img
                        src={LootBoxOpen}
                        alt="chest"
                        className="object-contain h-32 mt-8"
                    />
                </div>
            </div>
        </div>
    );
}
