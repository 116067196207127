import { React, useContext, useEffect, useState } from "react";
import {CityConfigContext, UserContext, WarehouseContext} from "../../index";
import { Link } from "react-router-dom";
import { RULE_CONFIG } from "../../RULES.js";
import {PaginationControls} from "../PaginationControls";
import {HighScoreNavDropDown} from "../lists/HighScoreNavDropDown";
import {useWindowSize} from "../useWindowSize";
import {WheatIconResponsive} from "../../images/resources/WheatIconResponsive";
import {WoodIconReponsive} from "../../images/resources/WoodIconReponsive";
import {StoneIconResponsive} from "../../images/resources/StoneIconResponsive";
import {IronIconResponsive} from "../../images/resources/IronIconResponsive";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faThumbtack} from "@fortawesome/free-solid-svg-icons";


export function BuyResourcesCard(props) {
  const { warehouse } = useContext(WarehouseContext);
  const { inCityId, tradersCount } = useContext(CityConfigContext);
  const [allListings, setAllListings] = useState();
  const [statusText, setStatusText] = useState("");
  const [selectedResource, setSelectedResource] = useState("all");
  const { setShowSpinner } = useContext(UserContext);
  const { width } = useWindowSize();
  const breakpoint = 1200;

  const options = [
    { value: "all", text: "All" },
    { value: "wheat", text: "Wheat" },
    { value: "wood", text: "Wood" },
    { value: "stone", text: "Stone" },
    { value: "iron", text: "Iron" },
    { value: "yours", text: "Mine" },
  ];

  const selectedTabStyle = "text-white font-bold py-2 px-4 rounded mr-2 bg-cyan-700";
  const unselectedTabStyle = "text-white font-bold py-2 px-4 rounded mr-2 bg-mainDarkBrown";


  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);

  async function purchaseListing(listingId) {
    setShowSpinner(true)
    setStatusText("");
    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/marketplace/purchase/listing`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ listingId, inCityId }),
      credentials: "include"

    });

    if (res.status === 401) {
      const data = await res.text();
      setStatusText(data);
    }
    if (res.status === 200) {
      props.onBuyComplete();
    }
    setTimeout(() => {
      setShowSpinner(false)
    }, 1700)
  }

  const getDataCount = async (filter) => {
    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/marketplace/resource/count/${filter}/${inCityId}/all`, {
          credentials: "include"
        }
    );

    const data = await res.json();

    setTotalPageCount(data);
  };

  async function getAllListings(filter) {
    setStatusText("");
    setAllListings(undefined);

    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/marketplace/resource/listing/${filter}/${pageNumber}`, {
          credentials: "include"
        }
    );

    if (res.status === 200) {
      const data = await res.json();

      setAllListings(data);
    }
    if (res.status === 201) {
      const data = await res.text();
      setAllListings([]);
      setStatusText(data);
    }
  }
  async function removeListing(listingId) {
    await fetch(`${import.meta.env.VITE_GAMEHOST}/api/marketplace/delete/listing/${listingId}`, {
      credentials: "include"
    });
    getDataCount(selectedResource);
    getAllListings(selectedResource);
  }

  useEffect(() => {
    getDataCount(selectedResource);
    getAllListings(selectedResource);
  }, [selectedResource]);

  useEffect(() => {
    getAllListings(selectedResource);
  }, [pageNumber]);

  if (!allListings) {
    return <div>Loading...</div>;
  }

  function canTrade(listing) {
    // check if the warehouse has enough of the needed resource
    if (warehouse[listing.resTypeNeeded] < listing.resAmountNeeded) {
      return "Not enough resources.";
    }

    // check if there are enough traders
    let neededTraders = Math.ceil(
      listing.resAmountNeeded / RULE_CONFIG.merchantCarryAmount
    );
    if (tradersCount.tradersInCity < neededTraders) {
      return "Not enough traders.";
    }

    // if all checks pass
    return "";
  }

  if(width < breakpoint){
    return <div>
      {!props.isOuterDropDownOpen &&
      <div className="flex justify-center">



        <div className="flex md:flex hidden text-lg sm:text:lg md:text-sm lg:text-base xl:text-lg text-white pb-4">
          <button
              className={selectedResource === "all" ? selectedTabStyle : unselectedTabStyle}
              onClick={() => setSelectedResource("all")}
          >
            All
          </button>
          <button
              className={selectedResource === "wheat" ? selectedTabStyle : unselectedTabStyle}
              onClick={() => setSelectedResource("wheat")}
          >
            Wheat
          </button>
          <button
              className={selectedResource === "wood" ? selectedTabStyle : unselectedTabStyle}
              onClick={() => setSelectedResource("wood")}
          >
            Wood
          </button>
          <button
              className={selectedResource === "stone" ? selectedTabStyle : unselectedTabStyle}
              onClick={() => setSelectedResource("stone")}
          >
            Stone
          </button>
          <button
              className={selectedResource === "iron" ? selectedTabStyle : unselectedTabStyle}
              onClick={() => setSelectedResource("iron")}
          >
            Iron
          </button>
          <button
              className={selectedResource === "yours" ? selectedTabStyle : unselectedTabStyle}
              onClick={() => setSelectedResource("yours")}
          >
            Mine
          </button>
        </div>



        <HighScoreNavDropDown options={options} onOptionChange={(value) => setSelectedResource(value)} showOption={selectedResource}/>

      </div>
      }
      <PaginationControls totalPageCount={totalPageCount} setPageNumber={setPageNumber} pageNumber={pageNumber}/>
      {allListings.length > 0 &&
      <div >
        <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 gap-2">
          {allListings.map((listing, index) => (
              <div key={listing.id} className={`p-4 relative rounded-lg grid place-items-center shadow-md m-2 ${index % 2 === 0 ? 'bg-mainLightBrown3': 'bg-mainLightBrown1'}`}>
                <div className="absolute top-0 right-2">
                  <FontAwesomeIcon icon={faThumbtack} className=""  />
                </div>
                <h3 className="text-base font-semibold">{listing.cityName} ( <Link to={`/profile/${listing.username}`}>
                  {listing.username}
                </Link>)</h3>
                <p className="text-sm">Selling</p>
                <div className="font-semibold">{listing.resAmountSelling} {listing.resTypeSelling === "wheat" ? <WheatIconResponsive/>:listing.resTypeSelling === "wood" ? <WoodIconReponsive/>: listing.resTypeSelling === "stone" ? <StoneIconResponsive/>: <IronIconResponsive/>}</div>
                <p className="text-sm my-1">For </p>
                <div className="font-semibold">{listing.resAmountNeeded} {listing.resTypeNeeded === "wheat" ? <WheatIconResponsive/>:listing.resTypeNeeded === "wood" ? <WoodIconReponsive/>: listing.resTypeNeeded === "stone" ? <StoneIconResponsive/>: <IronIconResponsive/>}</div>
                <p className="text-xs text-gray-600">Travel Time: {listing.travelTime}</p>
                <p className="text-xs text-gray-600 mb-3">Traders Needed: {listing.tradersNeeded}</p>
                {inCityId !== listing.cityId ? (
                    canTrade(listing) === "" ? (
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
                            onClick={() => purchaseListing(listing.id)}
                        >
                          Trade
                        </button>
                    ) : (
                        <span className="text-red-500 text-sm">{canTrade(listing)}</span>
                    )
                ) : (
                    <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
                        onClick={() => removeListing(listing.id)}
                    >
                      Remove
                    </button>
                )}
              </div>
          ))}

        </div>
        <div>
          <div>
            Traders available: {tradersCount.tradersInCity}/
            {tradersCount.maxTraders}
          </div>
          <div>
            Can carry:{" "}
            {tradersCount.tradersInCity * RULE_CONFIG.merchantCarryAmount}{" "}
            resources
          </div>
        </div>

        {statusText && <div className="text-red-500">{statusText}</div>}

      </div>}
    </div>
  }

  return (
      <div>
        {!props.isOuterDropDownOpen &&
        <div className="flex justify-center">



          <div className="flex md:flex hidden text-lg sm:text:lg md:text-sm lg:text-base xl:text-lg text-white pb-4">
            <button
                className={selectedResource === "all" ? selectedTabStyle : unselectedTabStyle}
                onClick={() => setSelectedResource("all")}
            >
              All
            </button>
            <button
                className={selectedResource === "wheat" ? selectedTabStyle : unselectedTabStyle}
                onClick={() => setSelectedResource("wheat")}
            >
              Wheat
            </button>
            <button
                className={selectedResource === "wood" ? selectedTabStyle : unselectedTabStyle}
                onClick={() => setSelectedResource("wood")}
            >
              Wood
            </button>
            <button
                className={selectedResource === "stone" ? selectedTabStyle : unselectedTabStyle}
                onClick={() => setSelectedResource("stone")}
            >
              Stone
            </button>
            <button
                className={selectedResource === "iron" ? selectedTabStyle : unselectedTabStyle}
                onClick={() => setSelectedResource("iron")}
            >
              Iron
            </button>
            <button
                className={selectedResource === "yours" ? selectedTabStyle : unselectedTabStyle}
                onClick={() => setSelectedResource("yours")}
            >
              Mine
            </button>
          </div>



            <HighScoreNavDropDown options={options} onOptionChange={(value) => setSelectedResource(value)} showOption={selectedResource}/>

        </div>
          }
        <PaginationControls totalPageCount={totalPageCount} setPageNumber={setPageNumber} pageNumber={pageNumber}/>

        <div className="max-h-[47vh] overflow-y-auto scrollbar scrollbar-thumb-mainDarkBrown scrollbar-thick">

          {allListings.length > 0 && (
              <table className="w-full divide-y divide-gray-200  ">
                <thead className="bg-mainLightBrown3 ">
                <tr>
                  <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                    Seller
                  </th>
                  <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                    City
                  </th>
                  <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                    Selling
                  </th>
                  <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                    Buying
                  </th>
                  <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-white uppercase">
                    Travel Time
                  </th>
                  <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                    Traders needed
                  </th>
                  <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-white uppercase">
                    Trade
                  </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 ">
                {allListings.map((listing, index) => (
                    <tr key={listing.id} className={`${index % 2 === 0 ? 'bg-mainLightBrown3': 'bg-mainLightBrown1'}`}>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-800">
                        <Link to={`/profile/${listing.username}`}>
                          {listing.username}
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-800">
                        {listing.cityName}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-800">
                        {listing.resAmountSelling} {listing.resTypeSelling}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-800">
                        {listing.resAmountNeeded} {listing.resTypeNeeded}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-800">
                        {listing.travelTime}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-800">
                        {listing.tradersNeeded}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-800">
                        {inCityId !== listing.cityId ? (
                            canTrade(listing) === "" ? (
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => purchaseListing(listing.id)}
                                >
                                  Trade
                                </button>
                            ) : (
                                <span className="text-red-500">{canTrade(listing)}</span>
                            )
                        ) : (
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => removeListing(listing.id)}
                            >
                              Remove
                            </button>
                        )}
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
          )}

          <div>
            <div>
              Traders available: {tradersCount.tradersInCity}/
              {tradersCount.maxTraders}
            </div>
            <div>
              Can carry:{" "}
              {tradersCount.tradersInCity * RULE_CONFIG.merchantCarryAmount}{" "}
              resources
            </div>
          </div>

          {statusText && <div className="text-red-500">{statusText}</div>}
        </div>
      </div>

  );
}
