import {useContext} from "react";
import { Link } from "react-router-dom";
import {UserContext} from "../../index";
//import BuyGoldImage from "../../images/navigations/BuyGoldNav.png"
import SettingsIcon from "../../images/navigations/CogNav.png"
import ProfileIcon from "../../images/navigations/ProfileNav.png"
import TroopActivityIcon from "../../images/navigations/TroopActivityNav.png"
import AchivementIcon from "../../images/navigations/AchivementsNav.png"
import GuideIcon from "../../images/navigations/GuideNav.png"
import BugIcon from "../../images/navigations/ReportABug.png"

export function LeftMenu() {
    const { hasFinnishedTutorial } = useContext(UserContext);


    return (
        <div className="bg-[#a67c52]  p-4 rounded-lg shadow-2xl border border-[#8c6239] text-white font-serif sm:text-lg md:text-sm lg:text-lg">
            <nav>
                <ul className=" grid grid-cols-3 gap-1 ">
                    {/*<li className="hover:bg-[#8c6239] transition duration-300 ease-in-out cursor-pointer">
                        <a href="#" className="flex items-center p-2 text-gray-800 ">
                            <img src={BuyGoldImage} className="mr-2 w-8" alt="Buy gold icon"/>
                             Buy gold
                        </a>
                    </li>*/}
                    <li className="hover:bg-[#8c6239] transition duration-300 ease-in-out cursor-pointer">
                        <Link to="/settings" className="flex items-center p-2 text-gray-800">
                            <img src={SettingsIcon} className="mr-2 w-8" alt="Settings icon"/>
                            <h4 className="hidden ">Settings</h4>
                        </Link>
                    </li>

                    <li className="hover:bg-[#8c6239] transition duration-300 ease-in-out cursor-pointer">
                        <Link to="/profile/me" className="flex items-center p-2 text-gray-800">
                            <img src={ProfileIcon} className="mr-2 w-8" alt="Profile icon"/>
                            <h4 className="hidden">Profile</h4>
                        </Link>
                    </li>

                        <li className="hover:bg-[#8c6239] transition duration-300 ease-in-out cursor-pointer">
                            <Link to="/achievements" className="flex items-center p-2 text-gray-800">
                                <img src={AchivementIcon} className="mr-2 w-8" alt="Achievement icon"/>
                                <h4 className="hidden ">Achievements</h4>
                            </Link>
                        </li>

                    <li className="hover:bg-[#8c6239] transition duration-300 ease-in-out cursor-pointer">
                        <Link to="/cityActivity" className="flex items-center p-2 text-gray-800">
                            <img src={TroopActivityIcon} className="mr-2 w-8" alt="Troop activity icon"/>
                            <h4 className="hidden ">Activity</h4>
                        </Link>
                    </li>
                    <li className="hover:bg-[#8c6239] transition duration-300 ease-in-out cursor-pointer">
                        <Link to="/guide" className="flex items-center p-2 text-gray-800">
                            <img src={GuideIcon} className="mr-2 w-8" alt="Troop activity icon"/>
                            <h4 className="hidden">Guide</h4>
                        </Link>
                    </li>
                    <li className="hover:bg-[#8c6239] transition duration-300 ease-in-out cursor-pointer">
                        <Link to="/bugReport" className="flex items-center p-2 text-gray-800 ">
                            <img src={BugIcon} className="mr-2 w-8" alt="Buy gold icon"/>
                            <h4 className="hidden ">Report a bug</h4>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );



}
