import { useEffect, useState } from "react";
import { HighScoreTopPlayersList } from "./HighScoreTopPlayersList";

export function AllTopListsPlayers(props) {
  const [allToplists, setAllTopLists] = useState([]);
  async function getData() {
    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/highscores/topLists/${props.type}`, {
      credentials: "include"
    });
    const data = await res.json();
    setAllTopLists(data)
  }

  useEffect(() => {

    getData();
  }, [props.type]);

  if(allToplists.length === 0){
    return <div>Loading..</div>
  }

  return (
    <div className="flex flex-wrap justify-between">
      {allToplists.map((list, index) => (
        <div key={index} className="w-full md:w-1/2 p-2">
          <HighScoreTopPlayersList title={list.title} items={list.entries} playerRank={list.loggedInRank} playerName={list.loggedInUsername} playerScore={list.loggedInAmount} />
        </div>
      ))}
    </div>
  );
}
