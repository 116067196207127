import { useNavigate } from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {NotificationContext, UserContext} from "../../index.jsx";
import {ResourceFieldNavigationImage} from "../../images/ResourceFieldNavigationImage";
import {CityCenterNavigationImage} from "../../images/CityCenterNavigationImage";
import {MapNavigationImage} from "../../images/MapNavigationImage";
import {MessagesNavigationImage} from "../../images/MessagesNavigationImage";
import {HighscoreNavigationImage} from "../../images/HighscoreNavigationImage";
import {NotificationNoNewImage} from "../../images/NotificationNoNewImage";
import {NotificationsHasUnreadNavigationImage} from "../../images/NotificationsHasUnreadNavigationImage";

export function MyNavBar() {
  const { numberOfUnreadMessages, setNumberOfUnreadMessages } = useContext(UserContext);
  const { hasNewNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const [newMessageCount, setNewMessageCount] = useState(0);

  async function getNumberOfNewMessages(){

    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/messages/count/unread`, {
      credentials: "include"
    })

    if(res.status === 200){

      const data = await res.json()
      setNewMessageCount(data)
      setNumberOfUnreadMessages(data)
    }

    if(res.status === 404){
      setNewMessageCount(0)
      setNumberOfUnreadMessages(0)
    }


  }
  useEffect(() => {
    if(numberOfUnreadMessages){
      setNewMessageCount(numberOfUnreadMessages)
    }else{

      getNumberOfNewMessages()
    }

  }, [])
  useEffect(() => {
    if(numberOfUnreadMessages !== undefined){
      setNewMessageCount(numberOfUnreadMessages)
    }

  }, [numberOfUnreadMessages])

  return (
    <div className="relative top-[-3px]">
      <div className="text-xs sm:text-sm md:text-base flex justify-center space-x-4 min-w-fit  w-full pl-2 pr-2">
        <div className=" hover:scale-[106%]" onClick={() => navigate("/resources")}>
          <ResourceFieldNavigationImage/>
        </div>
        <div className=" hover:scale-[106%]" onClick={() => navigate("/town")}>
          <CityCenterNavigationImage/>
        </div>
        <div className=" hover:scale-[106%]" onClick={() => navigate("/map")}>
          <MapNavigationImage/>
        </div>
        <div className=" hover:scale-[106%]" onClick={() => navigate("/notifications")}>

          {hasNewNotification > 0 ? (
              <NotificationsHasUnreadNavigationImage notificationCount={hasNewNotification}/>
          ) : (
              <NotificationNoNewImage />
          )}

        </div>
        <div onClick={() => navigate("/messages/all")} className=" hover:scale-[106%] relative">
          <MessagesNavigationImage/>
          {newMessageCount > 0 &&  <h4 className="absolute top-0 right-0 bg-red-500 rounded-full p-0.5">
            {`${newMessageCount}`}
          </h4>}

        </div>
        <div className=" hover:scale-[106%]" onClick={() => navigate("/highscores")}>
          <HighscoreNavigationImage/>
        </div>
      </div>
    </div>
  );
}
