import {useContext, useState} from "react";
import {UserContext} from "../index";

export function DiscordSettings(){
    const { userSettings } = useContext(UserContext);
    const [settingsObj, setSettingsObj] = useState(userSettings);
    const [status, setStatus] = useState();

    const handleSettingsChange = (event) => {
        const { name, type, checked, value } = event.target;
        const newValue = type === 'checkbox' ? checked : value;

        setSettingsObj((prevSettings) => ({
            ...prevSettings,
            [name]: newValue,
        }));
    };

    const handleDiscordSubmit = async (event) => {
        setStatus(undefined);
        event.preventDefault();
        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/settings`, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(settingsObj)
            ,credentials: "include"
        });
        if(res.ok){
            setStatus("Settings changed!")
        }else{
            setStatus("Failed to change settings!")
        }
    }

    return (
        <div className="max-w-md mx-auto shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold mb-4 text-center">Discord Settings</h2>
            <form onSubmit={handleDiscordSubmit} className="space-y-6">
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        name="notify_attack_discord"
                        checked={settingsObj.notify_attack_discord}
                        onChange={handleSettingsChange}
                        id="notify_attack_discord"
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label htmlFor="notify_attack_discord" className="ml-3 text-gray-700">Notify attacks via Discord</label>
                </div>
                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Change settings
                </button>
                {status && <p className="mt-4 text-center text-red-600">{status}</p>}
            </form>
        </div>
    );
}