import { motion } from "framer-motion";
import {useNavigate} from "react-router-dom";

export function LootChestPrizeModal({ prize, closeModal }) {

    const navigate = useNavigate();

    return (
        <motion.div
            className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center text-white"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="relative bg-mainLightBrown4 p-5 rounded-lg shadow-lg text-center">
                <button
                    className="absolute top-2 right-2 text-xs text-white hover:text-gray-300 focus:outline-none"
                    onClick={() => navigate("/town")}
                >
                    X
                </button>
                <h2 className=" mt-4 text-2xl font-bold mb-4">Unclaimed Prize</h2>
                <p className="text-xl">{prize.description}</p>
                <button className="mt-4 px-4 py-2 bg-mainDarkBrown3 text-white rounded" onClick={closeModal}>Claim</button>
            </div>
        </motion.div>
    )
}
