import { useState } from "react";

export function EditAllianceDescriptionCard(props) {
    const [description, setDescription] = useState("");

    const handleDescriptionChange = (event) => {
        if (event.target.value.length <= 500) {
            setDescription(event.target.value);
        }
    };

    async function saveDescription(){

         await fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/edit/description`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                description: description,
                allianceId: props.allianceId,

            }),
            credentials: "include"
        });
    }

    return (
        <div className="flex justify-center mt-4">

            <div>


            <textarea
                className=" p-3 bg-mainLightBrown3 text-white border border-gray-600 rounded-lg resize-none"
                rows="6"
                maxLength="500"
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Enter alliance description (max 500 characters)"
            />
            <div className="text-sm text-right text-gray-400">
                {description.length}/500
            </div>

            <div className="flex justify-center">
                <button
                    className="mt-2 px-4 py-2 bg-mainDarkBrown text-white font-semibold rounded-lg"
                    onClick={saveDescription}
                >
                    Save Description
                </button>
            </div>
            </div>
        </div>
    );
}
