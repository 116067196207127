

import {CardBackside} from "./CardBackside";
import {CardValue} from "./CardValues";
import {CardSuit} from "./CardSuit";





export function CardBG(props){




if(props.suit !== "Unknown"){



    return <svg width="100%" height="100%" viewBox="0 0 822 1122" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="818" height="1118" fill="#D9D9D9" stroke="black" stroke-width="4"/>
        {/*Top left corner suit*/}
        <g transform="scale(0.2) translate(200, 1000)">
            <CardSuit suit={props.suit} />

        </g>
        <g transform="scale(1.2) translate(50, 30)">
            <CardValue suit={props.suit} value={props.value} />

        </g>

       {/* Middle Suit*/}
        <g transform="scale(0.6) translate(411, 556)">
            <CardSuit suit={props.suit} />
        </g>

        {/*Bottom Right Corner Suit*/}
        <g transform="scale(0.2) translate(3900, 4500) rotate(180)">
            <CardSuit suit={props.suit} />
        </g>


        <g transform="scale(1.2) translate(630, 880) rotate(180)">
            <CardValue suit={props.suit} value={props.value} />
        </g>




    </svg>
}else{

    return <CardBackside/>
}



}