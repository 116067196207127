import { useState } from 'react';
import {useNavigate} from "react-router-dom";
import {ConfirmModal} from "../modals/ConfirmModal";



export function DisbandAllianceCard(props) {
    const [showModal, setShowModal] = useState(false);
   const navigate = useNavigate();


    const handleDisbandAlliance = () => {

        fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/delete/${props.allianceId}`, {
            credentials: "include"
        })

        navigate("/town")
        setShowModal(false);
    };

    return (
        <div className="mt-4">
            <div className="flex justify-center">
                <button
                    className="px-4 py-2 bg-red-600 text-white rounded-lg"
                    onClick={() => setShowModal(true)}
                >
                    Disband Alliance
                </button>
            </div>

            {showModal && (
                <ConfirmModal
                    onConfirm={handleDisbandAlliance}
                    onCancel={() => setShowModal(false)}
                />
            )}
        </div>
    );
}
