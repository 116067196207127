import {useEffect, useState} from "react";

export function AllianceInfoCard(props){
    const [detail, setDetail] = useState();

    useEffect(() => {
        setDetail(props.allianceDetails.details[0])
    }, [detail])

    if(!detail){
        return <div>
            Loading...
        </div>
    }

    return <div className="p-2 rounded-lg text-black border border-2 border-black">

        <div className="text-2xl mb-1 text-center font-semibold">{detail.alliance_name}</div>



        <div className="grid grid-cols-1 md:grid-cols-4">
            {/*ALLY DETAILS*/}
            <div className="col-span-1 text-center md:text-left">
                <div className="text-lg font-semibold text-center">Details</div>
                <div>
                    <div>
                        Tag: #{detail.alliance_tag}
                    </div>
                    <div>Rank: {detail.rank}</div>
                    <div >Members: {detail.alliance_members}/{detail.alliance_max_members}</div>
                    <div className="mb-3">Owner: {detail.ownerName}</div>
                </div>
            </div>
            {/*ALLY DESCRIPTION*/}
            <div className="mb-3 col-span-1 md:col-span-3">
                <div className="text-lg font-semibold text-center">Description</div>
                <div className="p-4 text-center rounded mb-3">
                    {detail.description || 'No description'}
                </div>
            </div>
        </div>



        {/*MEMBER LIST*/}
        <div className="text-xs sm:text-sm md:text-base">
            <div className="text-xl mb-1 mt-2 text-center">Alliance Members</div>
            <div className="border border-gray-600 divide-y divide-gray-600">
                <div className="grid grid-cols-4 p-2 text-center bg-mainLightBrown1 font-semibold">
                    <span>Name:</span>
                    <span>Pop:</span>
                    <span>Role:</span>
                    <span>Cities:</span>
                </div>
                {props.allianceDetails.users.map((user, index) => (
                    <div key={index} className={`grid grid-cols-4 p-2 text-center ${index % 2 === 0 ? 'bg-mainLightBrown3': 'bg-mainLightBrown1'} text-xxs sm:text-xs md:text-sm`}>
                        <span>{user.username}</span>
                        <span>{user.total_population}</span>
                        <span>{user.role}</span>
                        <div>{user.city_count}</div>
                    </div>
                ))}
            </div>
        </div>
    </div>
}