import React from 'react';
import { motion } from 'framer-motion';


export function PaginationControls({ pageNumber, setPageNumber, totalPageCount }) {
    const variants = {
        hidden: { opacity: 0, scale: 0.7 },
        visible: { opacity: 1, scale: 1 }
    };

    return (
        <motion.div
            className="grid grid-cols-3 gap-4 items-center text-center text-sm font-bold text-gray-700 py-2 rounded-lg shadow-lg"
            initial="hidden"
            animate="visible"
            variants={variants}
        >
            <motion.div
                variants={variants}
                initial={pageNumber === 1 ? 'hidden' : 'visible'}
                animate={pageNumber === 1 ? 'hidden' : 'visible'}
            >
                {pageNumber > 1 && (
                    <button
                        className="bg-mainDarkBrown hover:bg-green-700 text-white font-bold py-2 px-2 rounded"
                        onClick={() => setPageNumber(pageNumber - 1)}
                    >
                        ←
                    </button>
                )}
            </motion.div>

            <div>Page: {pageNumber}/{totalPageCount}</div>

            <motion.div
                variants={variants}
                initial={pageNumber === totalPageCount ? 'hidden' : 'visible'}
                animate={pageNumber === totalPageCount ? 'hidden' : 'visible'}
            >
                {pageNumber < totalPageCount && (
                    <button
                        className="bg-mainDarkBrown hover:bg-green-700 text-white font-bold py-2 px-2 rounded"
                        onClick={() => setPageNumber(pageNumber + 1)}
                    >
                        →
                    </button>
                )}
            </motion.div>
        </motion.div>
    );
}
