import {CreateAllianceCard} from "../cards/CreateAllianceCard";
import {AllianceBaseDetailsCard} from "../cards/AllianceBaseDetailsCard";
import {AllianceInvitationList} from "../lists/AllianceInvitationList";
import {useEffect} from "react";

export function EmbassyAlliance({canMake, allianceData, personalAllianceInvites}){

    return <div className="container mx-auto p-4">
        {/*does not have an alliance yet, but embassy high enough to make one*/}
        {canMake && !allianceData ? (
            <CreateAllianceCard />
        ) : (
            !allianceData  &&
            <div className="text-center text-sm sm:text-base md:text-lg xl:text-xl font-semibold text-red-500">
                You need embassy lvl 5 to create an alliance
            </div>
        )}

        {/*IS PART OF ALLIANCE*/}
        {allianceData && allianceData.details ? (
            <div>
                <AllianceBaseDetailsCard allianceInfo={allianceData.details[0]} />
            </div>
        ):<div className="flex justify-center">
            <div className="text-center bg-mainLightBrown2 mb-2 w-[50%] min-w-[180px] p-4 rounded">
                <h4 className="font-semibold">You are not part of an alliance</h4>
            </div>
        </div>}


        {/*INVITE LIST*/}
        {!allianceData && (personalAllianceInvites.length > 0 ? <div>
            <AllianceInvitationList list={personalAllianceInvites}/>
        </div>:<div className="flex justify-center">
            <div className="text-center bg-mainLightBrown3 mb-2 w-[50%] min-w-[180px] p-4 rounded">
                <h4 className="font-semibold text-white">You have no alliance invitations!</h4>
            </div>
        </div>)}
    </div>
}