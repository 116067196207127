import {useAnimation, motion} from "framer-motion";
import {useEffect} from "react";
import {CardBG} from "./playingCards/CardBG";

export function PlayingCard({ cardSuit, cardValue, index, instant, baseDelay, gameOver, hidden }) {
    const controls = useAnimation();

    useEffect(() => {
        // If game is over, do not animate the cards, just show them.
        if(gameOver){
            controls.start({
                x: 0,
                y: 0,
                rotateY: 0,
                opacity: 1,
            });
        }else{
            // if cardsuit is hearths, show hearths etc
            controls.start({
                x: 0,
                y: 0,
                rotateY: 0,
                opacity: hidden ? 0 : 1, // Hide the card if 'hidden' prop is true
                transition: { delay: instant ? 0 : baseDelay + index * 0.5, duration: 1 }
            });
        }
    }, [gameOver, hidden]); // Add 'hidden' as a dependency to the useEffect hook

    return (
        <motion.div
            className="w-[4vw] min-w-[60px] max-w-[70px] z-50 absolute bg-blue-400"
            initial={gameOver || instant ? {} : { x: -200, y: 0, rotateY: 180 }}
            animate={controls}
            style={{ left: `${index * 15}px`, zIndex: index }}
        >
            <CardBG suit={cardSuit} value={cardValue} />
        </motion.div>
    );
}
