import {useEffect, useState} from "react";
import { KickPlayerCard } from "./KickPlayerCard";
import { ChangeAllianceRoleCard } from "./ChangeAllianceRoleCard";
import {EditAllianceDescriptionCard} from "./EditAllianceDescriptionCard";
import {DisbandAllianceCard} from "./DisbandAllianceCard";
import {LeaveAllianceCard} from "./LeaveAllianceCard";
import {HighScoreNavDropDown} from "../lists/HighScoreNavDropDown";





export function AllianceSettingsCard(props) {
    const [activeSubTab, setActiveSubTab] = useState("leave");

    function changeView(option) {
        setActiveSubTab(option);
    }

    const options = [
        { value: "editDescription", text: "Edit Description" },
        { value: "kickPlayer", text: "Kick Player" },
        { value: "changeRole", text: "Change Role" },
        { value: "disband", text: "Disband Alliance" },
        { value: "leave", text: "Leave" },
    ];


    return (
        <div className="p-2 rounded-lg text-black border border-2 border-black mb-2 min-w-[250px] overflow-auto">

            {!props.isOuterDropDownOpen &&
            <div className="flex justify-center">
            <div>

            <div className="flex md:flex hidden text-lg sm:text:lg md:text-sm lg:text-base xl:text-lg text-white pb-4">
                {props.role.caneditdescription && <button
                    className={`mr-2 px-4 py-2 bg-mainDarkBrown ${activeSubTab === "editDescription" ? "border-b-2 bg-cyan-700" : ""}`}
                    onClick={() => changeView("editDescription")}
                >
                    Edit Description
                </button>}

                {props.role.cankick && <button
                    className={` mr-2 px-4 py-2 bg-mainDarkBrown ${activeSubTab === "kickPlayer" ? "border-b-2 bg-cyan-700" : ""}`}
                    onClick={() => changeView("kickPlayer")}
                >
                    Kick Player
                </button>}

                {props.role.caneditpermissions &&  <button
                    className={`mr-2 px-4 py-2 bg-mainDarkBrown ${activeSubTab === "changeRole" ? "border-b-2 bg-cyan-700" : ""}`}
                    onClick={() => changeView("changeRole")}
                >
                    Change Role
                </button>}

                {props.role.candoeverything && <button
                    className={`mr-2 px-4 py-2 bg-mainDarkBrown ${activeSubTab === "disband" ? "border-b-2 bg-cyan-700" : ""}`}
                    onClick={() => changeView("disband")}
                >
                    Disband Alliance
                </button>}


                <button
                    className={`px-4 py-2 bg-mainDarkBrown ${activeSubTab === "leave" ? "border-b-2 bg-cyan-700" : ""}`}
                    onClick={() => changeView("leave")}
                >
                    Leave
                </button>
            </div>
                <HighScoreNavDropDown options={options} onOptionChange={(value) => changeView(value)} showOption={activeSubTab}/>

            </div>
            </div>
            }
            <div className="min-h-[250px]">
                <div>
                    {activeSubTab === "editDescription" && <EditAllianceDescriptionCard allianceId={props.role.alliance_id} />}
                    {activeSubTab === "kickPlayer" && <KickPlayerCard allianceId={props.role.alliance_id} members={props.members} />}
                    {activeSubTab === "changeRole" && <ChangeAllianceRoleCard role={props.role} members={props.members} />}
                    {activeSubTab === "disband" && <DisbandAllianceCard allianceId={props.role.alliance_id} />}
                    {activeSubTab === "leave" && <LeaveAllianceCard allianceId={props.role.alliance_id} isOwner={props.role.candoeverything}/>}
                </div>
            </div>


        </div>
    );
}
