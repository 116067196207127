import {Link} from "react-router-dom";

import {BuildingInfoImage} from "../../images/BuildingInfoImage";


export function BuildingInCityCard({upgradeBuilding, building, buildingStatus, buildingLocation}){

    if(building.building_name === "empty"){

        if(buildingStatus){
            return (
                <div className="bg-gradient-to-br from-cityCard2 to-cityCard1 rounded-lg shadow-xl p-2 transition-all duration-300 ease-in-out cursor-pointer h-full flex items-center justify-center hover:scale-105 hover:shadow-2xl">
                    <div className="flex items-center justify-center rounded-lg shadow-inner w-full h-full">
            <span className="text-xl font-bold text-mainDarkBrown">
                {buildingStatus}
            </span>
                    </div>
                </div>
            );

        }
        return (
            <div className="min-h-[150px] bg-gradient-to-br from-cityCard2 to-cityCard1 rounded-lg shadow-xl p-2 transition-colors duration-500 ease-in-out cursor-pointer h-full flex items-center justify-center   hover:scale-[103%]">
                <Link
                    to={`/buildings/${buildingLocation}`}
                    className="text-black flex items-center justify-center rounded-lg shadow-inner w-full h-full"
                >
                    Empty
                </Link>
            </div>
        )
    }


    return <div onClick={() => upgradeBuilding(building)} className="bg-gradient-to-br from-cityCard2 to-cityCard1 rounded-lg shadow-lg p-2 transition-colors duration-500 ease-in-out cursor-pointer aspect-[1/1] w-full hover:scale-[103%]">
        <div>
            <BuildingInfoImage type={building.building_name}/>

        {buildingStatus ? (
            <div className="mt-4 bg-cityCard3 rounded-full flex justify-center items-center">{buildingStatus}</div>
        ) : (
            <div className="mt-4 bg-cityCard3 rounded-full flex justify-center items-center">
                {building.building_level}
            </div>
        )}
        { building.building_name === "under_construction" && (
            <div className="mt-4 bg-cityCard3 rounded-full flex justify-center items-center" >Under construction</div>
        ) }

    </div>
    </div>
}