import {useContext, useEffect, useState} from "react";
import { TroopCard } from "../cards/TroopCard";
import { BarracksTroopTimerList } from "../lists/BarracksTroopTimerList";
import {CityConfigContext} from "../../index";
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp,} from "@fortawesome/free-solid-svg-icons";
import {useTroopTimerState} from "../contexts/timerContext";

export function BarracksTroopsList(props) {
  const timersV2 =  useTroopTimerState();


  const { cityProductionStats, cityBonus } = useContext(CityConfigContext);

  const [troopReq, setTroopReq] = useState();

  const [isBarracksVisible, setIsBarracksVisible] = useState(true);

  const toggleBarracksVisibility = () => {
    setIsBarracksVisible(!isBarracksVisible);
  };

  useEffect(() => {
    const fetchTroops = async () => {
      const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/troops/req`, {
        credentials: "include"
      });
      const data = await res.json();
      if(cityProductionStats){
        data.forEach((item) => {


          const bonus = cityBonus.troop_training_speed_bonus  / 100

          item.seconds_to_build = Math.round(item.seconds_to_build / (1 + bonus));

        })
      }

      setTroopReq(data);
    };
    fetchTroops();
  }, []);



  if (!troopReq) {
    return <div className="text-lg mb-4">Loading...</div>;
  }

  return (
    <div className="m-0 md:m-4 lg:m-8">
      <div className="">
        <div className="bg-mainLightBrown1 pl-2 rounded grid grid-cols-1  md:gap-4 lg:grid-cols-1 2xl:grid-cols-2">
          <div className="max-h-[120vh] md:max-h-[36vh] overflow-y-scroll scrollbar pr-2 scrollbar-thumb-blue-500 scrollbar-thick  ">


            <TroopCard
              troop={troopReq[0]}
              troopNumber={1}
              troopId={troopReq[0].id}
              ad="15"
              dp="5"
            />

          {props.level >= 3 && (
              <TroopCard
                troop={troopReq[1]}
                troopNumber={2}
                troopId={troopReq[1].id}
                ad="10"
                dp="15"
              />
          ) }
          {props.level >= 6 && (
              <TroopCard
                troop={troopReq[2]}
                troopNumber={3}
                troopId={troopReq[2].id}
                ad="30"
                dp="20"
              />
          ) }
        </div>
          {timersV2.length > 0 && <div className="flex justify-center mx-auto place-items-center bg-mainDarkBrown rounded text-white items-center mt-2 w-[50%] block xl:hidden" onClick={toggleBarracksVisibility}>
            <FontAwesomeIcon icon={isBarracksVisible? faArrowUp : faArrowDown} className=""  />
            <p className="pl-2 font-semibold">{isBarracksVisible? 'Hide timers' : 'Show timers'}</p>
          </div>}

          <div className={`xl:block ${isBarracksVisible ? 'block' : 'hidden'}`}>
            <BarracksTroopTimerList  />
          </div>
        </div>


      </div>
    </div>
  );
}
