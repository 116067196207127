import {useEffect, useState} from "react";

export function SlotWinHistoryCard({lootCollected}){

    const [history, setHistory] = useState([])

    async function getHistory(){

        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/casino/slots/history`)

        if(res.status === 200){
            const data = await res.json();

            setHistory(data[0])
        }
    }
    useEffect(() => {


            getHistory()

    }, [lootCollected])



    if(!history){
        return
    }

    return <div>
        <h2>Slot win history</h2>
        <div className="grid grid-cols-2 md:grid-cols-4">
            <h4 className="mr-4">🍉x3: {history.watermelon_x_3}</h4>
            <h4 className="mr-0 md:mr-4">🥑x3: {history.avocado_x_3}</h4>
            <h4 className="mr-4">🍒x3: {history.cherry_x_3}</h4>
            <h4 className="mr-0 md:mr-4">🍇x3: {history.grapes_x_3}</h4>
        </div>
    </div>
}