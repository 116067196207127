import { useState } from "react";

import {HelperFunctions} from "../../HelperFunctions";
import {useReturningAttackTimer} from "../contexts/timerContext";
import {Link} from "react-router-dom";
import returningAttackIcon from "../../images/attacks/returningAttackIcon.png";

export function RetrievalList() {
  const retrievalTimers  = useReturningAttackTimer();
  const [isOpen, setIsOpen] = useState(true);

  const toggleList = () => setIsOpen(!isOpen);

  return (
      <div className="rounded-md overflow-hidden bg-mainLightRed shadow-md">
        <div className="bg-mainReturn py-2 px-4 flex justify-between items-center">
          <Link to={"/cityActivity"} className="text-sm font-semibold text-white">
            {retrievalTimers.length} {retrievalTimers.length === 1 ? 'Retrieval' : 'Retrievals'}
          </Link>

          <svg
              onClick={toggleList}
              xmlns="http://www.w3.org/2000/svg"
              className={`h-5 w-5 text-white cursor-pointer transform transition duration-300 ${
                  isOpen ? "rotate-0" : "rotate-180"
              }`}
              viewBox="0 0 20 20"
              fill="currentColor"
          >
            <path
                fillRule="evenodd"
                d="M11.293 9.293a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L10 11.414l2.293 2.293a1 1 0 001.414-1.414l-3-3z"
                clipRule="evenodd"
            />
          </svg>
        </div>
        {isOpen && (
            <ul className="divide-x divide-y divide-gray-300 grid grid-cols-1 grid-cols-2 md:grid-cols-1 mxl:grid-cols-2">
              {retrievalTimers.slice(0, 2).map((retrievalItem) => (
                  <li
                      key={retrievalItem.retrievalId}
                      className="py-2 px-4 text-gray-800 flex flex-col justify-between items-center "
                  >
                      <div className="flex flex-row text-xs justify-center">
                          <img src={returningAttackIcon} alt="Returning Attack icon"/>
                          <Link to={`/profile/${retrievalItem.fromUsername}`}>{retrievalItem.fromUsername}</Link>

              </div>
                      <div className="text-mainDefend font-semibold text-xs">
                          {HelperFunctions.secondsToTimestamp(retrievalItem.timeLeft)}
                      </div>
                  </li>
              ))}
                {retrievalTimers.length > 2 && (
                    <li className="  text-gray-800 flex justify-center col-span-2 md:col-span-1 mxl:col-span-2">
                        <Link
                            to="/cityActivity"
                            className="text-blue-600"
                        >
                            See all
                        </Link>
                    </li>
                )}
            </ul>


        )}


      </div>
  );
}
