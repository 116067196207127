


import {useContext, useEffect, useState} from "react";
import {PlayingCard} from "./PlayingCard";
import {CasinoContext} from "../../index";
import {HelperFunctions} from "../../HelperFunctions";
import {GameOverModal} from "../modals/GameOverModal";
import {CasinoCoinResponsive} from "../../images/CasinoCoinResponsive";

export function BlackjackGameCard(props){
    const { blackJackDetails, currentCasinoCoins, maxCasinoCoins} = useContext(CasinoContext);
    const [playerTotalSum, setPlayerTotalSum] = useState(0);
    const [dealerTotalSum, setDealerTotalSum] = useState(0);
    const [isLoading, setIsLoading] = useState(true);



    const [dealerCards, setDealerCards] = useState([]);
    const [playerCards, setPlayerCards] = useState([]);
    const [playerHasAce, setPlayerHasAce] = useState(false);
    const [dealerHadAce, setDealerHasAce] = useState(false);

    const [gameIsOver, setGameIsOver] = useState(false);

    const [winner, setWinner] = useState(null);

    async function stand() {
        setIsLoading(true);

        try {
        // Would be cool if i managed to make the unknown card of the dealer flip around to reveal one of the dealers cards
        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/casino/blackjack/stand`, {credentials: "include"});

        if (res.status === 200) {
            const data = await res.json();
            let dealerHand = [...data];
            let newDealerCards = [...dealerCards];
            newDealerCards.shift();
            setDealerCards(newDealerCards);

            // The first card should be animated to flip over the unknown one, the rest can be added to the end of the dealers hand

            let index = 0; // Added index to keep track of card in the dealerHand array

            const dealCardsInterval = setInterval(() => {
                if (index < dealerHand.length) {
                    let card = dealerHand[index];
                    let cardValue = HelperFunctions.getCardValue(card.value);

                    // Check if this card is an Ace and if it would make dealerTotalSum exceed 21
                    if (cardValue.wasAce && cardValue.value + dealerTotalSum > 21) {
                        cardValue.value = 1;
                    }

                    setDealerTotalSum((oldSum) => cardValue.value + oldSum);
                    setDealerCards((old) => [...old, card]);
                    if (cardValue.wasAce === true) {
                        setDealerHasAce(true);
                    }
                    index++; // Increase the index to move on to the next card
                } else {
                    clearInterval(dealCardsInterval);
                }
            }, 650);
        }

        setTimeout(() => {
            setGameIsOver(true);
        }, 3000);
        } finally {
            setIsLoading(false);
        }
    }


    async function addNewCardToPlayer(){
        setIsLoading(true);


        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/casino/blackjack/draw`, {credentials: "include"})

        if(res.status === 200){
            const data = await res.json()
            setPlayerCards(old => [...old, data])
            let cardValue = HelperFunctions.getCardValue(data.value)


            if (cardValue.wasAce && cardValue.value + playerTotalSum > 21) {
                cardValue.value = 1;
            }

            setPlayerTotalSum(oldSum => cardValue.value + oldSum)
        }

        if(res.status === 201){
            const data = await res.json();

            setPlayerCards(old => [...old, data.card])
            let cardValue = HelperFunctions.getCardValue(data.card.value)


            if (cardValue.wasAce && cardValue.value + playerTotalSum > 21) {
                cardValue.value = 1;
            }

            setPlayerTotalSum(oldSum => cardValue.value + oldSum)
            setTimeout(() => {
                setGameIsOver(true)
            }, 1000)

        }
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)


    }


    async function resumeGame(isOver){
        setIsLoading(true)
        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/casino/blackjack/resume`, {credentials: "include"})

        if(res.status === 200){
            const data = await res.json();
            setPlayerCards([]);
            setDealerCards([]);


            let playerHand = [...data.playerHand];
            let dealerHand = [...data.dealerHand];


            if(isOver) {
                let playerTotal = 0;
                let dealerTotal = 0;
                playerHand.forEach((card) => {
                    let cardValue = HelperFunctions.getCardValue(card.value);


                    if (cardValue.wasAce && cardValue.value + playerTotal > 21) {
                        cardValue.value = 1;
                    }



                    playerTotal += cardValue.value;
                })

                dealerHand.forEach((card) => {
                    let cardValue = HelperFunctions.getCardValue(card.value)

                    if(cardValue.wasAce && cardValue.value + dealerTotal > 21){
                    cardValue.value = 1;
                    }

                    dealerTotal += cardValue.value
                })

                setPlayerTotalSum(playerTotal);
                setDealerTotalSum(dealerTotal)
                setPlayerCards(playerHand);
                setDealerCards(dealerHand);
            } else {
                const dealCardsInterval = setInterval(() => {
                    if (playerHand.length > 0) {
                        let cardValue = HelperFunctions.getCardValue(playerHand[0].value)

                        // Check if this card is an Ace and if it would make playerTotalSum exceed 21
                        if (cardValue.wasAce && cardValue.value + playerTotalSum > 21) {
                            cardValue.value = 1;
                        }

                        setPlayerTotalSum(oldSum => cardValue.value + oldSum)
                        if(cardValue.wasAce === true){
                            setPlayerHasAce(true)
                        }

                        setPlayerCards(old => [...old, playerHand.shift()]);
                    } else if (dealerHand.length > 0) {
                        let cardValue = HelperFunctions.getCardValue(dealerHand[0].value)

                        // Check if this card is an Ace and if it would make dealerTotalSum exceed 21
                        if (cardValue.wasAce && cardValue.value + dealerTotalSum > 21) {
                            cardValue.value = 1;
                        }

                        setDealerTotalSum(oldSum => cardValue.value + oldSum)
                        if(cardValue.wasAce === true){
                            setDealerHasAce(true)
                        }

                        setDealerCards(old => [...old, dealerHand.shift()]);
                    } else {
                        clearInterval(dealCardsInterval);
                    }
                }, 650);
            }
        }


        setTimeout(() => {
            setIsLoading(false)
        }, 4000)
    }




    useEffect(() => {


        let isOver = false;
        if(blackJackDetails){
            if(blackJackDetails.state === "game over"){
                isOver = true
                setGameIsOver(true)
            }
        }



        setTimeout(() => {
            // timeout is to hopefully fix a bug where game sometimes start with only 1 player card rendered
            resumeGame(isOver)
        }, 600)


}, [])

    useEffect(() => {
        if (playerTotalSum > 21 && playerHasAce) {

            setPlayerTotalSum(playerTotalSum - 10);
            setPlayerHasAce(false);
        }

        if(playerTotalSum === 21){
            setGameIsOver(true)
        }

    }, [playerTotalSum, playerHasAce]);

    useEffect(() => {
        if (dealerTotalSum > 21 && dealerHadAce) {

            setDealerTotalSum(dealerTotalSum - 10);
            setDealerHasAce(false);
        }
    }, [dealerTotalSum, dealerHadAce]);





    useEffect(() => {

        let result;
        if (playerTotalSum > 21) {
            result = "dealer";
        } else if (dealerTotalSum > 21) {
            result = "player";
        }else if(playerTotalSum === dealerTotalSum){
            result = "draw"
        } else {
            result = playerTotalSum >= dealerTotalSum ? "player" : "dealer";
        }
        setWinner(result); // Update the winner state
    }, [playerTotalSum, dealerTotalSum, gameIsOver]); // Depend on these states to update winner




    return <div className="flex flex-col justify-between h-full">


        <div className="mb-auto">

            <div className="flex justify-center">
                <div className="bg-mainLightBrown3 rounded-full w-[30%]">
                    <h4 className="text-center">{dealerTotalSum}</h4>
                </div>
            </div>


        </div>
        {/*DEALERS ROW*/}
        <div className="relative top-[-80px]">
            <div >
                {dealerCards.map((card, index) => (
                    <PlayingCard
                        key={index}
                        cardValue={card.value}
                        cardSuit={card.suite}
                        index={index}
                        instant={card.instant}
                        gameOver={gameIsOver}
                        hidden={index === 0 && gameIsOver} // The first card is shown until the game is over
                    />
                ))}
            </div>
        </div>

        {/*MIDDLE ROW*/}
        <div className="flex justify-center">
            <div className="mt-4 mb-4">
              <span className="bg-gray-600 flex justify-center items-center rounded-full w-24 h-12">
                <h4 className="text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">{blackJackDetails.buy_in_amount}</h4>
                 <CasinoCoinResponsive/>
            </span>
            </div>
        </div>





        <div className="relative">

                {playerCards.map((card, index) => (
                    <PlayingCard key={index} cardValue={card.value} cardSuit={card.suite} index={index} instant={card.instant} gameOver={gameIsOver} />
                ))}

        </div>





        {/*CONTROLS ROW*/}
        <div className="mt-auto ">

            <div className="flex justify-center mb-2">
                <div className="bg-mainLightBrown3 rounded-full w-[30%]">
                    <h4 className="text-center">
                        {playerTotalSum}
                    </h4>
                </div>
            </div>

            <div className="flex">
                <button
                    className={`bg-mainYellow hover:bg-mainYellow2 text-white font-semibold py-2 px-4 rounded transition duration-200 ml-2 
    ${isLoading || gameIsOver ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={addNewCardToPlayer}
                    disabled={isLoading || gameIsOver}
                >
                    Hit
                </button>

                <button
                    className={`bg-mainYellow hover:bg-mainYellow2 text-white font-semibold py-2 px-4 rounded transition duration-200 ml-2 
    ${isLoading || gameIsOver ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={stand}
                    disabled={isLoading || gameIsOver}
                >
                    Stand
                </button>

            </div>


            <div className="flex justify-center mt-4">
                <CasinoCoinResponsive/>
                <h4 className="text-2xl">{currentCasinoCoins}/{maxCasinoCoins}</h4>
            </div>
        </div>


        {gameIsOver && playerTotalSum !== 0 && <div className="z-[500]">
            <GameOverModal winner={winner} closeModal={() => setGameIsOver(false)} changeTab={props.changeTab} betAmount={blackJackDetails.buy_in_amount}/>
        </div>}


    </div>


}