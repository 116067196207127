
import {SmallWheatIcon} from "../../images/resources/SmallWheatIcon";
import {SmallWoodIcon} from "../../images/resources/SmallWoodIcon";
import {SmallStoneIcon} from "../../images/resources/SmallStoneIcon";
import {SmallIronIcon} from "../../images/resources/SmallIronIcon";
import {useTimerState} from "../contexts/timerContext";
import {useEffect, useState} from "react";
import {HelperFunctions} from "../../HelperFunctions";

export function UpgradeBuildingReqCard({upgradedBuilding, upgradeBuilding,actualBuildTime, hasEnoughResources,  isMaxLevel, building, bonuses }){

    const allTimers = useTimerState();

    const [buildingTimers, setBuildingTimers] = useState(
        [...allTimers.resourceTimers, ...allTimers.buildingTimers]
    )

    useEffect(() => {
        setBuildingTimers( [...allTimers.resourceTimers, ...allTimers.buildingTimers])
    }, [allTimers])


    return <div className="flex justify-center text-center text-sm md:text-base">
    <div className="">
        {upgradedBuilding && (
            <div className="">
                <h4 className="text-base md:text-xl font-semibold mb-2">Upgrade to {upgradedBuilding.building_level}</h4>
                <div className=" mb-4">
                    
                    <div className="grid grid-cols-4 gap-6 xxs:grid-cols-4  text-xxs xxs:text-xs  font-semibold">
                        <div className="flex">
                            <SmallWheatIcon/>
                            <h4>{upgradedBuilding.wheat_req}</h4>
                        </div>
                        <div className="flex">
                            <SmallWoodIcon/>
                            <h4>{upgradedBuilding.wood_req}</h4>
                        </div>
                        <div className="flex">
                            <SmallStoneIcon/>
                            <h4>{upgradedBuilding.stone_req}</h4>
                        </div>
                        <div className="flex">
                            <SmallIronIcon/>
                            <h4>{upgradedBuilding.iron_req}</h4>
                        </div>


                    </div>
                </div>
                <h4 className="mb-4">
                    {HelperFunctions.secondsToTimestamp(actualBuildTime)}
                    {bonuses && bonuses.building_speed_bonus > 0 &&
                    bonuses.building_speed_bonus && (
                        <div>
                            ({bonuses.building_speed_bonus}% bonus)
                        </div>
                    )}
                </h4>
            </div>
        )}

        {hasEnoughResources && buildingTimers.length < 2 && !isMaxLevel ? (
            <button
                onClick={() =>
                    upgradeBuilding(upgradedBuilding.id, building.location)
                }
                className="bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded transition duration-200"
            >
                Build
            </button>
        ) : buildingTimers.length < 2 && !isMaxLevel ? (
            <h3 className="text-red-500">Not enough resources</h3>
        ) : !isMaxLevel ? (
            <div>Building queue full</div>
        ) : (
            <></>
        )}
    </div>
    </div>
}