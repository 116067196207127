import {BuildingDescAndImage} from "./BuildingDescAndImage";

export function CasinoOverviewCard(){

    return (
        <div className="min-h-[150px] overflow-x-scroll overflow-y-scroll">
            <BuildingDescAndImage type="Casino" isDetails={true}/>

        </div>
    );
}
