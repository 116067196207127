import insta from "../../images/instagram-logo.png";

export function SignedOutFooter(){

    return <footer className=" flex text-center items-center justify-center h-10 bg-mainDarkGreen">
        <a href="https://www.instagram.com/siege.game.dev?igsh=MTI2a2ZuN2w3MGV3dA%3D%3D&utm_source=qr" className="flex" target="_blank">
            <img src={insta} alt="instagram logo" className="w-6 h-6"/>
            <p className="font-semibold text-xl ml-1 inline text-white">@siege.game.dev</p>
        </a>
    </footer>
}