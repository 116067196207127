import {useContext, useState} from "react";
import { CityConfigContext } from "../../index.jsx";
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome";
import { faInfo,  } from "@fortawesome/free-solid-svg-icons";
import {WheatIconResponsive} from "../../images/resources/WheatIconResponsive";
import {WoodIconReponsive} from "../../images/resources/WoodIconReponsive";
import {StoneIconResponsive} from "../../images/resources/StoneIconResponsive";
import {IronIconResponsive} from "../../images/resources/IronIconResponsive";

export function ResourceProduction() {
    const { cityProductionStats, cityBonus } = useContext(CityConfigContext);
    const [showModal, setShowModal] = useState(false);

    if (!cityProductionStats) {
        return <div>Loading...</div>;
    }

    return (
        <div className="bg-mainDarkBrown text-white p-2 min-w-[170px] sm:min-w-[170px] md:min-w-full lg:min-w-auto xl:min-w-auto ">
                <h2 className="font-fantasy text-xs lg:text-sm lg:text-base xxl:text-xl text-white font-semibold mb-2 sm:mb-4 md:mb-4 lg:mb-4 xl:mb-4 text-center">
                    Production
                </h2>
            <div className="p-2 sm:p-3 md:p-3 lg:p-6 bg-mainLightBrown1 text-black overflow-auto max-h-[20vh] min-h-[100px] relative scrollbar scrollbar-thumb-cityCard3 scrollbar-thin ">
                <div className="absolute top-0 right-2 border border-2 rounded-full border-transparent hover:border-cityCard2 p-1 hover:bg-cityCard1 group" onClick={() => setShowModal(!showModal)}>
                    <FontAwesomeIcon icon={faInfo} className="group-hover:scale-110" />
                </div>
                <ul className="space-y-1 sm:space-y-2 md:space-y-2 lg:space-y-2 xl:space-y-2 font-pixel">
                    <li className="flex items-center flex-wrap">
                        <WheatIconResponsive/>
                        <h2 className="pl-2 text-base sm:text-base md:text-sm lg:text-base xl:text-base">
                            {cityProductionStats.wheat_per_min}/min{" "}
                            {cityBonus.wheat_bonus > 0 && (
                                <span className="inline">

                    </span>
                            )}
                        </h2>
                    </li>
                    <li className="flex items-center flex-wrap">
                        <WoodIconReponsive/>
                        <h2 className="pl-2 text-base sm:text-base md:text-sm lg:text-base xl:text-base">
                            {cityProductionStats.wood_per_min}/min{" "}
                            {cityBonus.wood_bonus > 0 && (
                                <span className="inline">

                    </span>
                            )}
                        </h2>
                    </li>
                    <li className="flex items-center flex-wrap">
                        <StoneIconResponsive/>
                        <h2 className="pl-2 text-base sm:text-base md:text-sm lg:text-base xl:text-base">
                            {cityProductionStats.stone_per_min}/min{" "}
                            {cityBonus.stone_bonus > 0 && (
                                <span className="inline">

                    </span>
                            )}
                        </h2>
                    </li>
                    <li className="flex items-center flex-wrap">
                        <IronIconResponsive/>
                        <h2 className="pl-2 text-base sm:text-base md:text-sm lg:text-base xl:text-base">
                            {cityProductionStats.iron_per_min}/min{" "}
                            {cityBonus.iron_bonus > 0 && (
                                <span className="inline">

                    </span>
                            )}
                        </h2>
                    </li>
                </ul>
            </div>
            {showModal && <div className="bg-mainLightBrown3">
                <h4>
                    Bonuses
                </h4>
                <h4>
                    Wheat: ({cityBonus.wheat_bonus}% bonus)
                </h4>
                <h4>
                    Wood: ({cityBonus.wood_bonus}% bonus)
                </h4>
                <h4>
                    Stone: ({cityBonus.stone_bonus}% bonus)
                </h4>
                <h4>
                    Iron: ({cityBonus.iron_bonus}% bonus)
                </h4>
            </div>}
        </div>
    );
}
