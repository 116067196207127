import {WheatIconResponsive} from "../../images/resources/WheatIconResponsive";
import {WoodIconReponsive} from "../../images/resources/WoodIconReponsive";
import {StoneIconResponsive} from "../../images/resources/StoneIconResponsive";
import {IronIconResponsive} from "../../images/resources/IronIconResponsive";
import {CASINO_COINS_BUY_PRICE, CASINO_COINS_SELL_PRICE} from "../../RULES";

export function ResourcePriceCard(props){


    return <div className="grid grid-cols-2 xxxs:grid-cols-4 gap-2">
        <div className="mr-4 flex">
            <WheatIconResponsive/>
            {props.priceType === "buy" ? <h4>{CASINO_COINS_BUY_PRICE.Wheat}</h4> : <h4>{CASINO_COINS_SELL_PRICE.Wheat}</h4> }
        </div>

        <div className="mr-4 flex">
            <WoodIconReponsive/>
            {props.priceType === "buy" ? <h4>{CASINO_COINS_BUY_PRICE.Wood}</h4> : <h4>{CASINO_COINS_SELL_PRICE.Wood}</h4> }
        </div>

        <div className="mr-4 flex">
            <StoneIconResponsive/>
            {props.priceType === "buy" ? <h4>{CASINO_COINS_BUY_PRICE.Stone}</h4> : <h4>{CASINO_COINS_SELL_PRICE.Stone}</h4> }
        </div>

        <div className="mr-4 flex">
            <IronIconResponsive/>
            {props.priceType === "buy" ? <h4>{CASINO_COINS_BUY_PRICE.Iron}</h4> : <h4>{CASINO_COINS_SELL_PRICE.Iron}</h4> }
        </div>


    </div>
}