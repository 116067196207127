import { useState } from "react";
import {HelperFunctions} from "../../HelperFunctions";
import {useIncomingAttackTimerState} from "../contexts/timerContext";
import {Link} from "react-router-dom";
import IncomingAttackIcon from "../../images/attacks/incomingAttackIcon.png";

export function DefenceTimerList() {
    const incomingAttack = useIncomingAttackTimerState();
    const [showList, setShowList] = useState(true);


    const toggleList = () => setShowList(!showList);

    return (
        <div className="rounded-md overflow-hidden bg-mainLightRed shadow-md">
            <div className="bg-mainDefend py-2 px-4 flex justify-between items-center">
                <Link to={"/cityActivity"} className="text-sm  font-semibold text-white">
                    {incomingAttack.length} Incoming {incomingAttack.length === 1 ? "Attack" : "Attacks"}
                </Link>

                    <svg
                        onClick={toggleList}
                        xmlns="http://www.w3.org/2000/svg"
                        className={`h-5 w-5 text-white cursor-pointer transform transition duration-300 ${
                            showList ? "rotate-0" : "rotate-180"
                        }`}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M11.293 9.293a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L10 11.414l2.293 2.293a1 1 0 001.414-1.414l-3-3z"
                            clipRule="evenodd"
                        />
                    </svg>

            </div>
            {showList && (
                <ul className="divide-x divide-y divide-gray-300 grid grid-cols-2 md:grid-cols-1 mxl:grid-cols-2">
                    {incomingAttack.slice(0, 2).map((defender) => (
                        <li
                            key={defender.attackId}
                            className="py-2 px-4 text-gray-800 flex flex-col justify-between items-center "
                        >



                            <div className="flex flex-row text-xs justify-center">

                                <img src={IncomingAttackIcon} alt="Incoming attack icon"/>
                            <Link to={`/profile/${defender.attackerUsername}`} > {defender.attackerUsername}</Link>

                            </div>
                            <div className="text-mainDefend font-semibold text-xs">
                                {HelperFunctions.secondsToTimestamp(defender.timeLeft)}
                            </div>

                        </li>
                    ))}
                    {incomingAttack.length > 2 && (
                        <li className="  text-gray-800 flex justify-center col-span-2 md:col-span-1 mxl:col-span-2">
                            <Link
                                to="/cityActivity"
                                className="text-blue-600"
                            >
                                See all
                            </Link>
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
}
