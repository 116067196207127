import {useContext} from "react";
import {CasinoContext} from "../../index";
import {CasinoCoinResponsive} from "../../images/CasinoCoinResponsive";

export function CasinoBuyInCard(props){
    const {currentCasinoCoins} = useContext(CasinoContext);

    return<div className="flex flex-col justify-between">
            <div>
                <h2 className="text-black text-3xl mt-12 text-center">Buy in</h2>
            </div>

            <div className="flex">
                <CasinoCoinResponsive/>
                <input value={props.buyInAmount} min={0} max={currentCasinoCoins} className="ml-4 mr-2 w-[4vw] min-w-[45px] pr-2 bg-mainLightBrown3 text-white" type="number" onChange={(e) => props.setBuyInAmount(e.target.value)} />
                <span className="cursor-pointer text-blue-600 hover:text-blue-500 ml-1" onClick={() => {
                    props.setBuyInAmount(currentCasinoCoins)}

                }>
                          ({currentCasinoCoins})
                </span>
            </div>
            <div className="flex justify-center">

                <button className="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-4 rounded transition duration-200 ml-2 mb-12" onClick={props.onBuyIn} >Start</button>
            </div>
        {props.status && <div className="text-red-500">{props.status}</div>}
        </div>
}