import {useState} from "react";
import {TabItem} from "./Marketplace";
import {BuildingDescAndImage} from "../cards/BuildingDescAndImage";
import {BarracksTroopsList} from "./BarracksTroopsList";


export function Barracks({level, hideUpgradeMenu}){

    const [activeTab, setActiveTab] = useState("overview");

    const handleTabChange = (tab) => {
        if(activeTab === "overview"){
            hideUpgradeMenu(false)
        }else{
            hideUpgradeMenu(true)
        }
        setActiveTab(tab);
    };


    return <div >
        <div className="mb-4">
            <ul className="flex border-b">
                <TabItem
                    label="Overview"
                    active={activeTab === "overview"}
                    onClick={() => handleTabChange("overview")}
                />
                <TabItem
                    label="Production"
                    active={activeTab === "production"}
                    onClick={() => handleTabChange("production")}
                />

            </ul>
        </div>

        {activeTab === "overview" && <BuildingDescAndImage type="Barracks" isDetails={true}/> }
        {activeTab === "production" && <BarracksTroopsList level={level}/> }
    </div>
}