import {useEffect, useState} from "react";
import { SendResourcesCard } from "../cards/SendResourcesCard";
import { SellResourcesCard } from "../cards/SellResourcesCard";
import { BuyResourcesCard } from "../cards/BuyResourcesCard";
import {MarketplaceHistoryCard} from "../cards/MarketplaceHistoryCard";
import {MarketplaceOverviewCard} from "../cards/MarketplaceOverviewCard";
import {HighScoreNavDropDown} from "../lists/HighScoreNavDropDown";



export function Marketplace({hideUpgradeMenu}) {

  const [isOuterDropDownOpen, setIsOuterDropDownOpen] = useState(false);
  const options = [
    { value: "overview", text: "Overview" },
    { value: "send", text: "Send" },
    { value: "sell", text: "Sell" },
    { value: "buy", text: "Buy" },
    { value: "history", text: "History" },
  ];

  const [activeTab, setActiveTab] = useState("overview");

  const handleTabChange = (tab) => {
    if(tab === "overview"){
      hideUpgradeMenu(true)
    }else{
      hideUpgradeMenu(false)
    }
    setActiveTab(tab);

  };



  useEffect(() => {
    setIsOuterDropDownOpen(false)
  }, [activeTab])
  return (
    <div className="flex flex-col items-center">

      <div className="mb-4">
        <ul className="flex md:flex hidden text-lg sm:text:lg md:text-sm lg:text-base xl:text-lg text-white pb-4 ">
          <TabItem
              label="Overview"
              active={activeTab === "overview"}
              onClick={() => handleTabChange("overview")}
          />
          <TabItem
            label="Send"
            active={activeTab === "send"}
            onClick={() => handleTabChange("send")}
          />
          <TabItem
            label="Sell"
            active={activeTab === "sell"}
            onClick={() => handleTabChange("sell")}
          />
          <TabItem
            label="Buy"
            active={activeTab === "buy"}
            onClick={() => handleTabChange("buy")}
          />
          <TabItem
              label="History"
              active={activeTab === "history"}
              onClick={() => handleTabChange("history")}
          />
        </ul>
        <div className="z-[2000]">

          <div onClick={() => setIsOuterDropDownOpen(!isOuterDropDownOpen)}>

            <HighScoreNavDropDown options={options} onOptionChange={(value) => handleTabChange(value)} showOption={activeTab} />
          </div>
        </div>
      </div>

      {activeTab === "overview" && <MarketplaceOverviewCard />}
      {activeTab === "send" && <SendResourcesCard />}
      {activeTab === "sell" && <SellResourcesCard onSellComplete={() => handleTabChange("buy")} />}
      {activeTab === "buy" && <BuyResourcesCard onBuyComplete={() => handleTabChange("send")} isOuterDropDownOpen={isOuterDropDownOpen} />}
      {activeTab === "history" && <MarketplaceHistoryCard onBuyComplete={() => handleTabChange("send")} />}


    </div>
  );
}

export function TabItem({ label, active, onClick }) {
  const activeClasses = active
    ? "bg-cyan-700 border-indigo-600"
    : "bg-mainDarkBrown text-gray-500 border-transparent";

  return (
    <li
      className={` mr-1 last:mr-0 flex-1 text-center border-b-2 cursor-pointer p-2 ${activeClasses} hover:bg-cyan-700`}
      onClick={onClick}
    >
      <span className="inline-block text-white">{label}</span>


    </li>
  );
}
