import { AuthContext } from "./index";
import React, {useContext, useEffect} from 'react'
import {Navigate, Outlet} from "react-router-dom"
import Cookies from "js-cookie";

const PrivateRoute = () => {
    const { authToken, setAuthToken } = useContext(AuthContext);

    useEffect(() => {
        if (!authToken) {
            let token = Cookies.get("authToken");
            if (token) {
                setAuthToken(token);
            }
        }
    }, [authToken, setAuthToken]);

    if (!authToken) {
        let token = Cookies.get("authToken");
        if (!token) {
            return <Navigate to="/login" replace />;
        }
    }

    return <Outlet />;
};

export default PrivateRoute;