import NotificationIcon from '../images/navigations/NotificationsNav.png';

export function NotificationsHasUnreadNavigationImage({notificationCount}){

    return <div className="w-12 md:w-14 relative">
        <img src={NotificationIcon} alt="Notification, no new" />
        <div className=" bg-red-500 absolute top-0 right-0 rounded-full p-0.5">
            {notificationCount}
        </div>
    </div>
}