import {ClubsSuit} from "./suits/ClubSuit";
import {HearthSuit} from "./suits/HearthSuit";
import {DiamondSuit} from "./suits/DiamondSuit";
import {SpadesSuits} from "./suits/SpadesSuits";

const suitsComponents = {
    "Clubs": <ClubsSuit/>,
    "Hearts": <HearthSuit/>,
    "Diamonds": <DiamondSuit/>,
    "Spades": <SpadesSuits/>
};

export function CardSuit({suit}) {
    return suitsComponents[suit] || null;
}
