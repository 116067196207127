import {useContext, useEffect, useState} from "react";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {AllianceContext} from "../../index";

export function AllianceInviteCard(props){

    const {allianceInviteList} = useContext(AllianceContext)
    const [newUsername, setNewUsername] = useState("");
    const [error, setError] = useState();
    const [statusMessage, setStatusMessage] = useState();
    const [allianceIsFull, setAllianceIsFull] = useState(true)

    async function invitePlayer(){
        setError(undefined)
        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/invite`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({newUsername: newUsername, allianceId: props.role.alliance_id  }),
            credentials: "include"
        });

        if(res.status === 400){
            const errorText = await res.text()

            setError(errorText)
        }
        if(res.status === 200){
            // Redirect to the alliance homepage?
        }
    }


    async function answerInvite(answer, allianceId, inviteId){
        setStatusMessage(undefined)

        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/answer/invite`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({answer, allianceId, inviteId  }),
            credentials: "include"
        })

        if(res.status === 204){
            setStatusMessage("Invitation declined")

        }
    }


    useEffect(() => {
        if(props.currentMembers >= props.maxMembers){
            setAllianceIsFull(true)
        }else {
            setAllianceIsFull(false)
        }
    }, [props.currentMembers])



    return (
        <div className="flex justify-center">
            <div>
                <div  className="p-2 rounded-lg text-black border border-2 border-black mb-2">


            {props.role.candoeverything || props.role.caninvite ? (
                <div className="mb-4">

                    <h2 className="text-base md:text-lg font-semibold  mb-2 text-center">
                        Invite players!
                    </h2>

                    <div className="flex flex-col md:flex-row items-center space-x-2">
                        <div className="mb-1 md:mb-0" >Username</div>
                        <input
                            type="text"
                            className="border border-gray-300 p-2 rounded-lg bg-mainLightBrown3 mb-2 md:mb-0 text-xs sm:text-base" maxLength={15}
                            onChange={(e) => setNewUsername(e.target.value)}
                        />
                        {!allianceIsFull?<button
                            className="bg-mainDarkBrown p-2 rounded-lg text-white hover:bg-mainLightBrown3 transition duration-200"
                            onClick={invitePlayer}
                        >
                            Send invitation
                        </button>: <button disabled className="bg-red-500 p-2 rounded-lg text-white hover:bg-blue-600 transition duration-200">
                            Alliance is full
                        </button>}

                    </div>
                    {error && <div className="text-red-500 text-xs md:text-sm flex justify-center">{error}</div>}
                </div>
            ) : (
                <div >
                    You don't have permission to invite new users
                </div>
            )}
                </div>




            {allianceInviteList && allianceInviteList.length > 0 && (
                <div className="p-2 rounded-lg text-black border border-2 border-black ">
                <div className="flex justify-center">


                <div>
                    <h2 className="text-base md:text-lg font-semibold mb-2">
                        All invites:
                    </h2>
                    <ul className="w-full">
                        {allianceInviteList.map((obj) => (
                            <li
                                key={obj.invitation_id}
                                className="bg-mainLightBrown1 border border-gray-300 p-2 rounded-lg mb-4 flex items-center justify-between text-gray-800"
                            >
                                <h2 className="text-xs sm:text-base font-semibold mr-2">
                                    {obj.username}
                                </h2>
                                <button
                                    className="bg-red-500 p-2 rounded-full hover:bg-red-600 transition duration-200"
                                    onClick={() =>
                                        answerInvite("Declined", obj.alliance_id, obj.invitation_id)
                                    }
                                >
                                    <XMarkIcon className="h-4 w-4 text-white" />
                                </button>
                            </li>
                        ))}
                    </ul>
                    {statusMessage && (
                        <div className="text-green-500">{statusMessage}</div>
                    )}
                </div>
                </div>
                </div>
            )}

        </div>
        </div>
    );
}