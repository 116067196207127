import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {ConfirmModal} from "../modals/ConfirmModal";


export function LeaveAllianceCard(props) {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleLeaveAlliance = () => {


        fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/leave/${props.allianceId}`, {
            credentials: "include"
        })

        navigate("/town")
        setShowModal(false);
    };

    useEffect(() => {

    }, [])

    // Only show button if not the owner of the alliance

    return (
        <div >
            {props.isOwner ? <div className="mt-8 flex flex-col justify-center">
                <button className="bg-red-600 text-white" disabled>Leave alliance</button>
                <h2 className="text-red-500 text-center">You are the owner! You cant leave the alliance</h2>
            </div>: <div className="flex justify-center"><button
                className="px-4 py-2 bg-red-600 text-white rounded-lg mt-4"
                onClick={() => setShowModal(true)}
            >
                Leave Alliance
            </button></div>}

            {showModal && (
                <ConfirmModal
                    onConfirm={handleLeaveAlliance}
                    onCancel={() => setShowModal(false)}
                />
            )}
        </div>
    );
}
