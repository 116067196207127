import {useEffect, useState} from "react";
import {InventorySpotCard} from "./InventorySpotCard";
import {AnimatePresence} from "framer-motion";
import {LootChestPrizeModal} from "../../modals/LootChestPrizeModal";

export function ChestInventory(){
    const [items, setItems] = useState([])
    const [unclaimedPrize, setUnclaimedPrize] = useState()


    async function fetchInventory(){
        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/casino/inventory/all`, {credentials: "include"});

        if(res.status === 200){
            const data = await res.json()

            updateInventory(data)

        }

    }


    async function checkUnclaimedPrize(){
        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/casino/inventory/unclaimed`, {credentials: "include"})

        if(res.status === 200){
            const data = await res.json()
            setUnclaimedPrize(data)
        }
    }


    function setNewUnclaimedPrize(prizeItem){
        setUnclaimedPrize(prizeItem)
    }



    function updateInventory(inventoryData){
        const openSpotsInInventory = 18 - inventoryData.amount_of_chests
        const newInventory = [];
        // fill up array with inventory
        for(let i = 0; i < inventoryData.amount_of_chests; i++){
            newInventory.push("Chest")
        }
        // Fill the rest of the inventory with empty spots
        for(let i = 0; i < openSpotsInInventory; i++){
            newInventory.push("empty")
        }
        setItems(newInventory)
    }
    async function claimPrize() {
        // Send a request to the backend to claim the prize and remove it from the db
        await fetch(`${import.meta.env.VITE_GAMEHOST}/api/casino/inventory/claim`, {credentials: "include"})
        setUnclaimedPrize(null);
    }

    useEffect(()=>{

        checkUnclaimedPrize()
        fetchInventory()

    },[])

    if(items.length === 0){
        return <div>Loading...</div>
    }

    return <div className="grid grid-cols-2  xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 gap-1 ">
        {items.map((item, index) => (
           <InventorySpotCard index={index} item={item} setNewItems={updateInventory} setUnclaimedPrize={setNewUnclaimedPrize}/>
        ))}
        <AnimatePresence>
            {unclaimedPrize && <LootChestPrizeModal prize={unclaimedPrize} closeModal={claimPrize} />}
        </AnimatePresence>
    </div>
}
