import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export function AllianceBaseDetailsCard(props) {

    const navigate = useNavigate();

    function navigateToDetailsPage () {
        localStorage.setItem("alliance_id", props.allianceInfo.alliance_id)
        navigate("/allianceDetails")
    }

    return (
        <div className=" bg-mainLightBrown3 w-full min-w-[230px]">
        <div className="grid place-items-center shadow-md p-6">
            <h2 className="text-xl md:text-2xl xl:text-3xl font-bold mb-2">
                {props.allianceInfo.alliance_name}
            </h2>
            <div className="flex items-center mb-4">
                <h3 className="text-base md:text-xl xl:text-2xl font-semibold mr-2">Tag:</h3>
                <span className="text-base md:text-xl xl:text-2xl font-semibold bg-mainLightBrown2 text-purple-900 py-1 px-2 rounded">
                    #{props.allianceInfo.alliance_tag}
                </span>
            </div>
            <h3 className="text-sm md:text-base xl:text-lg font-semibold mb-4">
                Members: {props.allianceInfo.alliance_members}/
                {props.allianceInfo.alliance_max_members}
            </h3>
            <button className="bg-mainDarkBrown text-white text-sm md:text-base xl:text-lg font-semibold px-6 py-2 rounded-lg hover:bg-mainLightBrown2 transition-colors duration-200" onClick={() => navigateToDetailsPage()}>
                Go to alliance
            </button>
        </div>
        </div>
    );
}
