import {BuildingInfoImage} from "../../images/BuildingInfoImage";
import {BuildingDescriptionCard} from "./BuildingDescriptionCard";
import {WAREHOUSE_BONUS_LEVEL} from "../../RULES";

export function BuildingDescAndImage({type, isDetails, level, upgradedBuilding}){

    return <div>
        <div className="grid grid-cols-1 xs:grid-cols-2 gap-4 place-items-center">
            <div className="order-last xs:order-first">
                <BuildingDescriptionCard type={type}/>
            </div>

            <div>
                <BuildingInfoImage type={type} isDetails={isDetails}/>
            </div>


        </div>
        <div className="flex justify-center font-semibold mt-2">
            {type === "Warehouse" && level && level < 30 && <div>
                {upgradedBuilding && upgradedBuilding.building_level ? <div>+{WAREHOUSE_BONUS_LEVEL[upgradedBuilding.building_level - 1]} warehouse capacity</div> : <div>+{WAREHOUSE_BONUS_LEVEL[level - 1]} warehouse capacity</div>}

            </div>}
        </div>
    </div>
}