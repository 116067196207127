import { useContext, useEffect, useState } from "react";

import {CityConfigContext, UserContext, WarehouseContext} from "../../index.jsx";

import { useNavigate } from "react-router-dom";
import {ResFieldNextLevelInfo} from "../cards/ResFieldNextLevelInfo";
import {RESOURCE_FIELD_DESCRIPTIONS} from "../../constants";
import {ResFieldInfoImage} from "../../images/resourceFields/ResFieldInfoImage";
import {NextLevelRequirementsCard} from "../cards/NextLevelRequirementsCard";
import {useResourceUpgradeTimerState, useTimerDispatch} from "../contexts/timerContext";
import {timerActions} from "../reducers/timerReducer";



export function ResourceBox() {
  const { warehouse } = useContext(WarehouseContext);
  const { inCityId, cityProductionStats, cityBonus } = useContext(CityConfigContext);
  const {selectedResField } = useContext(CityConfigContext);
  const dispatch = useTimerDispatch();
  const { setShowSpinner } = useContext(UserContext);

  const [actualBuildTime, setActualBuildTime] = useState(99999999)
  const [resourceField, setResourceField] = useState();
  const resourceTimers = useResourceUpgradeTimerState()

  const [hasEnoughResources, setHasEnoughResources] = useState(false);
  const [resourceReq, setResourceReq] = useState();
  const [isMaxLevel, setIsMaxLevel] = useState(false);
  const [nextLevelInfo, setNextLevelInfo] = useState();
  const navigate = useNavigate();



  useEffect(() => {
    const selectedField = localStorage.getItem("selectedResField")
    const data = JSON.parse(selectedField)
    if(resourceTimers.length > 0){
      if(data.nextLevel == null){
        resourceTimers.map((item) => {
          if(item.location === data.location){
            data.nextLevel = item.extra.next_level;
            data.status = `${data.level} -> ${item.extra.next_level}`
          }
        })
      }
    }
    setResourceField(data)

  }, [selectedResField]);

  async function getFieldReq(level, type) {
    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/resource/field/req/${level}/${type}`, {
      credentials: "include"
    });

    if (res.status === 200) {
      const data = await res.json();

      if(cityBonus){
        const bonus = cityBonus.building_speed_bonus  / 100
        const timeToBuild = Math.round(data.reqs.seconds_to_complete / (1 + bonus))

        setActualBuildTime(timeToBuild)
      }else{
        setActualBuildTime(data.reqs.seconds_to_complete)
      }


      setNextLevelInfo(data.nextLevelInfo)
      setResourceReq(data.reqs);
    } else if (res.status === 204) {
      setIsMaxLevel(true);
    }
  }
  const build = async (newFieldId) => {

    setShowSpinner(true)
     const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/resource/upgrade/${newFieldId}/${resourceField.location}/${inCityId}`, {
           credentials: "include"
         }
    );

     if(res.status === 200){
       const data = await res.json();
       dispatch(
           {
             type: timerActions.ADD_TIMER,
             category: "resourceTimers",
             payload: {
               duration: data.building_total_time,
               location: data.location,
               timeLeft: data.timeLeft,
               endTime: data.end_time,
               extra: {
                 building_name: data.building_name,
                 next_level: data.building_level
               }
             }
           })
     }
    setTimeout(() => {
      setShowSpinner(false)
    }, 1700)

    navigate("/resources");

  };

  useEffect(() => {
    if (resourceReq) {
      if (
        warehouse.wheat >= resourceReq.wheat_req &&
        warehouse.wood >= resourceReq.wood_req &&
        warehouse.stone >= resourceReq.stone_req &&
        warehouse.iron >= resourceReq.iron_req
      ) {
        setHasEnoughResources(true);
      }
    }
  }, [warehouse, resourceReq]);

  useEffect(() => {

    if (resourceField) {
      if(resourceField.nextLevel){
        getFieldReq(resourceField.nextLevel, resourceField.type);
      }else{
        getFieldReq(resourceField.level, resourceField.type);
      }
    }
  }, [resourceField]);

  if (!resourceReq && !isMaxLevel) {
    return <div>Loading..</div>;
  }
  return (
        <div className="w-screen h-screen bg-mainLightGreen overflow-hidden">
          <div className="relative  top-[10vh] mx-4">


          <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4 text-black">
            <div className="bg-mainLightYellow p-4 overflow-y-scroll max-h-[67vh]">
                <div>
                  <h2 className="text-2xl font-bold mb-4 mt-4 text-center">
                    {resourceField.type} Level:{" "}
                    {resourceField.status ? (
                        <div className="inline">{resourceField.status}</div>
                    ) : (
                        <div className="inline">{resourceField.level} {isMaxLevel && <div className="inline">(MAX)</div>}</div>
                    )}
                  </h2>

                  {/*RES FIELD DESCRIPTION*/}
                  <div className="grid grid-cols-1 xs:grid-cols-2 gap-4 place-items-center">
                    <div className="order-2">
                      {resourceField.type === "Wheat" && <div>
                        <h4>{RESOURCE_FIELD_DESCRIPTIONS.Wheat}</h4>
                      </div>}
                      {resourceField.type === "Wood" && <div>
                        <h4>{RESOURCE_FIELD_DESCRIPTIONS.Wood}</h4>
                      </div>}
                      {resourceField.type === "Stone" && <div>
                        <h4>{RESOURCE_FIELD_DESCRIPTIONS.Stone}</h4>
                      </div>}
                      {resourceField.type === "Iron" && <div>
                        <h4>{RESOURCE_FIELD_DESCRIPTIONS.Iron}</h4>
                      </div>}
                    </div>
                    {/*RES FIELD IMAGE*/}
                    {resourceField && <div className="lg:row-span-2 xl:row-span-2 xs:order-2 order-1">
                      <ResFieldInfoImage type={resourceField.type}/>
                    </div>}

                    {/*RES FIELD NEXT LEVEL INFO*/}
                    <div className="col-span-1 xs:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1 order-3">

                      {nextLevelInfo && cityProductionStats && <ResFieldNextLevelInfo nextLevelInfo={nextLevelInfo} cityProductionStats={cityProductionStats} resType={resourceField.type} bonuses={cityBonus}/>}
                    </div>
                  </div>

                  {/*NEXT LEVEL REQUIREMENTS*/}
                  {!isMaxLevel && <div className="justify-center">
                    <NextLevelRequirementsCard level={resourceReq.level} wheatReq={resourceReq.wheat_req} woodReq={resourceReq.wood_req} stoneReq={resourceReq.stone_req}
                                               ironReq={resourceReq.iron_req} cityProductionStats={cityProductionStats} build={build} actualBuildTime={actualBuildTime}
                                               hasEnoughResources={hasEnoughResources} resReqId={resourceReq.id} bonuses={cityBonus}/>
                  </div>}

                </div>

            </div>
          </div>
          </div>
        </div>

  );
}
