import {useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";

export function SlotSquare({direction, icons, startSpinning, setStartSpinning, stopDelay, onSpinEnd}) {

    const speed = 2;
    const animationDuration = 0.5 / speed;
    const totalSpinTime = 3000 / speed;
    const [iconStates, setIconStates] = useState([{icon: icons[0], key: Date.now()}]);
    const [isStopping, setIsStopping] = useState(false);

    const lastIcon = useRef(null);
    const spinningStoppedRef = useRef(false); // Ref to track if spinning is stopped


    const updateIcon = () => {
        if (spinningStoppedRef.current) {
            return;
        }
        setIconStates(prev => {
            const nextIcon = icons[Math.floor(Math.random() * icons.length)];
            if (!isStopping) {
                lastIcon.current = nextIcon;
            }
            if (prev.length > 1) {
                prev.pop();
            }
            return [{ icon: nextIcon, key: Date.now() }, ...prev];
        });
    }




    const [iconUpdatedAfterStopping, setIconUpdatedAfterStopping] = useState(false);

    useEffect(() => {
        let iconUpdateTimeout;
        let endTimeout;

        if (startSpinning) {
            setIconUpdatedAfterStopping(false);
            setIsStopping(false);
            spinningStoppedRef.current = false;
            iconUpdateTimeout = setTimeout(updateIcon, animationDuration * 1000);

            endTimeout = setTimeout(() => {
                setIsStopping(true);
                setStartSpinning(false);
                spinningStoppedRef.current = true;
            }, totalSpinTime + stopDelay);
        }

        return () => {
            spinningStoppedRef.current = true;
            clearTimeout(iconUpdateTimeout);
            clearTimeout(endTimeout);
        };
    }, [startSpinning, isStopping]);

    useEffect(() => {
        if (isStopping && !iconUpdatedAfterStopping) {
            setIconUpdatedAfterStopping(true);
            onSpinEnd(lastIcon.current);
        }
    }, [isStopping, iconUpdatedAfterStopping]);




    const motionProps = {
        initial: {top: direction === 'down' ? '-35%' : '120%'},
        animate: {top: (!startSpinning || isStopping) ? '20%' : (direction === 'down' ? '120%' : '-80%')},
        transition: {
            duration: isStopping ? (animationDuration / 2) : animationDuration,
            ease: "linear",
            onComplete: () => {
                if (startSpinning && !isStopping) {
                    setIconStates(prev => {
                        if (prev.length > 1) {
                            prev.pop();
                        }
                        return [...prev];
                    });
                    setTimeout(updateIcon, animationDuration * 1000);
                }
            }
        }
    };



    return (
        <div
            className="bg-blue-400 w-[10vw] h-[10vw] min-w-[55px] min-h-[55px] max-w-32 max-h-32 flex justify-center place-items-center border border-black overflow-hidden relative">
            {iconStates.map((iconState, index) => (
                index === 0 && (
                    <motion.h2
                        {...(isStopping && index === 0 ? {...motionProps, animate: {top: '20%'}} : motionProps)}
                        key={iconState.key}
                        className="text-3xl md:text-5xl lg:text-6xl absolute"
                    >
                        {iconState.icon}
                    </motion.h2>
                )
            ))}
        </div>
    );

}