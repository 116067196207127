import { useParams } from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import { ProfileCard } from "../Components/cards/ProfileCard";
import {UserContext} from "../index";



export function ProfilePage() {
  const { userInfo } = useContext(UserContext);
  const { userFromPath } = useParams();
  const [isMyProfile, setIsMyProfile] = useState(false);
  const [profile, setProfile] = useState();
  const [allBadges, setAllBadges] = useState([]);
  const [visibleBadges, setVisibleBadges] = useState([]);
  const [status, setStatus] = useState("");

  async function getAllBadges(){
    // get all the badges the user has earned
    // give the option to choose specific badges to show or remove from profile
    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/badges/all/${userFromPath}`, {
      credentials: "include"
    });

    if(res.status === 200){
      const data = await res.json();
      setAllBadges(data)
      const visibleBadgesData = data.filter(badge => badge.show_on_profile);

      setVisibleBadges(visibleBadgesData);


    }
  }

  const fetchProfile = async () => {
    setStatus("")
    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/auth/profile/${userFromPath}`, {
      credentials: "include"
    });
    if(res.status === 200){

      const newProfile = await res.json();
      setProfile(newProfile);
    }else if(res.status === 404){
      const data = await res.text()
      setStatus(data)
    }
  };

  useEffect(() => {
    getAllBadges()
    if (userFromPath === "me" || userFromPath === userInfo) {
      setIsMyProfile(true);
    } else {
      setIsMyProfile(false);
    }
    fetchProfile();
  }, [userInfo]);

  if (!profile && !status) {
    return <div>Loading...</div>;
  }
  return (
    <ul className="w-screen h-screen bg-mainLightGreen overflow-hidden">

      <li className="relative top-[10vh]">
        <ul className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4">

          {profile && <li className="max-w-full scrollbar scrollbar-thumb-blue-500 scrollbar-thick bg-mainLightYellow p-2  max-h-[70vh] min-h-0 overflow-auto">
            <ProfileCard user={profile} canEdit={isMyProfile} allBadges={allBadges} visibleBadges={visibleBadges} />
          </li>}

          {status && <li className="text-red-500">{status}</li>}
        </ul>
      </li>

    </ul>
  );
}
