import {useFounderTimerState} from "../contexts/timerContext";
import {useEffect} from "react";
import {FounderIconResponsive} from "../../images/troops/FounderIconResponsive";
import {HelperFunctions} from "../../HelperFunctions";

export function NextFounderInQueueModal() {
    const founderTimers = useFounderTimerState();

    if(founderTimers === undefined || founderTimers.length <= 0){
        return
    }

    return(
        <div className="bg-gray-800 bg-opacity-50 rounded-lg shadow-md p-4 text-white hover:shadow-lg transition-all duration-200 ease-in-out flex text-center justify-center">
            <h3 className="text-xs lg:text-sm font-fantasy font-semibold mb-2">
                    Next:
            </h3>
            <div className="font-pixel flex">
                <FounderIconResponsive/>
                <h4>
                    {HelperFunctions.secondsToTimestamp(founderTimers[0].timeLeftNextUnit)}
                </h4>
            </div>
        </div>
    )

}