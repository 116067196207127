import {useContext, useEffect, useState} from "react";
import {CasinoContext} from "../../index";
import {CasinoBuyInCard} from "./CasinoBuyInCard";
import {BlackjackGameCard} from "./BlackjackGameCard";

export function CasinoBlackjack() {
    const {currentCasinoCoins, blackJackDetails} = useContext(CasinoContext);
    const [buyInAmount, setBuyInAmount] = useState(0);
    const [showComponent, setShowComponent] = useState("buyIn");
    const [status, setStatus] = useState("");



    useEffect(() => {
        if(blackJackDetails){
            // Is in game
            setShowComponent("game")
        }
    }, [blackJackDetails])

    async function onBuyIn(){

        setStatus("")

        if(currentCasinoCoins < buyInAmount){
            setStatus("You do not have that many coins!")
            return
        }



        if(buyInAmount === 0 || parseInt(buyInAmount) === 0){
            setStatus("You need to select some coins")
            return
        }

        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/casino/blackjack/start/${buyInAmount}`, {credentials: 'include'})

        if(res.status !== 200){
            const data = await res.text();
            setStatus(data)
        }
        setShowComponent("game")

    }

    return (
        <div className="flex justify-center">
        <div className="bg-green-500 w-full h-[50vh] flex justify-center min-h-[400px]">

            {showComponent === "buyIn" && <CasinoBuyInCard setBuyInAmount={setBuyInAmount} buyInAmount={buyInAmount} onBuyIn={onBuyIn} status={status}/>}


            {showComponent === "game" && blackJackDetails && <BlackjackGameCard changeTab={() => setShowComponent("buyIn")} />}


        </div>
        </div>
    );
}
