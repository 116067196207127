import {useFoundNewCityTimer} from "../contexts/timerContext";

export function FoundNewCityTimerList() {

    const foundNewCityTimers = useFoundNewCityTimer()

    function secondsToHms(secondsLeft) {
        secondsLeft = Number(secondsLeft);
        const h = Math.floor(secondsLeft / 3600);
        const m = Math.floor(secondsLeft % 3600 / 60);
        const s = Math.floor(secondsLeft % 3600 % 60);

        const hDisplay = h > 0 ? (h < 10 ? '0' : '') + h + ':' : '00:';
        const mDisplay = m > 0 ? (m < 10 ? '0' : '') + m + ':' : '00:';
        const sDisplay = s > 0 ? (s < 10 ? '0' : '') + s : '00';

        return hDisplay + mDisplay + sDisplay;
    }

    if (!foundNewCityTimers || foundNewCityTimers.length <= 0) {
        return null
    }



    return (
        <div className="bg-mainDarkBrown  p-2 text-center ">
            <div className="bg-mainLightBrown1 max-h-[80px] overflow-auto scrollbar scrollbar-thumb-blue-500">
                {foundNewCityTimers.map((item) => (
                    <div className="border border-b border-cyan">
                        {item.cityName} in {secondsToHms(item.timeLeft)}
                    </div>
                ))}
            </div>
        </div>
    );
}
