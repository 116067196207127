import {React, useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome";
import { faTrash,  } from "@fortawesome/free-solid-svg-icons";
import {PaginationControls} from "../PaginationControls";
import {UserContext} from "../../index";



export function MessageList(props) {
    const { numberOfUnreadMessages, setNumberOfUnreadMessages } = useContext(UserContext);

  const [messages, setMessages] = useState([]);
  const [statusText, setStatusText] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const navigate = useNavigate()

    const toggleMessageSelection = (message) => {
        const index = selectedMessages.findIndex(m => m.message_id === message.message_id);
        if (index > -1) {
            // Message is already selected, so remove it
            setSelectedMessages(selectedMessages.filter(m => m.message_id !== message.message_id));
        } else {
            // Message is not selected, so add it
            setSelectedMessages([...selectedMessages, message]);
        }
    };

 async function deleteMessages(){
      const messageIds = selectedMessages.map((message) => message.message_id);

    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/messages/delete`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ messageIds }),
        credentials: "include"
    });

    if(res.status === 200){
        const unseenMessages = selectedMessages.filter((item) => item.hasseen === false);
        if(unseenMessages.length > 0){
            setNumberOfUnreadMessages(numberOfUnreadMessages - unseenMessages.length)
        }
        setSelectedMessages([])

      if (props.tab) {
        if (props.tab === "inbox") {
          await getPageCount("/api/messages/count/inbox")
          await getPage(`/api/messages/inbox/${pageNumber}`);
        } else if (props.tab === "sendt") {
          await getPageCount("/api/messages/count/sendt")
          await getPage(`/api/messages/sendt/${pageNumber}`);
        }
      }
    }
  }



  async function getPage(url) {
    setStatusText("");
    setMessages([]);


    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}${url}`, {
        credentials: "include"
    });

    if (res.status === 200) {
      const data = await res.json();

        data.forEach((msg) => {
            if(msg.subject.length > 8){
                msg.displaySubject = msg.subject.slice(0, 8)
                msg.displaySubject += "..."
            }else{
                msg.displaySubject = msg.subject
            }
        })
      setMessages(data);
    }

    if (res.status === 404) {
      setStatusText("Empty");
    }
  }

  function toggleSelectAll(e) {
    if (e.target.checked) {
      setSelectedMessages(messages);
    } else {
      setSelectedMessages([]);
    }
  }

  async function getPageCount(url) {
    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}${url}`, {
        credentials: "include"
    });

    if (res.status === 200) {
      const data = await res.json();


      setTotalPageCount(data);

    }
  }

  useEffect(() => {


    getPage(`/api/messages/inbox/${pageNumber}`);
    /*getPageCount();*/
  }, []);

  useEffect(() => {

    if (props.tab) {
      if (props.tab === "inbox") {
        getPageCount("/api/messages/count/inbox")
        getPage(`/api/messages/inbox/${pageNumber}`);
      } else if (props.tab === "sendt") {
        getPageCount("/api/messages/count/sendt")
        getPage(`/api/messages/sendt/${pageNumber}`);
      }

    }
  }, [props.tab, pageNumber]);

  useEffect(() => {
    setPageNumber(1)
    setTotalPageCount(1)
  }, [props.tab])


  if (messages.length === 0 && !statusText) {
    return <div>Loading...</div>;
  }

    return (
        <div className="w-full text-sm sm:text-sm md:text-base lg:text-lg xl:text-xl">
            <div className="">
                {/* For medium and larger screens */}
                <div className="hidden sm:block">
                    {messages.length > 0 && (
                        <div className="">
                            <div className="grid grid-cols-5 gap-4 bg-mainLightBrown1 text-center pt-2 pb-2 font-semibold ">
                                <div>
                                    <input
                                        type="checkbox"
                                        onChange={toggleSelectAll}
                                        className="form-checkbox h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px] "
                                    />
                                </div>
                                <div>Opened</div>
                                {props.tab === "inbox" && <div>From</div>}
                                {props.tab === "sendt" && <div>To</div>}
                                <div>Subject</div>
                                <div>Time</div>
                            </div>

                            {messages.map((message, index) => (
                                <div
                                    onClick={() => navigate(`/message/details/${message.message_id}`)}
                                    key={message.message_id}
                                    className={`grid grid-cols-5  ${index % 2 === 0 ? 'bg-mainLightBrown3': 'bg-mainLightBrown1'} text-center pt-2 pb-2  place-items-center `}
                                >
                                    <input
                                        onClick={(e) => e.stopPropagation()}
                                        type="checkbox"
                                        checked={selectedMessages.some(m => m.message_id === message.message_id)}
                                        onChange={(e) => {
                                            e.stopPropagation()
                                            toggleMessageSelection(message)
                                        } }
                                        className="form-checkbox h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px]"
                                    />
                                    <div>
                                        {props.tab === "inbox" && (
                                            <div>
                                                {message.hasseen ? (
                                                    <div className=" h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px] bg-green-500"> </div>
                                                ) : (
                                                    <div className="h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px] bg-red-400"> </div>
                                                )}
                                            </div>
                                        )}
                                        {props.tab === "sendt" && (
                                            <div className="h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px] bg-green-500"> </div>
                                        )}
                                    </div>
                                    {props.tab === "inbox" && (
                                        <div> {message.from_username}</div>
                                    )}
                                    {props.tab === "sendt" && <div> {message.to_username}</div>}

                                        <h4> {message.displaySubject}</h4>


                                    <div className="pr-2 text-xs"> {new Date(message.sendt).toLocaleString()}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* For small screens */}
                <div className="block sm:hidden">
                    {messages.length > 0 && (
                        <div>
                            <div>
                                <input
                                    type="checkbox"
                                    onChange={toggleSelectAll}
                                    className="form-checkbox h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px] "
                                />
                                <span className="ml-2">Select All</span>
                            </div>
                            <div className="grid grid-cols-1 gap-4">
                                {messages.map((message, index) => (
                                    <div key={message.message_id} className={`p-2 rounded-lg ${index % 2 === 0 ? 'bg-mainLightBrown3': 'bg-mainLightBrown1'}`} onClick={() => navigate(`/message/details/${message.message_id}`)}>

                                        <h4 className="text-center text-base sm-text-lg md:text-xl mb-2">{message.subject}</h4>

                                        <div className="flex justify-between">
                                            <input
                                                onClick={(e) => e.stopPropagation()
                                                }
                                                type="checkbox"
                                                checked={selectedMessages.some(m => m.message_id === message.message_id)}
                                                onChange={(e) => {
                                                    e.stopPropagation()
                                                    toggleMessageSelection(message)}
                                                }

                                                className="form-checkbox h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px]"
                                            />

                                            {props.tab === "inbox" && (
                                                <div>
                                                    {message.hasseen ? (
                                                        <div className=" h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px] bg-green-500"> </div>
                                                    ) : (
                                                        <div className="h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px] bg-red-400"> </div>
                                                    )}
                                                </div>
                                            )}
                                            {props.tab === "sendt" && (
                                                <div className="h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px] bg-green-500"> </div>
                                            )}

                                        </div>



                                        <div className="text-center">
                                            {props.tab === "inbox" && <div>From: {message.from_username}</div>}
                                            {props.tab === "sendt" && <div>To: {message.to_username}</div>}
                                        </div>

                                        <div className="text-center">{new Date(message.sendt).toLocaleString()}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {selectedMessages.length > 0 && (
                <button
                    className="bg-red-500 text-white p-2 rounded"
                    onClick={() => deleteMessages()}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </button>
            )}

            {/* PAGINATION */}
            <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPageCount={totalPageCount}/>

            {statusText && <div className="text-red-500">{statusText}</div>}
        </div>
    );

}
