import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "../../index";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../constants.js";
import {timerActions} from "../reducers/timerReducer";
import {useTimerDispatch} from "../contexts/timerContext";

export function EmptyTileCard(props) {
  const { inCityId } = useContext(CityConfigContext);
  const dispatch = useTimerDispatch();
  const [foundedInfo, setFoundedInfo] = useState();
  const [canFoundCity, setCanFoundCity] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newCityName, setNewCityName] = useState("");
  const [status, setStatus] = useState("");


  const navigate = useNavigate();

  async function foundNewCity() {
    setShowModal(true);
  }

  async function confirmCityName() {
    setStatus("");
    if (newCityName.length > 0) {
      const cityId = inCityId;
      const x = props.position.x;
      const y = props.position.y;

      const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/founder/new`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ cityId, x, y, newCityName }),
        credentials: "include"
      });

      if(res.status === 200){
        setShowModal(false);
        const data = await res.json();
        dispatch(
            {
              type: timerActions.ADD_TIMER,
              category: "foundNewCityTimer",
              payload: {
                duration: data.travelTimeMillies,
                timeLeft: data.timeLeft,
                endTime: data.arrivalTime,
                queueId: data.newItemId,
                cityName: data.cityName
              }
            })


        navigate("/resources");
      }else if(res.status === 402){
        setStatus("Max city  name is 15 characters!")
      }


    } else {
      setStatus("You need to name your new city!");
    }
  }

  async function getNumberOfFounders() {
    if (inCityId) {
      const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/city/check/founded/${inCityId}`, {
        credentials: "include"
      });
      const data = await res.json();

      if (data.founder_done >= 3) {
        setCanFoundCity(true);
      }
      setFoundedInfo(data);
    }
  }

  useEffect(() => {
    if (inCityId) {
      getNumberOfFounders();
    }
  }, [inCityId]);

  if (!foundedInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div >
      <h4 className="text-base md:text-lg xl:text-xl text-center">
        Empty tile
      </h4>

      <div className="text-center mb-2">
        (x: {props.position.x} y: {props.position.y})
      </div>
      {canFoundCity ? (
        <div className="flex flex-col justify-center">
          <div className="flex justify-center">
            <img
                src={CONSTANTS.TRIBE_ONE_TROOP_FOUR_IMAGE}
                alt={`Founder image`}
                className="w-8 h-8 mr-4 "
            />
            <div>{foundedInfo.founder_done}/3</div>
          </div>
          <button className="bg-mainDarkBrown" onClick={foundNewCity}>Found new city</button>
        </div>
      ) : (
        <div>
          <div className="text-center mb-2">
            You need to make founders in the mapChamber to found a new city!
          </div>
          <div className="flex justify-center">
            <img
                src={CONSTANTS.TRIBE_ONE_TROOP_FOUR_IMAGE}
                alt={`Founder image`}
                className="w-8 h-8 mr-4 "
            />
            <div>{foundedInfo.founder_done}/3</div>
          </div>
        </div>
      )}
      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <div className="bg-mainLightBrown4 rounded-lg shadow-lg p-8">
            <input
                maxLength={15}
              className="border border-gray-200 rounded-md p-2 mb-4 w-full"
              value={newCityName}
              onChange={(e) => setNewCityName(e.target.value)}
              placeholder="Enter new city name"
            />
           <div className="flex justify-center">
             <button
                 className="bg-red-500 text-white rounded-md px-4 py-2 mr-2"
                 onClick={() => {
                   setShowModal(false);
                   setNewCityName("");
                 }}
             >
               Cancel
             </button>
             <button
                 className="bg-mainDarkBrown text-white rounded-md px-4 py-2"
                 onClick={confirmCityName}
             >
               Confirm
             </button>
           </div>
            {status && <div className="text-red-500">{status}</div>}
          </div>
        </div>
      )}
    </div>
  );
}
