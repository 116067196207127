import { TownCenter } from "../Components/town/TownCenter";



export function TownPage() {
  return (
    <div className="w-screen h-screen bg-mainLightGreen overflow-hidden">
        <div className="relative top-[10vh]">
            <TownCenter />
        </div>

    </div>
  );
}
