export function DiamondSuit(){

    return (
        <svg
            width="643"
            height="663"
            viewBox="0 0 643 663"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="321.5"
                y="0"
                width="321.5"
                height="321.5"
                transform="rotate(45 321.5 0)"
                fill="#B70B0B"
                stroke="black"
                strokeWidth="5"
            />
        </svg>
    );

}