
import {MapGridV2} from "../Components/MapGridV2";


export function MapPage() {
  return (
      <div className="w-screen h-screen bg-mainLightGreen overflow-hidden">
          <div className="relative  top-[10vh]">


            <MapGridV2 />

          </div>
    </div>
  );
}
