import {React, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {CityProfileModal} from "../modals/CityProfileModal";
import {EditProfileModal} from "../modals/EditProfileModal";
import {EditCityNameModal} from "../modals/EditCityNameModal";
import {BadgeCard} from "./BadgeCard";
import {CityInfoProfileCard} from "./CityInfoProfileCard";
import { Tooltip } from 'react-tooltip'

export function ProfileCard(props) {
  const [newDescription, setNewDescription] = useState("");
  const [selectedBadges, setSelectedBadges] = useState([]);
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [isEditCityModalOpen, setIsEditCityModalOpen] = useState(false);
  const [isCityInfoModalOpen, setIsCityInfoModalOpen] = useState(false);
  const [selectedCityIndex, setSelectedCityIndex] = useState(null);
  const [newCityName, setNewCityName] = useState("");
  const [chosenCityName, setChosenCityName] = useState("");
  const [chosenCityPopulation, setChosenCityPopulation] = useState(0)
  const [chosenCityX, setChosenCityX] = useState(0)
  const [chosenCityY, setChosenCityY] = useState(0)
  const [chosenCityId, setChosenCityId] = useState(0)



  const updateDescription = async () => {


    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/auth/profile/update`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ newDescription, selectedBadges }),
      credentials: "include"
    });
    if (res.status === 200) {
      setIsEditProfileModalOpen(false);
      // TODO fix routing refresh when editing profile..
      window.location.reload();
    }
  };

  const updateCityName = async () => {
    if (selectedCityIndex !== null) {


      const updatedCities = [...props.user.cities];
      updatedCities[selectedCityIndex].name = newCityName;

      await fetch(`${import.meta.env.VITE_GAMEHOST}/api/city/change/name`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ name: updatedCities[selectedCityIndex].name, cityId: updatedCities[selectedCityIndex].cityId }),
        credentials: "include"
      })



        setIsEditCityModalOpen(false);


    }
  };

  useEffect(() => {
    setNewDescription(props.user.description)
  }, [])


  function openCityModal(cityName, cityPop, cityX, cityY, cityId){

    setChosenCityName(cityName)
    setChosenCityPopulation(cityPop)
    setChosenCityX(cityX)
    setChosenCityY(cityY)
    setChosenCityId(cityId)
    setIsCityInfoModalOpen(true)
  }




  return (
    <div className="container mx-auto  text-black mt-2 pl-4">
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-2">
            <div>
              <h2 className=" text:lg sm:text-xl  md:text-2xl font-semibold text-center md:text-left mb-2">{props.user.username}</h2>

              {/* Description */}
              <p className="mb-4">{props.user.description}</p>
              <div>
                {props.canEdit && (
                    <button
                        className="bg-profileButtonNormal hover:bg-profileButtonHover text-white font-bold rounded h-[40px] text-sm ml-2 mb-2"
                        onClick={() => setIsEditProfileModalOpen(true)}
                    >
                      Edit
                    </button>
                )}
              </div>
            </div>


          <div className="flex">


            {/*Badges*/}


            <div >
              {
                props.visibleBadges.length > 0 && <div>


                  <div className="flex">
                    <h4 className="text-xl font-semibold">Badges</h4>
                  </div>


                  <div className="grid grid-cols-2 xs:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
                    {props.visibleBadges.map((badge, index) => <a className="my-anchor-element" data-tooltip-content={`${badge.name} ${badge.description} earned ${new Date(badge.date_earned).toLocaleDateString()}`}>
                      <BadgeCard
                          key={index}
                          badge={badge}
                          isClickable={false}
                      />
                    </a>)}
                  </div>

                </div>
              }
            </div>
          </div>
        </div>
        {/* Total Population */}
        <div className="mb-1">
          <h4>Total Population: {props.user.totalPop}</h4>
        </div>
        {props.user.allianceTag && <Link to={`/alliance/${props.user.allianceTag}`}>#{props.user.allianceTag}</Link>}
        <div className="grid grid-cols-1 md:grid-cols-2">
        </div>
        {/* Cities List */}
        <div className="overflow-y-scroll scrollbar scrollbar-thumb-blue-500 scrollbar-thick">
        <div className=" ">
          <h3 className="text-xl text-black font-semibold mb-2 text-center">Cities</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
            {props.user.cities.map((city, index) => (
                <CityInfoProfileCard openCityModal={openCityModal} canEdit={props.canEdit} city={city} index={index} setIsEditCityModalOpen={setIsEditCityModalOpen} setNewCityName={setNewCityName} setSelectedCityIndex={setSelectedCityIndex}/>
            ))}
          </div>
        </div>
        </div>
      </div>
      {isEditProfileModalOpen && (
        <EditProfileModal
          description={newDescription}
          setDescription={setNewDescription}
          closeModal={() => setIsEditProfileModalOpen(false)}
          updateDescription={updateDescription}
          setSelectedBadges={setSelectedBadges}
          selectedBadges={selectedBadges}
          allBadges={props.allBadges}
          visibleBadges={props.visibleBadges}
        />
      )}
      {isEditCityModalOpen && (
          <EditCityNameModal
              cityName={newCityName}
              setCityName={setNewCityName}
              closeModal={() => setIsEditCityModalOpen(false)}
              updateCityName={updateCityName}
          />
      )}

      {isCityInfoModalOpen && (
          <CityProfileModal
          closeModal={() => setIsCityInfoModalOpen(false)}
          cityName={chosenCityName}
          username={props.user.username}
          cityPop={chosenCityPopulation}
          xCoord={chosenCityX}
          yCoord={chosenCityY}
          cityId={chosenCityId}
          isMyCity={props.canEdit}
          />
      )}
      <Tooltip anchorSelect=".my-anchor-element">

      </Tooltip>

    </div>
  );
}



