import { React } from "react";
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome";
import {faPeopleGroup, faMap, faPencil} from "@fortawesome/free-solid-svg-icons";
export function CityInfoProfileCard({ city, setSelectedCityIndex, setNewCityName, setIsEditCityModalOpen, index, canEdit, openCityModal }) {

    const openModal = () => openCityModal(city.name, city.cityPop, city.x, city.y, city.cityId);

    return (
        <div key={index} className={`border border-profileBorder p-2 ${index % 2 === 0 ? 'bg-profileSecondary' : 'bg-profileSecondary2'} rounded-lg shadow-lg transition hover:shadow-xl transform hover:-translate-y-1 cursor-pointer`} onClick={openModal}>

            <div className="h-full p-4 bg-profileBase rounded-lg flex flex-col text-sm ">

                <div className="flex justify-center">
                    <h4 className="text-xxs xs:text-base sm:text-xxs md:text-sm xl:text-base font-bold break-words whitespace-normal text-center mb-4 ">
                        {city.name}
                    </h4>
                    {canEdit && (
                        <FontAwesomeIcon icon={faPencil}
                                         className="ml-2 hover:scale-[120%]"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             setSelectedCityIndex(index);
                                             setNewCityName(city.name);
                                             setIsEditCityModalOpen(true);
                                         }}
                        />

                    )}
                </div>

                <div className="flex justify-around">
                    <div className="mb-2 text-center">
                        <FontAwesomeIcon icon={faPeopleGroup} className="mr-2"/>
                        <span className="font-semibold">{city.cityPop}</span>
                    </div>

                    <div className="mb-2 text-center">
                        <FontAwesomeIcon icon={faMap} className="mr-2 fa-regular"/>
                        <span className="font-semibold">({city.x}, {city.y})</span>
                    </div>
                </div>

            </div>
        </div>
    );

}
