import {useEffect, useState} from "react";

export function ResFieldNextLevelInfo({nextLevelInfo, cityProductionStats, resType, bonuses}){
    const [currentProdStat, setCurrentProdStat] = useState();
    const [nextLevelProdStat, setNextLevelProdStat] = useState();
    const [currentBonus, setCurrentBonus] = useState()

    useEffect(() => {

        if(resType === "Wheat"){
            setCurrentProdStat(cityProductionStats.wheat_per_min);
            setNextLevelProdStat(cityProductionStats.wheat_per_min + nextLevelInfo.effect)
            setCurrentBonus(bonuses.wheat_bonus)
        }else if(resType === "Wood"){
            setCurrentProdStat(cityProductionStats.wood_per_min);
            setNextLevelProdStat(cityProductionStats.wood_per_min + nextLevelInfo.effect)
            setCurrentBonus(bonuses.wood_bonus)
        }else if(resType === "Stone"){
            setCurrentProdStat(cityProductionStats.stone_per_min);
            setNextLevelProdStat(cityProductionStats.stone_per_min + nextLevelInfo.effect)
            setCurrentBonus(bonuses.stone_bonus)
        }else if(resType === "Iron"){
            setCurrentProdStat(cityProductionStats.iron_per_min);
            setNextLevelProdStat(cityProductionStats.iron_per_min + nextLevelInfo.effect)
            setCurrentBonus(bonuses.iron_bonus)
        }
    }, [])


    return (
        <div className="grid grid-cols-1 text-center place-items-center">
            <div className="bg-mainLightBrown1 text-center  text-base sm:text-base md:text-xl lg:text-xl xl:text-xl pl-5 pr-5 pt-3 pb-3 rounded">
                <h4 className="pb-4">
                    Next Level:
                </h4>
                <h4 className="pb-2">
                    +{nextLevelInfo.effect} {resType}/min
                </h4>
                {cityProductionStats && <h4 className="pb-2">
                    {currentProdStat}/min to {nextLevelProdStat}/min ({currentBonus}%)
                </h4>}
                <h4>
                    +{nextLevelInfo.population} pop
                </h4>
            </div>
        </div>
    )
}