import { NavLink } from "react-router-dom";

export function SignedOutsNavBar() {
    return (
        <div className="top-0 left-0 z-50 w-full rounded-lg shadow-md bg-mainNavBar grid place-items-center h-[50px]">
            <ul className="grid grid-cols-4 gap-8 place-items-center   bg-gradient-to-r from-attackTimerBg to-attackTimerBg2 px-16 py-4"
                style={{
                    clipPath: "polygon(0 0, 100% 0, 90% 100%, 10% 100%)",
                    transition: "all 0.3s ease-in-out",
                }}>
                <li className="hover:scale-105 transition transform duration-300">
                    <NavLink to="/" className={({ isActive }) => isActive ? "text-gray-200 text-lg lg:text-xl font-semibold underline hover:text-gray-200" : "text-white text-lg lg:text-xl font-semibold hover:text-gray-200"}>Home</NavLink>
                </li>
                <li className="hover:scale-105 transition transform duration-300">
                    <NavLink to="/login" className={({ isActive }) => isActive ? "text-gray-200 text-lg lg:text-xl font-semibold underline hover:text-gray-200" : "text-white text-lg lg:text-xl font-semibold hover:text-gray-200"}>Play</NavLink>
                </li>
                <li className="hover:scale-105 transition transform duration-300">
                    <NavLink to="/about" className={({ isActive }) => isActive ? "text-gray-200 text-lg lg:text-xl font-semibold underline hover:text-gray-200" : "text-white text-lg lg:text-xl font-semibold hover:text-gray-200"}>About</NavLink>
                </li>
                <li className="hover:scale-105 transition transform duration-300">
                    <NavLink to="/guide" className={({ isActive }) => isActive ? "text-gray-200 text-lg lg:text-xl font-semibold underline hover:text-gray-200" : "text-white text-lg lg:text-xl font-semibold hover:text-gray-200"}>Guide</NavLink>
                </li>
            </ul>
        </div>
    );
}
