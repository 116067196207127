import Wheat from './WheatIcon.png'

export function SmallWheatIcon(){

    return (
        <img
            className=" w-6 h-6 sm:w-8 sm:h-8"
            src={Wheat}
            alt="Small Wheat icon"/>
    );
}
