import {useEffect} from "react";
import {QuestObjectiveProgress} from "../modals/QuestObjectiveProgress";
import QuestGiverImage from "../../images/Questmaster2.png";
import QuestGiverImage2 from "../../images/Questmaster3.png";

export function QuestInfoCard(props){

    async function completeQuest(){
        await fetch(`${import.meta.env.VITE_GAMEHOST}/api/tutorial/reward`, {
            credentials: "include"
        })
    }


    useEffect(() => {
        if(props.playerQuest && !props.playerQuest.completed){
            fetch(`${import.meta.env.VITE_GAMEHOST}/api/tutorial/completion`, {
                credentials: "include"
            })
        }

    }, [])

    return (
        <div className="flex items-center justify-center bg-gray-900 p-5">

            <div className="bg-gray-800 text-white rounded-lg shadow-lg overflow-hidden">
                {/* Header */}
                <div className="px-6 py-4 bg-gradient-to-r from-gray-700 to-gray-800 text-center grid place-items-center">
                    <h3 className="text-xl font-bold text-gray-200">
                        {props.playerQuest ? props.playerQuest.name : 'Tutorial'}
                    </h3>

                </div>




                {/* Content */}
                <div className="p-6">
                    <div>
                        <p className="text-gray-300 bg-gray-600 rounded p-4">
                            {props.playerQuest ? props.playerQuest.description : 'You have a tutorial ready to start!'}
                        </p>
                    </div>







                    <div className="grid sm:grid-cols-2">
                        <div className="row-span-2 place-items-center justify-center my-auto">
                            <img src={Math.round(Math.random()) === 1 ? QuestGiverImage : QuestGiverImage2} alt="Questgiver image" className="w-[90%]"/>
                        </div>
                        {/* Objectives */}
                        <div className="mt-4">
                            <h4 className="font-semibold">Objectives:</h4>
                            <p>{props.playerQuest.taskDescription}</p>
                            <div className="mt-2">
                                {/* Dynamically generate ObjectiveProgress  based on quest data */}
                                {props.playerQuest && (
                                    <>
                                        {props.playerQuest.requiredTaskOneAmount && (
                                            <QuestObjectiveProgress
                                                name="Task 1"
                                                currentAmount={props.playerQuest.currentTaskOneAmount}
                                                requiredAmount={props.playerQuest.requiredTaskOneAmount}
                                            />
                                        )}
                                        {props.playerQuest.requiredTaskTwoAmount && (
                                            <QuestObjectiveProgress
                                                name="Task 2"
                                                currentAmount={props.playerQuest.currentTaskTwoAmount}
                                                requiredAmount={props.playerQuest.requiredTaskTwoAmount}
                                            />
                                        )}
                                        {props.playerQuest.requiredTaskThreeAmount && (
                                            <QuestObjectiveProgress
                                                name="Task 3"
                                                currentAmount={props.playerQuest.currentTaskThreeAmount}
                                                requiredAmount={props.playerQuest.requiredTaskThreeAmount}
                                            />
                                        )}
                                        {props.playerQuest.requiredTaskFourAmount && (
                                            <QuestObjectiveProgress
                                                name="Task 4"
                                                currentAmount={props.playerQuest.currentTaskFourAmount}
                                                requiredAmount={props.playerQuest.requiredTaskFourAmount}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                        {/* Reward */}
                        <div className="mt-4">
                            <h4 className="font-semibold">Reward:</h4>
                            <div className="bg-gray-600 p-4 rounded">
                                <p className="text-gray-300">{props.playerQuest ? props.playerQuest.reward : 'Experience, Items, Gold...'}</p>
                            </div>
                        </div>


                    </div>
                    </div>
                {/* Footer - Actions */}
                <div className="px-6 py-4 bg-gray-700 flex justify-end space-x-4">
                    {props.playerQuest?.completed && (
                        <button
                            className="bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 rounded-lg px-5 py-2 transition duration-150 ease-in-out"
                            onClick={completeQuest}
                        >
                            Complete
                        </button>
                    )}
                    <button
                        className="bg-red-500 hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-300 rounded-lg px-5 py-2 transition duration-150 ease-in-out"
                        onClick={props.closeModal}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );

}