import {CityActivityOverView} from "../Components/lists/CityActivityOverView";



export function CityActivityPage(){

    return <div className="w-screen h-screen bg-mainLightGreen overflow-hidden">


        <div className="relative top-[15vh]">
            <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4">

                <div className="max-h-[66vh] overflow-y-auto bg-mainLightYellow p-4">
                    <CityActivityOverView />
                </div>

            </div>
        </div>
    </div>
}