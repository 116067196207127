import { AttackTimerList } from "./AttackTimerList";
import { DefenceTimerList } from "./DefenceTimerList";
import { RetrievalList } from "./RetrievalList";

import {
    useIncomingAttackTimerState,
    useOutgoingAttackTimerState,
    useReturningAttackTimer
} from "../contexts/timerContext";

export function AllTroopActionTimersList() {


    const outgoingAttack = useOutgoingAttackTimerState();
    const incomingAttack = useIncomingAttackTimerState();
    const returningAttack = useReturningAttackTimer();





    return (
        <div className="min-h-44 ">
            {(outgoingAttack.length > 0 || incomingAttack.length > 0 || returningAttack.length > 0) &&
            <div className="rounded-lg shadow-md bg-attackTimerBg min-w-[220px] sm:min-w-[220px] md:min-w-[5vw] lg:min-w-[5vw] xl:min-w-[5vw]  hover:shadow-lg transition-all duration-200 ease-in-out">
{/*                <h2 className="font-fantasy text-xl md:text-sm lg:text-base xl:text-xl text-white font-semibold text-center">Troop Actions</h2>*/}
                <div className="p-2 text-black font-pixel">
                    {outgoingAttack.length > 0 && (
                        <div className="mb-2  overflow-y-auto ">
                            <AttackTimerList />
                        </div>
                    )}
                    {incomingAttack.length > 0 && (
                        <div className="mb-2 overflow-y-auto">
                            <DefenceTimerList />
                        </div>
                    )}
                    {returningAttack.length > 0 && (
                        <div className=" overflow-y-auto">
                            <RetrievalList />
                        </div>
                    )}
                </div>
            </div>}
        </div>
    );
}
