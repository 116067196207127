import {useEffect, useState} from "react";

import {HelperFunctions} from "../../HelperFunctions";
import {useTimerState} from "../contexts/timerContext";

export function BuildingTimerList() {


  const globalTimers = useTimerState();


  const [buildingTimers, setBuildingTimers] = useState(
      []
  )

    useEffect(() => {
        const combinedTimers = [...globalTimers.resourceTimers, ...globalTimers.buildingTimers];
        combinedTimers.sort((a, b) => a.endTime - b.endTime);
        setBuildingTimers(combinedTimers);
    }, [globalTimers]);




  if (buildingTimers.length === 0) {
    return null;
  }

  const isSingleItem = buildingTimers.length === 1;

  return (
      <div className="flex  text-white px-2 sm:px-0  ">
        <div className="bg-mainLightYellow rounded-lg shadow-md p-2 w-full  ">


        <div
            className={` overflow-auto`}
        >
          <ul
              className={`grid grid-cols-1 ${
                  isSingleItem ? "grid-cols-1" : " xxxs:grid-cols-2"
              } gap-4 divide-y divide-gray-200`}
          >
            {buildingTimers.map((timer, index) => (
                <li
                    key={index}
                    className={`p-2 border-2 border-black bg-mainDarkBrown ${
                        index === 1 ? " divide-opacity-0" : ""
                    }  `}
                >
                  <div className="flex items-center">
                    <h4 className="font-semibold text-xs sm:text-sm md:text-base lg:text-lg xl:text-lg mr-2">{index + 1}.</h4>
                    <h3 className="font-semibold text-xs sm:text-sm md:text-base lg:text-base mr-2">
                      {timer.extra.building_name} to {timer.extra.next_level}
                    </h3>
                  </div>
                  <div className="text-sm font-semibold mb-1">

              <span className="font-semibold text-xs mx-auto">
                {index === 0
                    ? `${HelperFunctions.secondsToTimestamp(timer.timeLeft)}`
                    : `Waiting... ${HelperFunctions.secondsToTimestamp(
                        timer.duration
                    )}`}
              </span>
                  </div>
                  {index === 0 && (
                      <div className="relative pt-1">
                        <div className="overflow-hidden h-2 text-xs flex rounded bg-blue-200">
                          <div
                              style={{
                                width: `${
                                    index === 0
                                        ? (timer.duration - timer.timeLeft) / timer.duration * 100
                                        : 0
                                }%`,
                              }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-1000"
                          > </div>
                        </div>
                      </div>
                  )}
                </li>
            ))}
          </ul>
        </div>
        </div>

      </div>
  );
}
