export function CardBackside(){

    return <svg width="100%" height="100%" viewBox="0 0 822 1122" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="818" height="1118" fill="#D9D9D9" stroke="black" stroke-width="4"/>
        <g filter="url(#filter0_f_12_89)">
            <rect x="376" y="376" width="475" height="365.303" fill="#B728A9"/>
        </g>
        <g filter="url(#filter1_f_12_89)">
            <rect x="376" y="745.544" width="453.686" height="360.456" fill="#8928B7"/>
        </g>
        <defs>
            <filter id="filter0_f_12_89" x="0" y="0" width="1227" height="1117.3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="188" result="effect1_foregroundBlur_12_89"/>
            </filter>
            <filter id="filter1_f_12_89" x="0" y="369.544" width="1205.69" height="1112.46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="188" result="effect1_foregroundBlur_12_89"/>
            </filter>
        </defs>
    </svg>

}