import {useEffect, useState} from "react";

export function ConfirmCoinSell({ onConfirm, onCancel, amountOfCoins }) {

    const [totalWheat, setTotalWheat] = useState(0);
    const [totalWood, setTotalWood] = useState(0);
    const [totalStone, setTotalStone] = useState(0);
    const [totalIron, setTotalIron] = useState(0);

    useEffect(() => {
        setTotalWheat(amountOfCoins * 825)
        setTotalWood(amountOfCoins * 815)
        setTotalStone(amountOfCoins * 915)
        setTotalIron(amountOfCoins * 885)
    }, [])
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                    onClick={onCancel}
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"> </div>
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >

        </span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Confirmation required
                        </h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                Are you sure you want to sell {amountOfCoins} coins?
                                You would get wheat: {totalWheat}
                                wood: {totalWood}
                                stone: {totalStone}
                                iron: {totalIron}

                            </p>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={onConfirm}
                        >
                            Confirm
                        </button>
                        <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}