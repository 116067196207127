import { SlotMachine } from "./slots/SlotMachine.jsx";
import {useContext, useState} from "react";
import {CasinoContext} from "../../index";
import {SlotRewards} from "./slots/SlotRewards";
import {SlotWinHistoryCard} from "./SlotWinHistoryCard";
import {CasinoCoinResponsive} from "../../images/CasinoCoinResponsive";

export function CasinoSlots() {

    const {currentCasinoCoins, maxCasinoCoins} = useContext(CasinoContext);

    const [startSpinning1, setStartSpinning1] = useState(false);
    const [startSpinning2, setStartSpinning2] = useState(false);
    const [startSpinning3, setStartSpinning3] = useState(false);
    const [status, setStatus] = useState("");

    const [lootCollected, setLootCollected] = useState(false);


    const handleClick = async () => {

        setStatus("")

        if(currentCasinoCoins > 0){
            const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/casino/slots/start`, {credentials: 'include'})

            if(res.status === 200){
                setStartSpinning1(true);
                setStartSpinning2(true);
                setStartSpinning3(true);
            }else{
                setStatus("Something went wrong while purchasing the spin!")
            }
        }else{
            setStatus("You do not have enough coins for that!")
        }
    };

    return (
        <div className="bg-blue-500 flex flex-col justify-center items-center ">
            <h2 className="text-xl md:text-2xl xl:text-3xl font-semibold">Slotmachine</h2>

            <div className="flex flex-col lg:flex-row justify-center items-center">
                {/*SlotRewards*/}
                <SlotRewards/>

                {/*Slotmachine*/}
                <div className="relative ">
                    <SlotMachine
                        setStartSpinning1={setStartSpinning1}
                        startSpinning1={startSpinning1}
                        setStartSpinning2={setStartSpinning2}
                        startSpinning2={startSpinning2}
                        setStartSpinning3={setStartSpinning3}
                        startSpinning3={startSpinning3}
                        setLootCollected={setLootCollected}

                    />
                </div>
                {/*Slotmachine controls*/}
                <div className="flex flex-col  items-center justify-center">

                    <button onClick={handleClick} disabled={startSpinning1 || startSpinning2 || startSpinning3} className="bg-yellow-400 text-xl font-bold py-2 px-4 rounded shadow-lg hover:bg-yellow-300 mt-2 mb-2">Spin</button>
                    <div className="flex items-center">
                        <h4 className="text-2xl text-yellow-400">1</h4>
                        <CasinoCoinResponsive/>
                    </div>
                </div>
            </div>

            {/*Slot history*/}
            <div className="text-center bg-mainLightYellow p-2 mt-4 mb-2">
                <SlotWinHistoryCard lootCollected={lootCollected}/>
            </div>

            {/*Current coins*/}
            <div className="flex justify-center mt-2 mb-2">
                <CasinoCoinResponsive/>
                <h3 className="ml-2">{currentCasinoCoins}/{maxCasinoCoins}</h3>
            </div>
            {status && <div className="text-red-500">{status}</div>}
        </div>
    );
}
