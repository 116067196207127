import {React, useEffect, useState} from "react";
import {PaginationControls} from "../PaginationControls";

export function AllianceActivityList(props) {
    const [statusText, setStatusText] = useState("");
    const [allActivityList, setAllActivityList] = useState([]);
    const [category, setCategory] = useState('all');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);

    const getDataCount = async () => {
        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/activities/count/page/${category}/${props.allianceId}`, {
            credentials: "include"
        });
        const data = await res.json();

        setTotalPageCount(data);
    };


    async function getAllianceActivities() {


        setStatusText("");
        const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/alliance/activities/${category}/${props.allianceId}/${pageNumber}`, {
            credentials: "include"
        });

        if (res.status === 200) {
            const data = await res.json();
            setAllActivityList(data)
        } else {
            setStatusText("Something terrible has happened!")
        }
    }

    useEffect(() => {
        setPageNumber(1)
        getDataCount()
        getAllianceActivities()
    }, [category]);

    useEffect(() => {
        const getData = async () => {
            await getAllianceActivities();
        };
        getData();
    }, [pageNumber]);

    const handleSelectChange = (event) => {
        setCategory(event.target.value);
    };

    return (
        <div className="p-2 rounded-lg text-black border border-2 border-black mb-2 min-w-[250px] md:min-w-full overflow-y-scroll">
            <h2 className="text-base md:text-lg xl:text-xl font-bold mb-2 text-center">Alliance Activity</h2>

            <select onChange={handleSelectChange} className="mb-4 bg-mainDarkBrown text-white  rounded">
                <option value="all">All</option>
                <option value="attack">Attack</option>
                <option value="joined">Joined</option>
                <option value="left">Left</option>
                <option value="defence">Defence</option>
            </select>

            {statusText && <h2 className="text-red-500">{statusText}</h2>}





                <div className="grid grid-cols-3 gap-4 text-sm font-semibold text-center bg-mainLightBrown1">
                    <div>Type</div>
                    <div>Event</div>
                    <div>Time</div>
                </div>

            {allActivityList.map((activity, index) => (
                <div className={`grid grid-cols-3 gap-4 text-xxs sm:text-sm md:text-base text-center p-2 pr-6 ${index % 2 === 0 ? 'bg-mainLightBrown3': 'bg-mainLightBrown1'}`} key={index}>
                    <div>{activity.type}</div>
                    <div>{activity.text}</div>
                    <div>{new Date(activity.time).toLocaleString()}</div>
                </div>
            ))}

            <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPageCount={totalPageCount}/>
        </div>
    );
}
