import { useContext, useEffect, useState } from "react";
import {CityConfigContext, UserContext, WarehouseContext} from "../../index.jsx";
import { MarketplaceTimerList } from "../lists/MarketplaceTimerList";
import { ConfirmResourcesModal } from "../modals/ConfirmResourcesModal";
import { RULE_CONFIG } from "../../RULES.js";
import {StoneIconResponsive} from "../../images/resources/StoneIconResponsive";
import {WheatIconResponsive} from "../../images/resources/WheatIconResponsive";
import {WoodIconReponsive} from "../../images/resources/WoodIconReponsive";
import {IronIconResponsive} from "../../images/resources/IronIconResponsive";
import {timerActions} from "../reducers/timerReducer";
import {useTimerDispatch} from "../contexts/timerContext";

export function SendResourcesCard() {
  const { warehouse } = useContext(WarehouseContext);
  const { inCityId, tradersCount } = useContext(CityConfigContext);
  const dispatch = useTimerDispatch();
  const [statusText, setStatusText] = useState("");
  const [cityName, setCityName] = useState(0);
  const [wheat, setWheat] = useState(0);
  const [wood, setWood] = useState(0);
  const [stone, setStone] = useState(0);
  const [iron, setIron] = useState(0);
  const [selectedCity, setSelectedCity] = useState(null);
  const [foundCities, setFoundCities] = useState([]);
  const [canCarry, setCanCarry] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setShowSpinner } = useContext(UserContext);
  function openConfirmationModal() {
    setIsModalOpen(true);
  }

  function calculateCanCarry() {
    if (tradersCount.canCarryTotal > 0) {
      const totalSelected = +wheat + +wood + +stone + +iron;
      const sum = tradersCount.canCarryTotal - totalSelected;
      setCanCarry(sum);
    } else {
      setCanCarry(0);
    }
  }

  async function confirmSendResources() {
    setShowSpinner(true)
    setIsModalOpen(false);
    setStatusText("");

    if (wheat === 0 && wood === 0 && stone === 0 && iron === 0) {
      setStatusText("You need to choose some resources");
      setShowSpinner(false)
      return;
    }

    // Check if warehouse has enough resources
    if (
      wheat > warehouse["wheat"] ||
      wood > warehouse["wood"] ||
      stone > warehouse["stone"] ||
      iron > warehouse["iron"]
    ) {
      setStatusText("Not enough resources in the warehouse!");
      setShowSpinner(false)
      return;
    }
    const totalSum = wheat + stone + wood + iron;
    const tradersNeeded = Math.ceil(totalSum / RULE_CONFIG.merchantCarryAmount);

    if (tradersCount.tradersInCity < tradersNeeded) {
      setStatusText("You do not have enough traders for that");
      setShowSpinner(false)
      return;
    }

    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/marketplace/send/res`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        wheat,
        wood,
        stone,
        iron,
        inCityId,
        selectedCity,
      }),
      credentials: "include"
    });

    if(res.status === 200){
      const data = await res.json();

      dispatch(
          {
            type: timerActions.ADD_TIMER,
            category: "sendResourcesTimers",
            payload: {
              duration: data.travelTimeMillies,
              timeLeft: data.timeLeft,
              endTime: data.arrival_time,
              queueId: data.itemQueueId,
              extra: {
                toCity: data.toCity,
                toCityName: data.toCityName,
                fromCity: data.fromCity,
                fromCityName: data.fromCityName,
                toUserName: data.toUserName,
                fromUserName: data.fromUserName,
                wheat: data.wheat,
                wood: data.wood,
                stone: data.stone,
                iron: data.iron,
                tradersNeeded: data.tradersNeeded
              }
            }
          })
    }else{
      setShowSpinner(false)
    }
    setTimeout(() => {
      setShowSpinner(false)
    }, 1700)
  }
  async function findCity() {
    if (!cityName) {
      setStatusText("Please enter something");
      return;
    }

    setStatusText("");
    setFoundCities([]);
    setSelectedCity(null);
    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/marketplace/find/${cityName}`, {
      credentials: "include"
    });

    if (res.status !== 200) {
      setStatusText(await res.text());
    } else {
      const data = await res.json();

      setFoundCities(data);
    }
  }

  function setResource(setter, value, resourceType) {
    if (value <= warehouse[resourceType]) {
      // Check if the warehouse has enough resources
      setter(Number(value));
    } else {
      setStatusText(`You don't have enough ${resourceType}!`);
    }
  }

  useEffect(() => {
    calculateCanCarry();
  }, [tradersCount, wheat, wood, stone, iron]);

  return (
    <div className="text-center">

      <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">


      <div > {/*SEND RES CONTENT DIV*/}
        <div >


        <h4 className="text-xl font-semibold mb-2">Send resources</h4>
      <div className="flex flex-col md:flex-row">
        <label className="mr-0 md:mr-1 mb-2 md:mb-0">City name</label>
        <input className="bg-mainLightBrown3 mr-0 md:mr-1 mb-2 md:mb-0" type="text" onChange={(e) => setCityName(e.target.value)} />
        <button className="text-sm bg-mainDarkBrown" onClick={findCity}>Find City</button>
      </div>




      {foundCities.length > 0 && (
        <ul>
          {foundCities.map((city) => (
            <li key={city.id}>
              <label>
                <input
                  type="radio"
                  value={city.id}
                  checked={selectedCity && selectedCity.id === city.id}
                  onChange={() => setSelectedCity(city)}
                />
                {city.name}({city.username})
              </label>
            </li>
          ))}
        </ul>
      )}

      {selectedCity && (
        <div className="">
          <div className="">
          <div className="flex justify-center">
            <WheatIconResponsive/>
            <input
                className="bg-mainLightBrown3 mb-2 ml-2"
              type="number"
              max={warehouse["wheat"]}
              onChange={(e) => setResource(setWheat, e.target.value, "wheat")}
            />
          </div>
          <div className="flex justify-center">
            <WoodIconReponsive/>
            <input
                className="bg-mainLightBrown3 mb-2 ml-2"
              type="number"
              max={warehouse["wood"]}
              onChange={(e) => setResource(setWood, e.target.value, "wood")}
            />
          </div>
          <div className="flex justify-center">
            <StoneIconResponsive/>
            <input
                className="bg-mainLightBrown3 mb-2 inline ml-2"
              type="number"
              max={warehouse["stone"]}
              onChange={(e) => setResource(setStone, e.target.value, "stone")}
            />
          </div>
          <div className="flex justify-center">
            <IronIconResponsive/>
            <input
                className="bg-mainLightBrown3 mb-2 ml-2"
              type="number"
              max={warehouse["iron"]}
              onChange={(e) => setResource(setIron, e.target.value, "iron")}
            />
          </div>
          </div>

          {statusText && <div className="text-red-500">{statusText}</div>}
          <button className="bg-mainDarkBrown" onClick={openConfirmationModal}>Send resources</button>

          {isModalOpen && (
            <ConfirmResourcesModal
              traders={tradersCount.maxTraders}
              wheat={wheat}
              wood={wood}
              stone={stone}
              iron={iron}
              confirm={confirmSendResources}
              closeModal={() => setIsModalOpen(false)}
            />
          )}
          <div>
            <div>
              Traders available: {tradersCount.tradersInCity}/
              {tradersCount.maxTraders}
            </div>
            <div>Can carry: {canCarry} resources</div>
          </div>
        </div>
      )}
      </div>
      </div>
      <MarketplaceTimerList />
      </div>
    </div>
  );
}
