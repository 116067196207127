import {BuildingDescAndImage} from "./BuildingDescAndImage";
import {MarketplaceTimerList} from "../lists/MarketplaceTimerList";

export function MarketplaceOverviewCard(){


    return <div>
        <BuildingDescAndImage type="Marketplace" isDetails={true}/>
        <MarketplaceTimerList />
    </div>
}