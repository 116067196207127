import {ResourceProduction} from "../resource/ResourceProduction";
import {TroopsOverview} from "../troops/TroopsOverview";
import {CityList} from "../lists/CityList";
import {FoundNewCityTimerList} from "../lists/FoundNewCityTimerList";
import {useContext, useEffect} from "react";
import {CityConfigContext} from "../../index";

export function CityInfoCard(){
    const { troops } = useContext(CityConfigContext);


    useEffect(() => {

        if(troops){
            let hasTroopsInCity = false
            troops.forEach((troop) => {
                if(troop.in_city_amount > 1){
                    hasTroopsInCity = true
                }
            })


        }
    }, [troops])

    return <ul className="max-h-[85vh] lg:max-h-[92vh] overflow-y-scroll lg:overflow-y-hidden">
                <li>
                    <CityList />
                </li>

                <li className=" mt-3 xl:mt-6">
                    <ResourceProduction />
                </li>

         <li className="mt-3 xl:mt-6">
            <TroopsOverview />
        </li>

                <li className="mt-3 xl:mt-4">
                    <FoundNewCityTimerList/>
                </li>
        </ul>




}