import { MyNavBar } from "./Navbar.jsx";

export function TopNavBar() {
  return (
    <div  >
        <MyNavBar />


    </div>
  );
}
