import questMaster from "../../images/Questmaster.png";
import { useState } from "react";
import { QuestDescriptionModal } from "../modals/QuestDescriptionModal";
export function QuestGiverCard() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    function closeModal() {
        setIsModalOpen(false)
    }

    return (
        <div className="w-[15vw] min-w-[200px] ml-4">
            <img
                src={questMaster}
                alt="Quest giver image"
                className="w-full object-cover hover:scale-[105%]"
                onClick={() => setIsModalOpen(true)}
            />

            {isModalOpen && <QuestDescriptionModal closeModal={closeModal}/>}
        </div>
    );
}
