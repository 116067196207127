

export const QuestObjectiveProgress = ({ name, currentAmount, requiredAmount }) => {
    const progressPercent = Math.min((currentAmount / requiredAmount) * 100, 100);
    return (
        <div className="flex items-center space-x-4 mb-4">
            <div className="flex-1">
                <div className="text-sm font-medium text-gray-300">{name}</div>
                <div className="w-full bg-gray-700 rounded-full h-2.5">
                    <div className="bg-gradient-to-r from-green-400 to-blue-500 h-2.5 rounded-full" style={{ width: `${progressPercent}%` }}></div>
                </div>
            </div>
            <div className="text-sm font-medium text-gray-300">{currentAmount}/{requiredAmount}</div>
        </div>
    );
};